import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, IconButton, InputAdornment, Card } from '@mui/material';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { followUser, searchUsers, withdrawRequest, unfollowUser } from '../../actions/user'


const MAX_NAME_LENGTH = 15;
const MAX_BIO_LENGTH = 15;

const SearchResult = ({result, followState}) => {
    const dispatch = useDispatch();
    const handleFollow = () => {
        dispatch(followUser(result._id));
    }
    const handleWithdraw = () => {
        dispatch(withdrawRequest(result._id));
    }
    const handleUnfollow = () => {
        dispatch(unfollowUser(result._id));
    }
    return (
        <ListItem alignItems="flex-start" key={result._id}>
            <ListItemAvatar>
                <Avatar alt={result.username} src={result.profilePictureUrl}>{result.username?.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={result.username.length > MAX_NAME_LENGTH ? `${result.username.substring(0, MAX_NAME_LENGTH)}...` : result.username}
                secondary={result.bio.length > MAX_BIO_LENGTH ? `${result.bio.substring(0, MAX_BIO_LENGTH)}...` : result.bio}
            />
            {
                followState === "following" &&
                <Button onClick={handleUnfollow}> Unfollow</Button>
            }
            {
                followState === "requested" &&
                <Button onClick={handleWithdraw}>Withdraw request </Button>
            }
            {
                followState === "none" && 
                <Button  onClick={handleFollow}> Follow </Button>
            }
        </ListItem>
    )
}


const FollowForm = ({user, following, outgoing}) => {
    if (!user) {
        user = JSON.parse(localStorage.getItem('profile'));
    }

    const searchResults = useSelector((state) => state.user.searchResults);

    const [target, setTarget] =  useState("");
    const dispatch = useDispatch();   
    
    useEffect(() => {
        if (user != null) {
            dispatch(searchUsers(target));
        }
    }, []);

    const handleSubmit = (e) => {
        dispatch(searchUsers(target));
    }

    const calculateFollowState = (id) => {
        if (id === user.result._id) {
            return "self";
        }
        else if (following.map(obj => obj._id).includes(id)) {
            return "following";
        }
        else if (outgoing.map(obj => obj._id).includes(id)) {
            return "requested";
        }
        else {
            return "none";
        }
    }

    return (
        <>
            { user &&
                <TextField 
                    name="search" 
                    variant="outlined" 
                    label="Search"
                    fullWidth
                    value={target} 
                    onKeyDown={handleSubmit}
                    onChange={(e) => setTarget(e.target.value)}
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             <IconButton aria-label="send" color="primary" onClick={handleSubmit}>
                    //                 <SendIcon />
                    //             </IconButton>
                    //         </InputAdornment>
                    //     ),
                    // }}
                />
            }
            <List>
            {searchResults.map(index => <SearchResult result={index} followState={calculateFollowState(index._id)}/>)}
            </List>
            
        </>
    );
};

export default FollowForm;