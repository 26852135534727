import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProgram } from '../../../actions/program';
import GenericCard from '../../Content/Card';
import { joinProgram, acceptProgramInvitation, removeJoinRequest } from '../../../actions/program';
// import { createSubscription } from '../../../actions/payment';

const ProgramCard = ({ item, isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (id) => {
    dispatch(deleteProgram(id));
  };

  const handleManage = (id) => {
    navigate(`/programs/${id}`);
  };

  const handleJoin = (id) => {
    dispatch(joinProgram(id));
    // dispatch(createSubscription(id));
  };

  const handleAccept = (id) => {
    dispatch(acceptProgramInvitation(id));
    // dispatch(createSubscription(id));
  };

  const handleWithdraw = (id) => {
    dispatch(removeJoinRequest(id));
  };

  return (
    <GenericCard
      item={{ ...item, type: 'program' }}
      onLike={null}
      onBookmark={null}
      onDelete={handleDelete}
      onEdit={null}
      onManage={handleManage}
      onJoin={handleJoin}
      onAccept={handleAccept}
      onWithdraw={handleWithdraw}
      isBookmarked={false}
      isLiked={false} 
      isMobile={isMobile}
      programData={null}
    />
  );
};

export default ProgramCard;