import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';
import WorkoutCard from '../../Workout/WorkoutCard';
import { getLogsByMonthAndWorkouts, uploadLogToProgram } from '../../../actions/log'
import Log from '../../Log/Log';
import RestDayIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import { useMediaQuery } from '@mui/material';
import { 
    Container,
    Button, 
    Paper, 
    Typography, 
    InputAdornment, 
    IconButton, 
    TextField, 
    MenuItem, 
    Box, 
    Divider, 
    Stack, 
    Switch,
    Collapse,
    Alert, 
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Grid
} from '@mui/material';


let initialValue = dayjs();
initialValue.format("YYYY-MM-DD");

function Day(props) {
    const theme = useTheme();
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? <DoneIcon sx={{ color: theme.palette.primary.light, fontSize: 18 }}/> : undefined}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}

const logStyle = {
    // overflow: 'auto',
    width: "100%",
    margin: "5px 5px 0px 0px",
    color: "primary",
  };

const LogDisplay = ({schedule, id, isAdmin}) => {
    const [month, setMonth] = useState(0);
    const [date, setDate] = useState(new Date());
    const [logOptions, setLogOptions] = useState([])
    const altSchedule = schedule.map(day=>day.map(workout => workout?._id))
    const logs = useSelector(
        (state) => state.log.memberLogs
    );
    const currentDay = new Date();
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const dispatch = useDispatch();
    const setLog = (value) => {
        dispatch(uploadLogToProgram(value, id))
    }
    const datesWithAllIds = [];
    
    // Group logs by date
    const logsByDate = logs.reduce((acc, log) => {
        const date = log.createdAt;
        if (!acc[date]) {
        acc[date] = [];
        }
        acc[date].push(log.workoutId);
        return acc;
    }, {});
    for (const date in logsByDate) {
        const logIds = logsByDate[date];

        // Determine the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = new Date(date).getDay();

        const requiredIds = altSchedule[dayOfWeek];
        // Check if all required IDs are present in the logs for that date
        const allIdsPresent = requiredIds.every(id => logIds.includes(id));
        if (allIdsPresent) {
        datesWithAllIds.push((new Date(date)).getDate());
        }
    }

    const theme = useTheme();
    const styles = {
        restDayCard: {
          border: 1,
          borderColor: 'gray',
          borderRadius: 3,
          backgroundColor: theme.palette.background.paper,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '300px',
          width: '250px',
        },
      };

    useEffect(() => {
        dispatch(getLogsByMonthAndWorkouts(month, id));
    }, [month]);

    useEffect(() => {
        const filteredLogs = logs.filter(log => {
            const logDate = new Date(log.createdAt);
            return (
              logDate.getFullYear() === date.getFullYear() &&
              logDate.getMonth() === date.getMonth() &&
              logDate.getDate() === date.getDate()
            );
        });
        setLogOptions(filteredLogs);

    }, [date, logs]);
    return (
    <Box sx={{display: isSmallScreen ? 'block' : 'flex',}}>
        <Box display="flex" justifyContent="center" sx={{width: isSmallScreen ? '100%' : '40%'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                sx={{
                    minWidth: '300px',
                    maxWidth: '300px',
                    height: '325px',
                }}
                onMonthChange = {(e) => {
                    setMonth(currentDay.getMonth() - e.$d.getMonth() + (currentDay.getYear() - e.$d.getYear())*12);
                }}
                onYearChange = {(e) => {
                    setMonth(currentDay.getMonth() - e.$d.getMonth() + (currentDay.getYear() - e.$d.getYear())*12);
                }}
                onChange = {(e)=>{setDate(e.$d)}}
                defaultValue={initialValue}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                    day: Day,
                }}
                slotProps={{
                    day: {
                        highlightedDays: datesWithAllIds
                    },
                }}
            />
        </LocalizationProvider>
        </Box>
        <Box sx={{width: isSmallScreen ? "100%" : "60%"}}>
            <Stack spacing={2}  sx={{alignItems: isSmallScreen ? 'center' : 'none'}}>
                {schedule[date.getDay()].map((workout) => (
                    <>
                        {
                            workout ?
                            <Box key={workout._id} sx={{display: isSmallScreen ? 'grid' : 'flex', placeItems: isSmallScreen ? 'center' : 'none'}}>
                                <WorkoutCard item={workout} programData={isAdmin ? { programId: id, workoutId: workout._id, day: 0 } : null} />
                                <Box sx={{width: isSmallScreen ? '100%' : '50%', marginLeft: isSmallScreen ? "0px" : "20px"}}>
                                    <TextField
                                    size="large" 
                                    value={workout} 
                                    onChange={(e) => setLog(e.target.value)} select label='Upload Log' width="100px" 
                                    sx={logStyle}
                                    SelectProps={{
                                        MenuProps: {
                                        PaperProps: {
                                            style: {
                                            maxHeight: 200, // Set the maximum height for the dropdown
                                            overflowY: 'auto', // Enable vertical scrolling
                                            },
                                        },
                                        },
                                    }} 
                                    >
                                        {logOptions.filter(log => log.workoutId == workout._id).map((value, index) => (
                                        <MenuItem key={index} value={value._id}>
                                            <Log key={index} creator={value.creator} data={value} />
                                        </MenuItem>
                                        ))}  
                                    </TextField>
                                    {logOptions.find(log => log.programs.includes(id)) &&
                                        <Log creator={"HAHA"} data={logOptions.find(log => log.programs.includes(id))} programId={id}/>
                                    }
                                </Box>
                            </Box>
                            :
                            <Box sx={styles.restDayCard}>
                                <Typography paddingTop='2.5%'>Deleted Workout</Typography>
                                <WarningIcon />
                            </Box>
                        }
                    </>
                ))}
                {schedule[date.getDay()].length === 0 && 
                    <Box sx={styles.restDayCard}>
                        <Typography paddingTop='2.5%'>Rest day</Typography>
                        <RestDayIcon />
                  </Box>
                }
            </Stack>
        </Box>
        
    
    </Box>
  );
};

export default LogDisplay;






