import { combineReducers } from 'redux';
import userReducer from './user';
import exerciseReducer from './exercise';
import workoutReducer from './workout';
import programReducer from './program';
import logReducer from './log';
import commentReducer from './comment';
import statsReducer from './stats';
import paymentReducer from './payment';
import videoReducer from './video';

export const reducers = combineReducers({
  user: userReducer,
  exercise: exerciseReducer,
  workout: workoutReducer,
  program: programReducer,
  log: logReducer,
  comment: commentReducer,
  stats: statsReducer,
  payment: paymentReducer,
  video: videoReducer,
});