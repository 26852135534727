import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTheme } from '@mui/material/styles';
import Input from './Input';
import { recoverEmail } from '../../actions/user';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(recoverEmail({ email }));
    navigate(`/auth`);
  };

  const handleChange = (e) => setEmail(e.target.value);

  const classes = {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper style={classes.paper} elevation={6}>
        <Avatar style={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5"> Recover Account </Typography>
        <form style={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" style={classes.submit}>
            Send Recovery Email
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Forgot;