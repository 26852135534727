import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Button, Divider, Stack, Grow, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeExercises from '../Exercise/HomeExercises';
import HomeWorkouts from '../Workout/HomeWorkouts';
import HomeVideos from '../Video/HomeVideos';
import HomePrograms from '../Program/Member/HomePrograms';
import background from '../../images/background.jpg';


const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem('profile'));

  const sectionStyle = {
    mt: '20px',
    mb: '20px',
  };

  const footerElementStyle = {
    color: theme.palette.text.secondary,
    p: '1px 0px',
  };

  return (
    <Grow in >
      <Container disableGutters maxWidth={false} sx={{ 
        backgroundColor: 'background.paper', 
        height: '100vh',
        position: 'absolute',
        top: '0px', 
        zIndex: '-1',
      }}>

        <Container disableGutters maxWidth={false} sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '300px',
          height: '40vh',
          width: '100%',
          position: 'relative', 
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
          <Container maxWidth="lg" sx={{ textAlign: 'left', pl: '8vw' }}>
            <Typography variant="h4" sx={{
              color: 'white', 
              fontWeight: 'bold',
              mb: 1,
              ml: {
                xs: 0, // No margin on extra-small screens
                sm: 5, // Add margin on small screens and above
              }
            }}>
              Coaching, Anytime, Anywhere
            </Typography>
            <Typography variant="h5" sx={{
              color: 'white', 
              fontWeight: 'bold',
              display: {
                xs: 'none', // Hide on extra-small screens
                sm: 'block' // Show on small screens and above
              },
              ml: 8,
            }}>
              AI-powered training optimization
            </Typography>

            {user === null && (
              <Box sx={{ mt: 4 }}>
                <Button 
                  color="secondary" 
                  variant="contained"
                  onClick={() => navigate('/auth')}
                  sx={{
                    mt: 1, // Added margin-top to create space below the text
                    ml: {
                      xs: 21, // No margin on extra-small screens
                      sm: 16,
                      md: 15,
                    }
                  }}
                >
                  Sign In
                </Button>
              </Box>
            )}

            <Box sx={{
              position: 'absolute',
              bottom: '16px',
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}>
              <Typography variant="subtitle2" sx={{ color: 'white' }}>
                Browse content
              </Typography>
              <ArrowDropDownIcon sx={{ fontSize: '40px', color: 'white' }} />
            </Box>
          </Container>
        </Container>

        <Box sx={sectionStyle}>
          <HomeExercises />
        </Box>
        
        <Box sx={sectionStyle}>
          <HomeWorkouts />
        </Box>

        <Box sx={sectionStyle}>
          <HomeVideos />
        </Box>

        <Box sx={sectionStyle}>
          <HomePrograms />
        </Box>
        
        <Divider sx={{m: '20px 0px'}}/>

        <Container maxWidth='lg' sx={{m: '20px 0px', pb: '20px', textAlign: 'right'}}>
          <Stack direction="column" >
            <Typography variant="p" sx={footerElementStyle}>© 2024 Gains Coach, LLC All rights reserved</Typography>
            <Typography variant="p" sx={footerElementStyle} component={Link} to="/privacy">Privacy Policy</Typography>
            <Typography variant="p" sx={footerElementStyle} component={Link} to="/terms">Terms of Service</Typography>

            <Typography variant="p" sx={footerElementStyle}>
              <a style={{textDecoration:'none', color: `${theme.palette.text.secondary}`}} href="https://www.freepik.com/free-photo/muscular-sportsman-training-arms-with-dumbbell_8794687.htm#page=4&query=fitness%20dark%20blue&position=34&from_view=search&track=ais&uuid=65b0578e-f758-4369-85ec-421b00f5c00d">Image by serhii_bobyk</a> on Freepik
            </Typography>
            <Typography variant="p" sx={footerElementStyle}>
              <a style={{textDecoration:'none', color: `${theme.palette.text.secondary}`}} href="https://www.freepik.com/free-photo/front-view-room-with-weights-water-bottle_11218192.htm">Default thumbnail image by freepik</a>
            </Typography>
          </Stack>
        </Container>
            
      </Container>
    </Grow>
  );
};

export default Home;