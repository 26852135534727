// User Action Types
export const AUTH = 'AUTH';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const RECOVER_EMAIL = 'RECOVER_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USERS_BY_IDS = 'GET_USERS_BY_IDS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const GET_FOLLOWERS_BY_ID = 'GET_FOLLOWERS_BY_ID';
export const GET_FOLLOWING_BY_ID = 'GET_FOLLOWING_BY_ID';
export const GET_INCOMING_BY_ID = 'GET_INCOMING_BY_ID';
export const GET_OUTGOING_BY_ID = 'GET_OUTGOING_BY_ID';
export const FOLLOW_USER = 'FOLLOW_USER';
export const WITHDRAW_REQUEST = 'WITHDRAW_REQUEST'
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const ACCEPT_FOLLOW_REQUEST = 'ACCEPT_FOLLOW_REQUEST';
export const DECLINE_FOLLOW_REQUEST = 'DECLINE_FOLLOW_REQUEST';
export const REMOVE_FOLLOWER = 'REMOVE_FOLLOWER';
export const SEARCH_USERS = 'SEARCH_USERS';
export const SUGGEST_USERS_TO_FOLLOW = 'SUGGEST_USERS_TO_FOLLOW';
export const USER_ERROR = 'USER_ERROR';
export const LOGOUT = 'LOGOUT';
export const FETCH_EXERCISE_BOOKMARKS_WITH_TITLES = 'FETCH_EXERCISE_BOOKMARKS_WITH_TITLES';
export const FETCH_WORKOUT_BOOKMARKS_WITH_TITLES = 'FETCH_WORKOUT_BOOKMARKS_WITH_TITLES';

// Exercise Action Types
export const GET_HOMEPAGE_EXERCISES = 'GET_HOMEPAGE_EXERCISES';
export const GET_TRENDING_EXERCISES = 'GET_TRENDING_EXERCISES';
export const GET_EXERCISES_BY_CREATOR = 'GET_EXERCISES_BY_CREATOR';
export const SEARCH_EXERCISES = 'SEARCH_EXERCISES';
export const GET_EXERCISES_FROM_ARRAY = 'GET_EXERCISES_FROM_ARRAY';
export const GET_EXERCISE_BY_ID = 'GET_EXERCISE_BY_ID';
export const GET_SUGGESTED_EXERCISES = 'GET_SUGGESTED_EXERCISES';
export const CREATE_EXERCISE = 'CREATE_EXERCISE';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const LIKE_EXERCISE = 'LIKE_EXERCISE';
export const INCREMENT_EXERCISE_RUNS = 'INCREMENT_EXERCISE_RUNS';
export const EXERCISE_ERROR = 'EXERCISE_ERROR';
export const CLEAR_EXERCISE = 'CLEAR_EXERCISE';
export const CLEAR_EXERCISE_SEARCH_RESULTS = 'CLEAR_EXERCISE_SEARCH_RESULTS';

// Workout Action Types
export const GET_HOMEPAGE_WORKOUTS = 'GET_HOMEPAGE_WORKOUTS';
export const GET_TRENDING_WORKOUTS = 'GET_TRENDING_WORKOUTS';
export const GET_WORKOUTS_BY_CREATOR = 'GET_WORKOUTS_BY_CREATOR';
export const SEARCH_WORKOUTS = 'SEARCH_WORKOUTS';
export const GET_WORKOUTS_FROM_ARRAY = 'GET_WORKOUTS_FROM_ARRAY';
export const GET_WORKOUT_BY_ID = 'GET_WORKOUT_BY_ID';
export const GET_SUGGESTED_WORKOUTS = 'GET_SUGGESTED_WORKOUTS';
export const CREATE_WORKOUT = 'CREATE_WORKOUT';
export const UPDATE_WORKOUT = 'UPDATE_WORKOUT';
export const DELETE_WORKOUT = 'DELETE_WORKOUT';
export const LIKE_WORKOUT = 'LIKE_WORKOUT';
export const INCREMENT_WORKOUT_RUNS = 'INCREMENT_WORKOUT_RUNS';
export const WORKOUT_ERROR = 'WORKOUT_ERROR';
export const CLEAR_WORKOUT = 'CLEAR_WORKOUT';
export const CLEAR_WORKOUT_SEARCH_RESULTS = 'CLEAR_WORKOUT_SEARCH_RESULTS';

// Program Action Types
export const IS_MEMBER = 'IS_MEMBER';
export const FETCH_PROGRAMS_BY_INVITED_USER = 'FETCH_PROGRAMS_BY_INVITED_USER';
export const FETCH_PROGRAMS_BY_MEMBER = 'FETCH_PROGRAMS_BY_MEMBER';
export const FETCH_PROGRAMS_BY_REQUESTED_USER = 'FETCH_PROGRAMS_BY_REQUESTED_USER';
export const FETCH_SUGGESTED_PROGRAMS = 'FETCH_SUGGESTED_PROGRAMS';
export const GET_HOMEPAGE_PROGRAMS = 'GET_HOMEPAGE_PROGRAMS';
export const GET_TRENDING_PROGRAMS = 'GET_TRENDING_PROGRAMS';
export const CREATE_PROGRAM = 'CREATE_PROGRAM';
export const DELETE_PROGRAM = 'DELETE_PROGRAM';
export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_INVITED_USERS = 'GET_INVITED_USERS';
export const GET_REQUESTED_USERS = 'GET_REQUESTED_USERS';
export const ADD_JOIN_REQUEST = 'ADD_JOIN_REQUEST';
export const REMOVE_JOIN_REQUEST = 'REMOVE_JOIN_REQUEST';
export const ADD_MEMBER = 'ADD_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const LEAVE_PROGRAM = 'LEAVE_PROGRAM';
export const DENY_JOIN_REQUEST = 'DENY_JOIN_REQUEST';
export const SCHEDULE_WORKOUT = 'SCHEDULE_WORKOUT';
export const REMOVE_WORKOUT = 'REMOVE_WORKOUT';
export const UNSCHEDULE_WORKOUT = 'UNSCHEDULE_WORKOUT';
export const ADD_COMPETITION = 'ADD_COMPETITION';
export const REMOVE_COMPETITION = 'REMOVE_COMPETITION';
export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';
export const ADD_MEMBER_BY_JOIN_CODE = 'ADD_MEMBER_BY_JOIN_CODE';
export const ADD_INVITED_USER = 'ADD_INVITED_USER';
export const REMOVE_INVITED_USER = 'REMOVE_INVITED_USER';
export const ACCEPT_PROGRAM_INVITATION = 'ACCEPT_PROGRAM_INVITATION';
export const FETCH_PROGRAMS_BY_CREATOR = 'FETCH_PROGRAMS_BY_CREATOR';
export const FETCH_PROGRAM_BY_ID = 'FETCH_PROGRAM_BY_ID';
export const SEARCH_PROGRAMS = 'SEARCH_PROGRAMS';
export const PROGRAM_ERROR = 'PROGRAM_ERROR';
export const GET_EXERCISES_BY_PROGRAM = 'GET_EXERCISES_BY_PROGRAM';
export const GET_WORKOUTS_BY_PROGRAM = 'GET_WORKOUTS_BY_PROGRAM';
export const JOIN_PROGRAM = 'JOIN_PROGRAM';
export const CLEAR_PROGRAM_SEARCH_RESULTS = 'CLEAR_PROGRAM_SEARCH_RESULTS';
export const CLEAR_JOIN_CODE_ERROR = 'CLEAR_JOIN_CODE_ERROR';
export const JOIN_CODE_ERROR = 'JOIN_CODE_ERROR';

// Video Action Types
export const GET_HOMEPAGE_VIDEOS = 'GET_HOMEPAGE_VIDEOS';
export const FETCH_TRENDING_VIDEOS = 'FETCH_TRENDING_VIDEOS';
export const FETCH_VIDEOS_BY_CREATOR = 'FETCH_VIDEOS_BY_CREATOR';
export const SEARCH_VIDEOS = 'SEARCH_VIDEOS';
export const GET_VIDEO = 'GET_VIDEO';
export const CREATE_VIDEO = 'CREATE_VIDEO';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const LIKE_VIDEO = 'LIKE_VIDEO';
export const INCREMENT_VIEWS = 'INCREMENT_VIEWS';
export const VIDEO_ERROR = 'VIDEO_ERROR';
export const CLEAR_VIDEO_SEARCH_RESULTS = 'CLEAR_VIDEO_SEARCH_RESULTS';

// Comment Action Types
export const FETCH_COMMENTS_BY_EXERCISE = 'FETCH_COMMENTS_BY_EXERCISE';
export const FETCH_COMMENTS_BY_WORKOUT = 'FETCH_COMMENTS_BY_WORKOUT';
export const FETCH_COMMENTS_BY_VIDEO = 'FETCH_COMMENTS_BY_VIDEO';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const FETCH_COMMENT_BY_ID = 'FETCH_COMMENT_BY_ID';

// Bookmark Action Types
export const FETCH_BOOKMARKED_EXERCISES = 'FETCH_BOOKMARKED_EXERCISES';
export const FETCH_BOOKMARKED_WORKOUTS = 'FETCH_BOOKMARKED_WORKOUTS';
export const FETCH_EXERCISE_BOOKMARKS_BY_CREATOR = 'FETCH_EXERCISE_BOOKMARKS_BY_CREATOR';
export const FETCH_WORKOUT_BOOKMARKS_BY_CREATOR = 'FETCH_WORKOUT_BOOKMARKS_BY_CREATOR';
export const TOGGLE_BOOKMARK = 'TOGGLE_BOOKMARK';
export const DELETE_BOOKMARKS_BY_EXERCISE_ID = 'DELETE_BOOKMARKS_BY_EXERCISE_ID';
export const DELETE_BOOKMARKS_BY_WORKOUT_ID = 'DELETE_BOOKMARKS_BY_WORKOUT_ID';

// Log Action Types
export const FETCH_LOGS = 'FETCH_LOGS';
export const TWO_MONTHS = 'TWO_MONTHS';
export const CREATE_LOG = 'CREATE_LOG';
export const DELETE_LOG = 'DELETE_LOG';
export const UPDATE_LOG_VISIBILITY = 'UPDATE_LOG_VISIBILITY';
export const FETCH_LOGS_BY_MONTH_AND_WORKOUT = 'FETCH_LOGS_BY_MONTH_AND_WORKOUT';
export const  UPLOAD_LOG_TO_PROGRAM = 'UPLOAD_LOG_TO_PROGRAM';
export const REMOVE_LOG_FROM_PROGRAM = 'REMOVE_LOG_FROM_PROGRAM';
export const FETCH_LOGS_BY_PROGRAM = 'FETCH_LOGS_BY_PROGRAM';
export const GET_COMPETITION_RESULTS = 'GET_COMPETITION_RESULTS';

// Stats Action Types
export const FETCH_STATS_BY_CREATOR = 'FETCH_STATS_BY_CREATOR';
export const CREATE_STATS = 'CREATE_STATS';
export const INCREMENT_STATS = 'INCREMENT_STATS';

// Payment Action Types
export const GET_STRIPE_LOGIN_LINK = 'GET_STRIPE_LOGIN_LINK';
export const CREATE_STRIPE_CONNECTED_ACCOUNT = 'CREATE_STRIPE_CONNECTED_ACCOUNT';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CHECK_SUBSCRIPTION_STATUS = 'CHECK_SUBSCRIPTION_STATUS';
// export const POST_PAYMENT = 'POST_PAYMENT';