import React from 'react';
import {
  getTrendingWorkouts,
  searchWorkouts,
  clearSearchResults,
} from '../../actions/workout';
import { getWorkoutBookmarksByCreator } from '../../actions/user';
import WorkoutCard from './WorkoutCard';
import ContentPage from '../Content/ContentPage';
import { selectTrendingWorkouts, selectSearchResults } from '../../reducers/workout';

const WorkoutSearch = () => (
  <ContentPage
    title="Workouts"
    searchPlaceholder="Search Workouts..."
    fetchTrendingAction={getTrendingWorkouts}
    searchAction={searchWorkouts}
    clearSearchResultsAction={clearSearchResults}
    fetchBookmarksAction={getWorkoutBookmarksByCreator}
    CardComponent={WorkoutCard}
    trendingItemsSelector={selectTrendingWorkouts}
    searchResultsSelector={selectSearchResults}
  />
);

export default WorkoutSearch;
