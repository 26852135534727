import React from "react";
import { Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const Metrics = (props) => {

    const theme = useTheme();

    const controlScore = props.controlScore;

    const controlMetricStyle = {
        width: `${controlScore * 100}%`,
        height: '15px',
        backgroundColor: theme.palette.primary.main,
    };

    return (
        <Container sx={{ textAlign: 'left' }}>
            <Typography variant="h6" style={{color: theme.palette.text.secondary}}>
                Control
            </Typography>
            <div style={controlMetricStyle}></div>
        </Container>
    );
};

export default Metrics;