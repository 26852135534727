import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ color: '#ffffff' }}>
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Effective Date: August 26, 2024
        </Typography>
        
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          Welcome to Gains Coach. We are committed to protecting your privacy and ensuring that your personal
          information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use,
          store, and protect your personal information when you use our services, in compliance with applicable data 
          protection laws, including the GDPR and the California Consumer Privacy Act (CCPA), as amended by the 
          California Privacy Rights Act (CPRA).
        </Typography>
        
        <Typography variant="h5" gutterBottom>
          2. Information We Collect
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Personal Information: This includes your name and email address, which you provide when you create an account with us." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Uploaded Content: Any images or videos that you upload to our platform. You have the option to set visibility settings for this content." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          3. Lawful Basis for Processing Personal Data
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Consent: You provide consent for the processing of your data when signing up or sharing content." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Contractual Necessity: Processing your data is required to fulfill our contract when you use our services." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Legitimate Interests: We process your data to improve services, subject to balancing your rights." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Legal Obligation: We may be required to process data to comply with legal obligations." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          4. Content Visibility and Sharing
        </Typography>
        <Typography variant="body1" gutterBottom>
          We allow you to control the visibility of the content you upload. You can choose to make your content:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Everyone: Visible to all users of the platform." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Private: Only visible to you." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Followers: Visible to users who follow you." />
          </ListItem>
        </List>
        <Typography variant="body1" gutterBottom>
          Please note that even when content is set to private, we may still access it for security, legal, or technical reasons, but only as needed.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Content Deletion
        </Typography>
        <Typography variant="body1" gutterBottom>
          You have the right to delete any personal information or uploaded content directly from your profile page at any time. Once deleted, the content will be permanently removed from our systems and will no longer be accessible to you or other users. However, please be aware that deleted content may remain in our backup systems for a limited time as part of our data integrity processes but will not be accessible through the platform.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Data Breach Notification
        </Typography>
        <Typography variant="body1" gutterBottom>
          In the event of a data breach that poses a risk to your personal data, we will notify you and the appropriate data protection authorities within 72 hours of becoming aware of the breach, as required by GDPR and California law.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Data Security
        </Typography>
        <Typography variant="body1" gutterBottom>
          We are committed to protecting your data. We employ a range of industry-standard security measures, including encryption and secure storage, to safeguard your information against unauthorized access, alteration, disclosure, or destruction.
        </Typography>

        <Typography variant="h5" gutterBottom>
          8. Data Retention
        </Typography>
        <Typography variant="body1" gutterBottom>
          We retain your personal information and uploaded content for as long as necessary to provide our services to you, or as required by law. You can delete your content or account at any time, and we will promptly remove your data from our systems unless retention is required for legal or legitimate business purposes.
        </Typography>

        <Typography variant="h5" gutterBottom>
          9. Your Rights (Including CCPA/CPRA Rights)
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Right to be informed: You have the right to know how your personal data is being processed." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Right of access: You can request access to the personal data we hold about you." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Right to rectification: You can request corrections to any inaccurate information." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Right to erasure: You can request that we delete your personal data, subject to legal requirements." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Right to opt out of the sale of personal data: We do not sell personal data. If this changes, you have the right to opt out." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Right to non-discrimination: You will not be discriminated against for exercising your data rights." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          10. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated policy on our website or through email communication.
        </Typography>

        <Typography variant="h5" gutterBottom>
          11. Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" color="primary">
          team@gainscoach.com
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;