import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Typography, Stack, TextField, Container, Box, Collapse, Alert, IconButton, Divider, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import { createWorkout, updateWorkout, getWorkoutById } from '../../actions/workout.js';
import { getExerciseBookmarksWithTitles } from '../../actions/user.js';
import Set from './Set.js';
import FileInput from '../Form/FileInput.js';

const CreateWorkout = ({ currentId, setCurrentId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem('profile'));

  const workout = useSelector((state) => state.workout.workout);
  const bookmarks = useSelector((state) => state.user.exerciseBookmarksWithTitles);

  const [workoutData, setWorkoutData] = useState({
    ids: [],
    reps: [],
    title: "",
    description: "",
    visibility: "followers",
  });

  const [rawThumbnail, setRawThumbnail] = useState(null);
  const [formError, setFormError] = useState('');
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (currentId !== 0) {
      dispatch(getWorkoutById(currentId));
    }
  }, [currentId, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(getExerciseBookmarksWithTitles());
    }
  }, [dispatch]);

  useEffect(() => {
    if (workout && currentId !== 0) {
      setWorkoutData({
        ids: workout.exercises.map(exercise => [exercise._id, exercise.title]),
        reps: workout.reps,
        title: workout.title,
        description: workout.description,
        thumbnail: workout.thumbnail,
        visibility: workout.visibility,
      });
      setKeys(Array(workout.reps.length).fill(0));
    }
  }, [workout, currentId]);

  const handleVisibilityChange = (e) => {
    setWorkoutData({ ...workoutData, visibility: e.target.value });
  };

  const increment = () => {
    setWorkoutData(prevData => ({
      ...prevData,
      ids: [...prevData.ids, null],
      reps: [...prevData.reps, 1],
    }));
    setKeys(prevKeys => [...prevKeys, 0]);
  };

  const sendIds = (input, index) => {
    const updatedIds = [...workoutData.ids];
    updatedIds[index] = input;
    setWorkoutData({ ...workoutData, ids: updatedIds });
  };

  const extractIds = (idsWithTitles) => {
    return idsWithTitles.map(idWithTitle => idWithTitle[0]);
  }

  const sendReps = (input, index) => {
    const updatedReps = [...workoutData.reps];
    updatedReps[index] = input;
    setWorkoutData({ ...workoutData, reps: updatedReps });
  };

  const remExercise = (index) => {
    const updatedIds = [...workoutData.ids];
    const updatedReps = [...workoutData.reps];
    const updatedKeys = [...keys];
    updatedIds.splice(index, 1);
    updatedReps.splice(index, 1);
    updatedKeys.splice(index, 1);
    setWorkoutData({ ...workoutData, ids: updatedIds, reps: updatedReps });
    setKeys(updatedKeys);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hasNullReps = workoutData.reps.some(reps => reps < 0);
    const hasNullIds = workoutData.ids.some(id => id == null);

    if (workoutData.title === "") {
      setFormError("Please fill out all fields");
    } else if (keys.length < 1) {
      setFormError("Please provide at least one exercise");
    } else if (hasNullReps) {
      setFormError("Please fill out all repetition fields");
    } else if (hasNullIds) {
      setFormError("Please fill out all exercise fields");
    } else {
      setFormError("");
      
      const workoutPayload = {
        visibility: workoutData.visibility,
        creator: user.result.email,
        reps: workoutData.reps,
        exercises: extractIds(workoutData.ids),
        title: workoutData.title,
        description: workoutData.description,
        thumbnail: rawThumbnail || workoutData.thumbnail,
      };

      if (currentId === 0) {
        dispatch(createWorkout(workoutPayload));
      } else {
        dispatch(updateWorkout(currentId, workoutPayload));
      }
      navigate('/home');
    }
  };

  const rowStyle = {
    margin: '5px',
  };

  if (!user) {
    return (
      <Paper elevation={6}>
        <Typography variant="h6" align="center">
          Please Sign In
        </Typography>
      </Paper>
    );
  } else if (user?.result?._id !== workout?.creator?._id && currentId !== 0) {
    return (
      <Paper elevation={6}>
        <Typography variant="h6" align="center">
          Not Authorized to Edit this Workout
        </Typography>
      </Paper>
    );
  } else if (bookmarks.length === 0) {
    return (
      <Paper elevation={6}>
        <Typography variant="h6" align="center">
          Bookmark exercises to create workouts
        </Typography>
      </Paper>
    );
  } else {
    return (
      <Container sx={{ mt: theme.spacing(1) }}>
        <Paper elevation={5} sx={{ p: '20px' }}>
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center" flexDirection="column" width="100%">
              <Typography variant="h6" sx={rowStyle}>Workout Builder</Typography>
              <TextField name="title" variant="outlined" label="Title" sx={rowStyle} fullWidth value={workoutData.title} onChange={(e) => setWorkoutData({ ...workoutData, title: e.target.value })} required />
              <TextField name="description" variant="outlined" label="Description" sx={rowStyle} fullWidth value={workoutData.description} onChange={(e) => setWorkoutData({ ...workoutData, description: e.target.value })} />
              <Button variant="contained" color="primary" type="button" onClick={increment}>Add Set</Button>
              {keys.map((_, index) => (
                <Set
                  key={index}
                  updateOrder={remExercise}
                  num={index}
                  sendIds={sendIds}
                  bookmarks={bookmarks}
                  sendReps={sendReps}
                  id={workoutData.ids[index]}
                  reps={workoutData.reps[index]}
                />
              ))}

              <Divider sx={{m: '15px 0px', width: '100%'}}/>
              <Box color="secondary" display="flex" flexDirection="column" alignItems="center">
                <FormLabel>Visibility</FormLabel>
                <RadioGroup row onChange={handleVisibilityChange} value={workoutData.visibility}>
                  {["Everyone", "Followers", "Private"].map((option, index) => (
                    <Box key={index} display="flex" flexDirection="row" sx={{ justifyContent: 'space-between' }}>
                      <FormControlLabel value={option.toLowerCase()} control={<Radio />} label={option} />
                    </Box>
                  ))}
                </RadioGroup>
              </Box>
              <Divider sx={{m: '15px 0px'}}/>

              <Box sx={{ maxWidth: '200px', marginTop: '20px', marginBottom: '10px' }}>
                <FileInput val="" base64={false} onDone={(file) => setRawThumbnail(file)}>
                  Upload Thumbnail
                </FileInput>
              </Box>
              <Stack direction="row" spacing={2} sx={rowStyle}>
                <Button variant="contained" startIcon={<PublishIcon />} color="primary" type="submit">Submit</Button>
              </Stack>
            </Box>
          </form>
        </Paper>

        <Box sx={{ width: '100%', mt: '10px' }}>
          <Collapse in={formError !== ""}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setFormError("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {formError}
            </Alert>
          </Collapse>
        </Box>
      </Container>
    );
  }
};

export default CreateWorkout;
