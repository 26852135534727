import React from "react";
import { Link } from 'react-router-dom';
import { Button, IconButton, Menu, MenuItem, Box } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

function CreateMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const largeScreenStyle = {
    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
  };

  const mediumScreenStyle = {
    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
    color: 'text.primary',
  };

  const smallScreenStyle = {
    display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' },
    margin: '0px 5px',
  };

  return (
    <Box className="mui-fixed" sx={{boxSizing: 'content-box'}}>
      <Button 
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
        startIcon={<AddOutlinedIcon color='text.primary' sx={largeScreenStyle}/>}
        sx={mediumScreenStyle}
      >
        Create
      </Button>
      <IconButton sx={smallScreenStyle}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <AddOutlinedIcon color='text.primary'/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <MenuItem component={Link} to='/exercise' onClick={handleClose}>Exercise</MenuItem>
        <MenuItem component={Link} to='/workout' onClick={handleClose}>Workout</MenuItem>
        <MenuItem component={Link} to='/program' state={{ from: 'create' }} onClick={handleClose}>Program</MenuItem>
      </Menu>
    </Box>
  );
}

export default CreateMenu;