import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography, Grid, Divider, Container, Pagination, PaginationItem, Box, Button, Stack } from '@mui/material';
import { getProgramsByMember , getSuggestedPrograms, getProgramsByInvitedUser, getProgramsByRequestedUser} from '../../../actions/program';
import { useTheme } from '@mui/material/styles';
import Program from '../Admin/ProgramCard';
import JoinCode from './JoinCode';

const MemberPage = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const theme = useTheme();
    const dispatch = useDispatch();

    const { programs, suggestedPrograms, invitedPrograms, requestedPrograms } = useSelector((state) => state.program);

    const totalMemberPages = programs?.totalPages || 1;
    const totalSuggestedPages = suggestedPrograms?.totalPages || 1;
    const totalInvitedPages = invitedPrograms?.totalPages || 1;
    const totalRequestedPages = requestedPrograms?.totalPages || 1;

    const [memberPage, setMemberPage] = useState(1);
    const [suggestedPage, setSuggestedPage] = useState(1);
    const [invitedPage, setInvitedPage] = useState(1);
    const [requestedPage, setRequestedPage] = useState(1);

    useEffect(() => {
      if (user != null) {
        dispatch(getProgramsByMember(memberPage));
      }
    }, [dispatch, memberPage]);

    useEffect(() => {
      if (user != null) {
        dispatch(getSuggestedPrograms(suggestedPage));
      }
    }, [dispatch, suggestedPage]);

    useEffect(() => {
      if (user != null) {
        dispatch(getProgramsByInvitedUser(invitedPage));
      }
    }, [dispatch, invitedPage]);

    useEffect(() => {
      if (user != null) {
        dispatch(getProgramsByRequestedUser(requestedPage));
      }
    }, [dispatch, requestedPage]);

    const handleMemberPageChange = (event, value) => {
      if (value <= totalMemberPages) {
        setMemberPage(value);
      }
    };

    const handleInvitedPageChange = (event, value) => {
      if (value <= totalInvitedPages) {
        setInvitedPage(value);
      }
    };

    const handleRequestedPageChange = (event, value) => {
      if (value <= totalInvitedPages) {
        setRequestedPage(value);
      }
    };

    const handleSuggestedPageChange = (event, value) => {
      if (value <= totalSuggestedPages) {
        setSuggestedPage(value);
      }
    };

    const styles = {
      joinedProgramsHeaderStack: {
        flexDirection: {
          xs: 'column',
          sm: 'row',
          md: 'row',
        },
        spacing: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    };

    return (
      <Container sx={{ mt: theme.spacing(1) }}>
        { user == null &&
          <Paper elevation={6}>
            <Typography variant="h6" align="center">
              Please Sign In
            </Typography>
          </Paper>
        }
        <Stack sx={styles.joinedProgramsHeaderStack}>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 3, mb: 1 }}>
            Joined Programs
          </Typography>
          <JoinCode />
        </Stack>
        
        <Divider style={{ margin: '20px 0 30px 0' }} />
          {!programs?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No programs found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {programs.data.map((program) => (
                  <Grid key={program._id} item>
                    <Program item={program} />
                  </Grid>
                ))}
              </Grid>    
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalMemberPages}
                  page={memberPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleMemberPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Invited Programs
          </Typography>
        <Divider style={{ margin: '20px 0 30px 0' }} />
          {!invitedPrograms?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No programs found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {invitedPrograms.data.map((program) => (
                  <Grid key={program._id} item>
                    <Program item={program}/>
                  </Grid>
                ))}
              </Grid>    
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalInvitedPages}
                  page={invitedPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleInvitedPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Requested Programs
          </Typography>
        <Divider style={{ margin: '20px 0 30px 0' }} />
          {!requestedPrograms?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No programs found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {requestedPrograms.data.map((program) => (
                  <Grid key={program._id} item>
                    <Program item={program}/>
                  </Grid>
                ))}
              </Grid>    
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalRequestedPages}
                  page={requestedPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleRequestedPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}

        <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Suggested Programs
          </Typography>
        <Divider style={{ margin: '20px 0 30px 0' }} />
          {!suggestedPrograms?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No programs found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {suggestedPrograms.data.map((program) => (
                  <Grid key={program._id} item>
                    <Program item={program}/>
                  </Grid>
                ))}
              </Grid>    
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalSuggestedPages}
                  page={suggestedPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleSuggestedPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}
          </Container>
    );
}


export default MemberPage;
