import { FETCH_LOGS, TWO_MONTHS, CREATE_LOG, DELETE_LOG, UPDATE_LOG_VISIBILITY, FETCH_LOGS_BY_MONTH_AND_WORKOUT, UPLOAD_LOG_TO_PROGRAM, REMOVE_LOG_FROM_PROGRAM, FETCH_LOGS_BY_PROGRAM, GET_COMPETITION_RESULTS } from '../constants/actionTypes';

export default (logs = { logs: [], publicLogs: [], monthLogs: [], publicMonthLogs: [], twoMonths: [], memberLogs: [], ownerLogs: [], results: [], users: [] }, action) => {
  switch (action.type) {
    case FETCH_LOGS:
      return { ...logs, logs: action.payload };
    case TWO_MONTHS:
      return {...logs, twoMonths: action.payload };
    case CREATE_LOG:
      return { ...logs, logs: [...logs.logs, action.payload] };
    case DELETE_LOG:
      return { ...logs, logs: logs.logs.filter((log) => log._id !== action.payload) };
    case UPDATE_LOG_VISIBILITY:
      return {
        ...logs,
        logs: logs.logs.map((log) => (log._id === action.payload._id ? action.payload : log)),
      };
    case FETCH_LOGS_BY_MONTH_AND_WORKOUT:
      return {
        ...logs,
        memberLogs: action.payload
      }
    case UPLOAD_LOG_TO_PROGRAM: 
      function transformLog(log, referenceLog, programId) {
        // Helper function to check if two dates are on the same day
        function isSameDay(date1, date2) {
          return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate();
        }
      
        // Check if log meets the conditions
        if (isSameDay(new Date(log.createdAt), new Date(referenceLog.createdAt)) &&
            log.workoutId === referenceLog.workoutId &&
            log.programs.includes(programId)) {
              
          // Remove programId from programs if it exists
          log.programs = log.programs.filter(program => program !== programId);
        }
        return log;
      }
      return {
        ...logs,
        memberLogs: logs.memberLogs.map((log) => (log._id === action.payload.data._id ? action.payload.data : transformLog(log, action.payload.data, action.payload.programId))),
      }
    case REMOVE_LOG_FROM_PROGRAM:
      return {
        ...logs, memberLogs: logs.memberLogs.map((log) => (log._id === action.payload._id ? action.payload : log))
      }
    case FETCH_LOGS_BY_PROGRAM: 
      return {
        ...logs, ownerLogs: action.payload
      }
    case GET_COMPETITION_RESULTS:
      return {
        ...logs, results: action.payload.result, users: action.payload.users
      }
    default:
      return logs;
  }
};