import React from 'react';
import { getHomepageWorkouts, getHomepageWorkoutsForGuest } from '../../actions/workout';
import { getWorkoutBookmarksByCreator } from '../../actions/user';
import GenericHomeContent from '../Content/HomeContent';
import WorkoutCard from './WorkoutCard';

const HomeWorkouts = () => {
  const user = JSON.parse(localStorage.getItem('profile'));

  const fetchDataAction = user ? getHomepageWorkouts : getHomepageWorkoutsForGuest;

  const reduxStateSelector = (state) => state.workout.homepageWorkouts;

  return (
    <GenericHomeContent
      title="Workouts"
      fetchDataAction={fetchDataAction}
      fetchGuestDataAction={getHomepageWorkoutsForGuest}
      bookmarksAction={getWorkoutBookmarksByCreator}
      CardComponent={WorkoutCard}
      navigatePath="/workouts"
      reduxStateSelector={reduxStateSelector}
      containerTitle="Workout"
    />
  );
};

export default HomeWorkouts;
