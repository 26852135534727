import { 
  CREATE_STRIPE_CONNECTED_ACCOUNT,
  CREATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_STATUS,
  GET_STRIPE_LOGIN_LINK
} from '../constants/actionTypes';
  
const initialState = {
  stripeLoginLink: null,
  payment: null,
  error: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STRIPE_LOGIN_LINK:
      return {...state, stripeLoginLink: action.payload.data};
    case CREATE_STRIPE_CONNECTED_ACCOUNT:
    case CREATE_SUBSCRIPTION:
    case CANCEL_SUBSCRIPTION:
    case CHECK_SUBSCRIPTION_STATUS:
      return {...state, payment: action.payload.data};
    default:
      return state;
  }
};
  
export default paymentReducer;