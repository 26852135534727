import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/UploadFileOutlined';
import UploadCompleteIcon from '@mui/icons-material/TaskOutlined';


export default function FileInput(props) {
    const [baseImage, setBaseImage] = useState(props.val);
    const [error, setError] = useState("");
    const SIZE_LIMIT = 400; // in kb

    const uploadImage = async (e) => {
        setError("");
        const file = e.target.files[0];

        // security checks
        if (!file) return;
        else if (file.size > 1024 * SIZE_LIMIT) {
            setError(`File size should be less than ${SIZE_LIMIT}kb`);
            return;
        }

        const base64 = await convertBase64(file);
        setBaseImage(base64);

        if (props.base64) {
            props.onDone({ base64, file });
        } else {
            props.onDone(file);
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }; 
    
    return (
        <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
            <label>
                <div>
                    <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={(e) => uploadImage(e)} />   
                    <Button position="absolute" color="primary" component='span' startIcon={!baseImage || baseImage?.length === 0 ? <FileUploadIcon/> : <UploadCompleteIcon/>} >
                        {props.children}
                    </Button>
                </div>
                
                <img style={{margin: '0 auto', height: '75px', maxWidth: '130px' }} src={baseImage} />
            </label>
            <Typography variant="caption" color="error">{error}</Typography>
        </div>
    )
}