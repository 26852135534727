import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container, Link, Box, Collapse, Alert, IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTheme } from '@mui/material/styles';
import Icon from './icon';
import { signIn, signUp } from '../../actions/user';
import { AUTH } from '../../constants/actionTypes';
import CloseIcon from '@mui/icons-material/Close';
import Input from './Input';

// Google OAuth Client ID
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Initial state for form
const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', username: '' };

const SignUp = () => {
  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState('');
  const [formInfo, setFormInfo] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [strength, setStrength] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const userError = useSelector((state) => state.user.error);
  const submitted = useSelector((state) => state.user.submitted);
  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: 'email profile'
      });
    }

    gapi.load('client:auth2', start);
  });

  useEffect(() => {
    if (userError) {
      setFormError(userError);
    } 
    if (submitted) {
      setFormInfo("Please check your email for a verification link.");
      setIsSignup(false);
    }
  }, [userError, submitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError("");
    setFormInfo("");
  
    const formData = {
      ...form,
      name: `${form.firstName} ${form.lastName}`
    };
  
    if (isSignup && !agreedToTerms) {
      setFormError("Please accept the Terms of Service.");
    } else if (isSignup && form.password !== form.confirmPassword) {
      setFormError("Passwords do not match.");
    } else if (isSignup) {
      await dispatch(signUp(formData));
    } else {
      const response = await dispatch(signIn(form));
      if (response?.success) {
        navigate('/');
      } else {
        setFormError(response.error);
      }
    }
  };

  const evalStrength = (strength) => {
    const strengthLevels = ["Very Weak", "Weak", "Moderate", "Good", "Strong"];
    return strengthLevels[strength];
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;
  
    try {
      const response = await dispatch(signIn({ email: result.email, googleToken: token }));
      if (response?.success) {
        dispatch({ type: AUTH, data: response.data });
        navigate('/');
      } else {
        if (response?.error === "User doesn't exist") {
          setFormError("Please create an account first before signing in");
          setIsSignup(true);
        } else {
          setFormError(response.error);
        }
      }
    } catch (error) {
      console.log(error);
      setFormError("An unexpected error occurred. Please try again later.");
    }
  };

  const googleError = () => {
    console.log('Google Sign In was unsuccessful. Try again later');
    setFormError("Google Sign In was unsuccessful. Try again later.");
  };

  const handleTermsChange = (event) => {
    setAgreedToTerms(event.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'password') {
      let strength = 0;
      if (value.length > 7) strength++;
      if (/[A-Z]/.test(value)) strength++;
      if (/[a-z]/.test(value)) strength++;
      if (/[0-9]/.test(value)) strength++;
      setStrength(strength);
    }

    setForm({ ...form, [name]: value });
  };

  const classes = {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    googleButton: {
      marginBottom: theme.spacing(2),
    },
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper style={classes.paper} elevation={6}>
        <Avatar style={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>
        <form style={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            { isSignup && (
              <>
                <Input name="firstName" label="First Name" handleChange={handleChange} half />
                <Input name="lastName" label="Last Name" handleChange={handleChange} half />
                <Input name="username" label="Username" handleChange={handleChange} fullWidth />
              </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email"/>
            {isSignup && (
              <Typography component="h5" variant="h10" marginTop="10px"> 
                Password Strength: {evalStrength(strength)} 
              </Typography>
            )}
            <Input 
              name="password" 
              label="Password" 
              handleChange={handleChange} 
              type={showPassword ? 'text' : 'password'} 
              handleShowPassword={handleShowPassword} 
            />
            {!isSignup && (
              <Typography>
                <Link onClick={() => navigate("/forgot")}> Forgot Password </Link>
              </Typography>
            )}
            {isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" />}
          </Grid>

          {isSignup && 
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={handleTermsChange}
                    name="termsCheckbox"
                    color="primary"
                  />
                }
                label={
                  <>
                    I accept the{' '}
                    <Link component={RouterLink} to="/terms" color="primary">
                      Terms of Service
                    </Link>
                  </>
                }
              />
            </FormGroup>
          }

          <Button type="submit" fullWidth variant="contained" color="primary" style={classes.submit}>
            { isSignup ? 'Sign Up' : 'Sign In' }
          </Button>
          <GoogleLogin
            clientId={clientId}
            render={(renderProps) => (
              <Button 
                style={classes.googleButton} 
                color="primary" 
                fullWidth 
                onClick={renderProps.onClick} 
                disabled={renderProps.disabled} 
                startIcon={<Icon />} 
                variant="contained"
              >
                Google Sign In
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleError}
            cookiePolicy="single_host_origin"
          />
          {isSignup && (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Typography fontWeight="heavy" component="h5" variant="h10"> 
                Satisfy at least 3 of the following 4 criteria: 
              </Typography>
              <Typography fontWeight="light" component="h5" variant="h10"> 
                7 or more characters 
              </Typography>
              <Typography fontWeight="light" component="h5" variant="h10"> 
                At least one lowercase character 
              </Typography>
              <Typography fontWeight="light" component="h5" variant="h10"> 
                At least one uppercase character 
              </Typography>
              <Typography fontWeight="light" component="h5" variant="h10"> 
                At least one digit 
              </Typography> 
            </Box>
          )}
          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                { isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Box sx={{ width: '100%', mt: '10px' }}>
        <Collapse in={formError !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setFormError("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {formError}
          </Alert>
        </Collapse>
        <Collapse in={formInfo !== ""}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setFormInfo("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {formInfo}
          </Alert>
        </Collapse>
      </Box>
    </Container>
  );
};

export default SignUp;
