import Detector2D from "./inference2D";
import Detector3D from "./inference3D";
import * as math from "./math";
import * as tf from '@tensorflow/tfjs';


export class Analyzer {
    constructor(dynamicAngles, points=5) {
        // initialize the 2D and 3D detectors
        this.detector2D = new Detector2D();
        this.detector3D = new Detector3D();
        
        // video create angles
        this.vangles = [];

        // uses history?
        this.usesHist = dynamicAngles?.length > 0;

        // initialize angle history
        this.hist = new History(dynamicAngles, points);
        
        // keep track of the phase
        this.phase = -1;
    }

    // Returns 2D keypoints from an image
    async detectKeypoints2D(image) {
        return this.detector2D.detect(image);
    }

    // Returns 3D keypoints from an image
    async detectKeypoints3D(image) {
        return this.detector3D.detect(image);
    }
    
    // For exercise creation, include both 2D and 3D keypoints/angles to ensure hybrid compatibility
    async create(image) {
        // detect 2D and 3D keypoints
        await tf.ready();
        const pose2D = await this.detectKeypoints2D(image);
        const pose3D = await this.detectKeypoints3D(image);
        
        // if neither 2D nor 3D keypoints are detected, return null
        if (pose3D == null || pose2D == null) return null;

        // calculate the angles
        const angles2D = math.compile2D(pose2D.keypoints);
        const angles3D = math.compile3D(pose3D.keypoints3D);
        return { angles2D: angles2D.angles, orientation2D: angles2D.orientation, skeleton2D: pose2D, angles3D: angles3D.angles, orientation3D: angles3D.orientation, skeleton3D: pose3D};
    }

    /**
     * For running exercises, first check whether 2D keypoints/angles are sufficient, as they are more accurate.
     * If 2D keypoints/angles are not sufficient, only use 3D keypoints/angles.
     * If 2D angles are sufficient, compare them to the reference angles.
     * If the 2D angles are not correct, switch to 3D angles.
    */
    async run(image, refangles, refangles2D, focus, phase, orientation2D, orientation3D) {
        let run3D = true;
        let runAngles = null;
        let runSkeleton = null;
        let feedback  = [];
        // detect 2D keypoints
        await tf.ready();
        const pose2D = await this.detectKeypoints2D(image);
        if (pose2D == null) return null;

        // determine if the 2D keypoints are sufficient
        const run2D = math.hybridFunction(pose2D.keypoints);

        if (run2D) {
            const compile2D = math.compile2D(pose2D.keypoints);
            const angles2D = compile2D.angles;
            const runOrientation = compile2D.orientation;
            runAngles = angles2D; 
            runSkeleton = pose2D;
            if (focus[0] > 0) {
                feedback = feedback.concat(math.compare_upper2D(refangles2D, angles2D, focus[0]));
            }
            if (focus[1] > 0) {
                feedback = feedback.concat(math.compare_lower2D(refangles2D, angles2D, focus[0]));
            }
            if (focus[2] > 0) {
                feedback = feedback.concat(math.compare_central2D(refangles2D, angles2D, focus[0]));
            }
            if (feedback.length == 0 && runOrientation != orientation2D) {
                feedback = [[33, 34, 35]]
            }
            if (feedback.length === 0) {
                run3D = false;
            }
        }

        if (run3D) {
            const pose3D = await this.detectKeypoints3D(image);
            if (pose3D == null) return null;
            const compile3D = math.compile3D(pose3D.keypoints3D);
            const angles3D = compile3D.angles;
            const runOrientation = compile3D.orientation;
            if (runAngles == null) {
                runAngles = angles3D;
            }
            if (runSkeleton == null) {
                runSkeleton = pose3D;
            }
            if (focus[0] > 0) {
                feedback = feedback.concat(math.compare_upper3D(refangles, angles3D, focus[0]));
            }
            if (focus[1] > 0) {
                feedback = feedback.concat(math.compare_lower3D(refangles, angles3D, focus[1]));
            }
            if (focus[2] > 0) {
                feedback = feedback.concat(math.compare_central3D(refangles, angles3D, focus[2]));
            }
            if (feedback.length == 0 && runOrientation != orientation3D) {
                feedback = [[33, 34, 35]]
            }
        }


        if (this.usesHist) {
            this.updateHistory(runAngles);
        }
        //if phase is the same as the previous phase when we have no feedback
        if (feedback.length === 0 && this.phase === phase && this.usesHist) {
            return {feedback: null, skeleton: runSkeleton, flat: run2D};
        }

        //if the above is not the case but we do in fact have no feedback, we must update the last true phase
        if (feedback.length === 0 && this.usesHist) {
            this.phase = phase;
            return {feedback: feedback, skeleton: runSkeleton, flat: run2D};
        }
        return {feedback: feedback, skeleton: runSkeleton, flat: run2D};
    }

    async videoCreate(image) {
        await tf.ready();
        const create = await this.create(image);

        if (create == null) return null;

        let max = 100;
        if (this.vangles.length === max) {
            this.vangles.shift();
        }

        this.vangles.push(create.angles);
        let check = math.checkArrays(this.vangles, max);
        if (check.angles != null) {
            this.vangles = [];
        }

        return {
            percentage: check.percentage,
            angles: check.angles,
            skeleton: create.skeleton
        };
    }

    // sets the phase angles
    setPhaseAngles(phaseAngles) {
        this.hist.updatePhaseAngles(phaseAngles);
    }

    // updates the angles
    updateHistory(angles) {
        this.hist.update(angles);
    }

    // gets the control score
    get controlScore() {
        return this.hist.controlScore;
    }
}


// Stores time values and angles
class History {
    // dAngles: array of the names of dynamic angles
    // points: the number of points used in moving average calculations
    constructor(dAngles, points=5) {
        this.angles = [];
        this.dAngles = dAngles;
        this.points = points;
        this.sigmoid = math.sigmoid(200, 3);

        for (let i = 0; i < dAngles?.length; i++) {
            this.angles.push(new Angle(points));
        }

        // a normalized form rating
        this._form = 0;

        // a normalized control rating based on the acceleration
        this._control = 0;
    }

    get controlScore() {
        return this._control;
    }

    // update the Angles, and update the acceleration score
    update(runAngles) {
        if (runAngles == null) return;
        
        let avgAcceleration = 0;

        for (let i = 0; i < this.dAngles.length; i++) {
            this.angles[i].update(runAngles[this.dAngles[i]]);

            const alpha = this.angles[i].alpha[1];
            const accAvg = alpha.length > this.points ? math.avgAbs(alpha.slice(-this.points)) : 0;
            avgAcceleration += accAvg;
        }

        avgAcceleration /= this.angles.length;
        this._control = math.round(this.sigmoid(avgAcceleration));
    }
}


// Represents a joint-angle, its angular velocity, and its angular acceleration over time
class Angle {
    // points: the number of points used in moving average calculations
    constructor(points) {
        this.t0 = Date.now() / 1000;
        this._t = 0;
        this.times = [];
        this._theta = [];
        this._omega = [];
        this._alpha = [];
        this.history = points;
    }
    
    // time between initialization and last update
    get time() {
        return this._t;
    }

    /**
     * Set the initial time
     * @param {number} t
     */
    set initialTime(t) {
        this.t0 = t;
    }

    get theta() {
        return [this.times, this._theta];
    }

    get omega() {
        return [this.times, this._omega];
    }

    get alpha() {
        return [this.times, this._alpha];
    }

    update(angle) {
        this._t = Date.now() / 1000 - this.t0;
        this.times.push(this._t);
        this._theta.push(angle);

        if (this._theta.length > this.history) {
            const P = this.times.length;
            this._omega.push(math.estDeriv(this.times.slice(P - this.history, P), this._theta.slice(P - this.history, P), 0.5));
            this._alpha.push(math.estDeriv(this.times.slice(P - this.history, P), this._omega.slice(P - this.history, P), 0.5));
        } else {
            this._omega.push(0);
            this._alpha.push(0);
        }
    }
} 
