import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExerciseBookmarksByCreator, bookmark, deleteBookmarksByExerciseId } from '../../actions/user';
import { likeExercise, deleteExercise } from '../../actions/exercise';
import { useNavigate } from 'react-router-dom';
import GenericCard from '../Content/Card';
import { removeCompetition } from '../../actions/program';

const ExerciseCard = ({ item, isMobile, programData=null }) => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem('profile'))?.result?._id;
  const navigate = useNavigate();
  const bookmarks = useSelector((state) => state.user.exerciseBookmarks);
  const isBookmarked = bookmarks?.includes(item._id);

  const handleLike = (id) => {
    dispatch(likeExercise(id));
  };

  const handleRemove = () => {
    if (programData.exerciseId != null) {
      dispatch(removeCompetition(programData.programId, programData.exerciseId));
    }
  }

  const handleBookmark = async (id) => {
    if (isBookmarked) {
      await dispatch(deleteBookmarksByExerciseId(id));
    } else {
      await dispatch(bookmark({ exerciseId: id }));
    }
    dispatch(getExerciseBookmarksByCreator()); // Ensure state is up-to-date
  };

  const handleDelete = (id) => {
    dispatch(deleteBookmarksByExerciseId(id));
    dispatch(deleteExercise(id));
  };

  const handleEdit = (id) => {
    navigate(`/exercise/${id}`);
  };

  return (
    <GenericCard
      item={{ ...item, type: 'exercise' }}
      onLike={handleLike}
      onRemove={handleRemove}
      onBookmark={handleBookmark}
      onDelete={handleDelete}
      onEdit={handleEdit}
      isBookmarked={isBookmarked}
      isLiked={item?.likes?.includes(userId)}
      isMobile={isMobile}
      programData={programData}
    />
  );
};

export default ExerciseCard;
