import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Box } from '@mui/material';
import { createLog } from '../../actions/log';
import { getWorkoutById } from '../../actions/workout';
import { getExercisesFromArray } from '../../actions/exercise';
import { incrementStats } from '../../actions/stats';
import RunnerHelper from '../Analyzer/RunnerHelper';

const WorkoutRunner = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const workout = useSelector((state) => state.workout.workout);
    const exercises = useSelector((state) => state.exercise.exercisesFromArray);

    const [index, setIndex] = useState(0);

    const REST_TIME = 20; // 20 seconds
    const [resting, setResting] = useState(false);
    const [restTime, setRestTime] = useState(REST_TIME); 

    const videoIds = useRef([]);

    useEffect(() => {
        dispatch(getWorkoutById(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (workout) {
            const ids = workout.exercises.map((w) => w._id);
            dispatch(getExercisesFromArray(ids));
        }
    }, [dispatch, workout]);

    useEffect(() => {
        let timer;
        if (resting && restTime > 0) {
            timer = setInterval(() => {
                setRestTime((prevTime) => prevTime - 1);
            }, 1000);
        } else if (resting && restTime === 0) {
            setResting(false);
            setRestTime(REST_TIME); // Reset rest time for the next rest period
        }
        return () => clearInterval(timer);
    }, [resting, restTime]);

    if (exercises.length !== 0 && exercises.length > index && workout && !resting) {
        return (
            <RunnerHelper 
                input={exercises[index]} 
                workoutTitle={workout.title} 
                workoutCount={workout.reps[index]} 
                updateIndex={(videoId) => {
                    setIndex(index + 1);
                    setResting(true);
                    videoIds.current.push(videoId);
                }}
            />
        );
    } else if (resting && exercises.length > index) {
        return (
            <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                height="100vh"
            >
                <Typography variant="h4" gutterBottom color="textPrimary">
                    Rest
                </Typography>
                <Box position="relative" display="inline-flex">
                    <CircularProgress 
                        variant="determinate" 
                        value={(restTime / REST_TIME) * 100}
                        size={100}
                        color="primary"
                    />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="h6" component="div" color="textPrimary">
                            {restTime}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    } else if (exercises.length !== 0 && workout) {
        const titles = exercises.map(a => a.title);
        const exerciseIds = exercises.map(a => a._id); 

        if (!user) {
            navigate('/home');
        } else {
            dispatch(createLog({
                creator: user.result._id, 
                reps: workout.reps, 
                exerciseNames: titles, 
                exerciseIds: exerciseIds, 
                workoutName: workout.title, 
                workoutId: workout._id,
                videos: videoIds.current 
            }));

            for (let i = 0; i < exercises.length; i++) {
                dispatch(incrementStats(exercises[i].muscleGroups));
            }

            navigate('/logs');
        }
    }
    
    return <Typography variant="h6" color="textPrimary">Logging your workout...</Typography>;
};

export default WorkoutRunner;