import {
  GET_HOMEPAGE_VIDEOS,
  FETCH_TRENDING_VIDEOS,
  FETCH_VIDEOS_BY_CREATOR,
  SEARCH_VIDEOS,
  GET_VIDEO,
  CREATE_VIDEO,
  UPDATE_VIDEO,
  DELETE_VIDEO,
  LIKE_VIDEO,
  INCREMENT_VIEWS,
  VIDEO_ERROR,
  CLEAR_VIDEO_SEARCH_RESULTS,
} from '../constants/actionTypes';
import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

const initialState = {
  homepageVideos: [],
  trendingVideos: { data: [], currentPage: 1, totalPages: 1 },
  videosByCreator: { data: [], currentPage: 1, totalPages: 1 },
  searchResults: { data: [], currentPage: 1, totalPages: 1 },
  video: null,
  error: null,
};

const updateLikesField = (videos, payload) => {
  return videos.map(video =>
    video._id === payload._id ? { ...video, likes: payload.likes } : video
  );
};

const updateViewsField = (videos, payload) => {
  return videos.map(video =>
    video._id === payload._id ? { ...video, views: payload.views } : video
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_VIDEOS:
      return { ...state, homepageVideos: action.payload.data, error: null };
    case FETCH_TRENDING_VIDEOS:
      return {
        ...state,
        trendingVideos: {
          data: uniqBy([...state.trendingVideos.data, ...action.payload.data], '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case FETCH_VIDEOS_BY_CREATOR:
      return {
        ...state,
        videosByCreator: {
          data: uniqBy(action.payload.data, '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case SEARCH_VIDEOS:
      return {
        ...state,
        searchResults: {
          data: uniqBy([...state.searchResults.data, ...action.payload.data], '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case CLEAR_VIDEO_SEARCH_RESULTS:
      return { ...state, searchResults: { data: [], currentPage: 1, totalPages: 1 }, error: null };
    case GET_VIDEO:
      return {
        ...state,
        video: action.payload,
        error: null,
      };
    case CREATE_VIDEO:
      return {
        ...state,
        trendingVideos: {
          ...state.trendingVideos,
          data: uniqBy([action.payload, ...state.trendingVideos.data], '_id'),
        },
        videosByCreator: {
          ...state.videosByCreator,
          data: uniqBy([action.payload, ...state.videosByCreator.data], '_id'),
        },
        searchResults: {
          ...state.searchResults,
          data: uniqBy([action.payload, ...state.searchResults.data], '_id'),
        },
        error: null,
      };
    case UPDATE_VIDEO:
      return {
        ...state,
        trendingVideos: {
          ...state.trendingVideos,
          data: state.trendingVideos.data.map(video =>
            video._id === action.payload._id ? action.payload : video
          ),
        },
        videosByCreator: {
          ...state.videosByCreator,
          data: state.videosByCreator.data.map(video =>
            video._id === action.payload._id ? action.payload : video
          ),
        },
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map(video =>
            video._id === action.payload._id ? action.payload : video
          ),
        },
        homepageVideos: state.homepageVideos.map(video =>
          video._id === action.payload._id ? action.payload : video
        ),
        error: null,
      };
    case DELETE_VIDEO:
      return {
        ...state,
        trendingVideos: {
          ...state.trendingVideos,
          data: state.trendingVideos.data.filter(video => video._id !== action.payload),
        },
        videosByCreator: {
          ...state.videosByCreator,
          data: state.videosByCreator.data.filter(video => video._id !== action.payload),
        },
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.filter(video => video._id !== action.payload),
        },
        homepageVideos: state.homepageVideos.filter(video => video._id !== action.payload),
        error: null,
      };
    case LIKE_VIDEO:
      return {
        ...state,
        trendingVideos: {
          ...state.trendingVideos,
          data: updateLikesField(state.trendingVideos.data, action.payload),
        },
        videosByCreator: {
          ...state.videosByCreator,
          data: updateLikesField(state.videosByCreator.data, action.payload),
        },
        searchResults: {
          ...state.searchResults,
          data: updateLikesField(state.searchResults.data, action.payload),
        },
        homepageVideos: updateLikesField(state.homepageVideos, action.payload),
        error: null,
      };
    case INCREMENT_VIEWS:
      return {
        ...state,
        trendingVideos: {
          ...state.trendingVideos,
          data: updateViewsField(state.trendingVideos.data, action.payload),
        },
        videosByCreator: {
          ...state.videosByCreator,
          data: updateViewsField(state.videosByCreator.data, action.payload),
        },
        searchResults: {
          ...state.searchResults,
          data: updateViewsField(state.searchResults.data, action.payload),
        },
        homepageVideos: updateViewsField(state.homepageVideos, action.payload),
        error: null,
      };
    case VIDEO_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

// Selectors
export const selectTrendingVideos = createSelector(
  state => state.video.trendingVideos,
  trendingVideos => ({ ...trendingVideos })
);

export const selectSearchResults = createSelector(
  state => state.video.searchResults,
  searchResults => ({ ...searchResults })
);
