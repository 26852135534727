import React, { useRef, useState, useEffect } from 'react';
import { Button, Box, Tooltip } from '@mui/material';

const ThumbnailSelector = ({ videoUrl, onUpdate }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        setVideoDimensions({
          width: video.videoWidth,
          height: video.videoHeight,
        });
        // Capture default thumbnail without triggering onUpdate
        captureFrame(false);
      });
    }
  }, [videoUrl]);

  const captureFrame = (triggerUpdate = true) => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    try {
      const dataURL = canvas.toDataURL('image/jpeg');
      setThumbnail(dataURL);
      // Only trigger onUpdate if this was a user-initiated capture
      if (triggerUpdate) {
        onUpdate(dataURL);
      }
    } catch (error) {
      console.error('Error capturing frame:', error);
    }
  };

  return (
    <Box sx={{ p: 2, position: 'relative', display: 'inline-block' }}>
      <video
        ref={videoRef}
        src={videoUrl}
        controls
        width="100%"
        height="auto"
        crossOrigin="anonymous"
      />
      <Tooltip title="Click to capture the current frame as a thumbnail">
        <Button
          variant="contained"
          color="primary"
          onClick={() => captureFrame(true)}
          sx={{
            position: 'absolute',
            bottom: 5,
            right: 8,
            padding: '4px 8px',
            minWidth: 'auto',
            fontSize: '12px',
          }}
        >
          Capture Thumbnail
        </Button>
      </Tooltip>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {thumbnail && (
        <img
          src={thumbnail}
          alt="Thumbnail"
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
            width: '100px',
            height: 'auto',
            border: '2px solid white',
            zIndex: 10,
          }}
        />
      )}
    </Box>
  );
};

export default ThumbnailSelector;
