import React from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';

const VideoPlayer = ({ url, playing, width, height }) => {
  return (
    <Box sx={{ width, height, marginBottom: 2 }}>
      <ReactPlayer url={url} playing={playing} width="100%" height="100%" controls />
    </Box>
  );
};

export default VideoPlayer;