import React from 'react';
import RunnerHelper from '../Analyzer/RunnerHelper';
import { useSelector } from 'react-redux';

const ExerciseRunner = () => {
    // Select exercise data from the Redux store
    const exerciseDataFromRedux = useSelector((state) => state.exercise.exercise);

    // If exercise data exists in Redux state, save it to local storage
    // This ensures data is persisted across page refreshes
    if (exerciseDataFromRedux !== undefined) {
        localStorage.setItem("exerciseData", JSON.stringify(exerciseDataFromRedux));
    }
    
    // Retrieve exercise data from local storage
    const exerciseDataFromLocalStorage = JSON.parse(localStorage.getItem("exerciseData"));

    // If exercise data exists in local storage, render the RunnerHelper component
    if (exerciseDataFromLocalStorage !== undefined) {
        return <RunnerHelper input={exerciseDataFromLocalStorage} workoutTitle="" />;
    }

    // If no exercise data is found, display an error message
    return <p>Error: No exercise data available</p>;
};

export default ExerciseRunner;