import React from 'react';
import { Box, TextField, IconButton, Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

const Set = ({ updateOrder, num, sendIds, sendReps, id, reps, bookmarks = [] }) => {
  const theme = useTheme();

  const handleRemove = () => {
    updateOrder(num);
  };

  const phaseStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    margin: '15px',
    padding: '10px',
    backgroundColor: theme.palette.background.main,
    border: '1px solid',
    width: '100%',
    maxWidth: '420px',
  };

  const fieldStyle = {
    margin: '7px',
    paddingRight: '20px',
    width: { xs: '100%', sm: '300px' },
  };

  const buttonStyle = {
    margin: '5px',
    textAlign: 'right',
  };

  const autocompleteOptions = bookmarks.map(bookmark => ({
    label: bookmark.title,
    exerciseId: bookmark._id,
  }));

  const findBookmark = (exerciseIdWithTitle) => {
    if (!exerciseIdWithTitle) return null;
    else return autocompleteOptions.find(option => option.exerciseId === exerciseIdWithTitle[0]) || null;
  };

  return (
    <Box style={phaseStyle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Autocomplete
          disablePortal
          options={autocompleteOptions}
          getOptionLabel={(option) => option.label || ''}
          value={findBookmark(id)}
          onChange={(event, newValue) => {
            if (newValue) {
              sendIds([newValue.exerciseId, newValue.label], num);
            } else {
              sendIds([null, ''], num); // Handle case where value is cleared
            }
          }}
          renderInput={(params) => <TextField {...params} label="Exercise Name" />}
          isOptionEqualToValue={(option, value) => option.exerciseId === value.exerciseId}
          sx={fieldStyle}
        />
        <TextField
          type="number"
          id="number"
          label="Reps or Duration"
          variant="outlined"
          size="small"
          value={reps}
          sx={fieldStyle}
          onChange={(e) => sendReps(e.target.value, num)}
          inputProps={{ min: 1 }}
        />
      </Box>
      <IconButton sx={buttonStyle} aria-label="delete" onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default Set;
