import * as api from '../api';
import { FETCH_LOGS, TWO_MONTHS, CREATE_LOG, DELETE_LOG, UPDATE_LOG_VISIBILITY, FETCH_LOGS_BY_MONTH_AND_WORKOUT, UPLOAD_LOG_TO_PROGRAM, REMOVE_LOG_FROM_PROGRAM, FETCH_LOGS_BY_PROGRAM , GET_COMPETITION_RESULTS} from '../constants/actionTypes';

export const createLog = (log) => async (dispatch) => {
  try {
    const { data } = await api.createLog(log);
    dispatch({ type: CREATE_LOG, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const deleteLog = (id) => async (dispatch) => {
  try {
    await api.deleteLog(id);
    dispatch({ type: DELETE_LOG, payload: id });
  } catch (error) {
    console.error(error);
  }
};

export const updateLogVisibility = (id, visibility) => async (dispatch) => {
  try {
    const { data } = await api.updateLogVisibility(id, { visibility });
    dispatch({ type: UPDATE_LOG_VISIBILITY, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getLogsByCreator = (viewSelf, month, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getLogsByCreator(viewSelf, month, page, limit);
    dispatch({ type: FETCH_LOGS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getTodayLogsByWorkoutId = (workoutId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getTodayLogsByWorkoutId(workoutId, page, limit);
    dispatch({ type: FETCH_LOGS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getMonthLogsByWorkoutId = (workoutId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getMonthLogsByWorkoutId(workoutId, page, limit);
    dispatch({ type: FETCH_LOGS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getMonthLogsByCreator = (creatorId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getMonthLogsByCreator(creatorId, page, limit);
    dispatch({ type: FETCH_LOGS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getMonthLogsByCreatorForUser = (creatorId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getMonthLogsByCreatorForUser(creatorId, page, limit);
    dispatch({ type: FETCH_LOGS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getLogsByExercise = (exerciseId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getLogsByExercise(exerciseId, page, limit);
    dispatch({ type: FETCH_LOGS, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getTwoMonthLogsByCreator = (creatorId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getTwoMonthLogsByCreator(creatorId, page, limit);
    dispatch({ type: TWO_MONTHS, payload: data });
  } catch (error) {
    console.error(error);
  }
};


export const getLogsByMonthAndWorkouts = (month, programId) => async (dispatch) => {
  try {
    const { data } = await api.getLogsByMonthAndWorkouts(month, programId);
    dispatch({ type: FETCH_LOGS_BY_MONTH_AND_WORKOUT, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const uploadLogToProgram = (logId, programId) => async (dispatch) => {
  try {
    const { data } = await api.uploadLogToProgram(logId, programId);
    dispatch({ type: UPLOAD_LOG_TO_PROGRAM, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const removeLogFromProgram = (logId, programId) => async (dispatch) => {
  try {
    const { data } = await api.removeLogFromProgram(logId, programId);
    dispatch({ type: REMOVE_LOG_FROM_PROGRAM, payload: data });
  } catch (error) {
    console.error(error);
  }
};

export const getLogsByProgram = (month, programId, memberId) => async (dispatch) => {
  try {
    const { data } = await api.getLogsByProgram(month, programId, memberId);
    dispatch({ type: FETCH_LOGS_BY_PROGRAM, payload: data });
  } catch (error) {
    console.error(error);
  }
};


export const getCompetitionResults = (programId) => async (dispatch) => {
  try {
    const { data } = await api.getCompetitionResults(programId);
    dispatch({ type: GET_COMPETITION_RESULTS, payload: data });
  } catch (error) {
    console.error(error);
  }
};