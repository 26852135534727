import React from 'react';
import { getHomepageExercises, getHomepageExercisesForGuest } from '../../actions/exercise';
import { getExerciseBookmarksByCreator } from '../../actions/user';
import ExerciseCard from './ExerciseCard';
import GenericHomeContent from '../Content/HomeContent';

const HomeExercises = () => {
  const user = JSON.parse(localStorage.getItem('profile'));

  const fetchDataAction = user ? getHomepageExercises : getHomepageExercisesForGuest;

  const reduxStateSelector = (state) => state.exercise.homepageExercises;

  return (
    <GenericHomeContent
      title="Exercises"
      fetchDataAction={fetchDataAction}
      fetchGuestDataAction={getHomepageExercisesForGuest}
      bookmarksAction={getExerciseBookmarksByCreator}
      CardComponent={ExerciseCard}
      navigatePath="/exercises"
      reduxStateSelector={reduxStateSelector}
      containerTitle="Exercise"
    />
  );
};

export default HomeExercises;
