export const appendFormData = (formData, data, parentKey = '') => {
  if (Array.isArray(data)) {
    // Handle an array of files or non-files
    data.forEach((item, index) => {
      appendFormData(formData, item, `${parentKey}[${index}]`);
    });
  } else if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    // Handle nested objects
    Object.keys(data).forEach(key => {
      appendFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key); // Using square brackets for nested keys
    });
  } else if (data !== undefined && data !== null) {
    // Handle regular data (primitives, non-null values)
    formData.append(parentKey, data);
  }
};
