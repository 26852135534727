import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Box, Typography, TextField, useTheme, Divider, IconButton, CircularProgress, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';

const ContentPage = ({
  title,
  searchPlaceholder,
  fetchTrendingAction,
  searchAction,
  clearSearchResultsAction,
  fetchBookmarksAction,
  CardComponent,
  trendingItemsSelector,
  searchResultsSelector,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem('profile'));
  const trendingItems = useSelector(trendingItemsSelector);
  const searchResults = useSelector(searchResultsSelector);
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const loadItems = useCallback(() => {
    if (query.trim()) {
      dispatch(searchAction(query, page));
    } else {
      dispatch(fetchTrendingAction(page, 16));
    }
  }, [dispatch, query, page, searchAction, fetchTrendingAction]);

  useEffect(() => {
    dispatch(clearSearchResultsAction());
    setItems([]);
    setPage(1);
    setHasMore(true);
    loadItems();

    if (user && fetchBookmarksAction) dispatch(fetchBookmarksAction());
  }, [loadItems, dispatch, fetchBookmarksAction]);

  useEffect(() => {
    let combinedItems = [];
    if (query.trim()) {
      combinedItems = searchResults.data;
      setHasMore(searchResults.currentPage < searchResults.totalPages);
    } else {
      combinedItems = trendingItems.data;
      setHasMore(trendingItems.currentPage < trendingItems.totalPages);
    }
    combinedItems = uniqBy(combinedItems, '_id');

    setItems(combinedItems);
  }, [trendingItems, searchResults, page]);

  const handleSearch = () => {
    setPage(1);
    setItems([]);
    setHasMore(true);
    loadItems();
  };

  const handleInputChange = e => {
    setQuery(e.target.value);
  };

  const handleBack = () => {
    navigate('/home');
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const classes = {
    title: {
      color: theme.palette.primary.main,
    },
    searchBar: {
      maxWidth: '600px',
      marginLeft: 'auto',
    },
    headerBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '7px',
      border: '1px solid gray',
      height: '300px',
      width: '250px',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      textAlign: 'center',
    },
    body: {
      color: theme.palette.text.secondary,
    },
    button: {
      marginTop: theme.spacing(2),
    },
  };

  return (
    <Container maxWidth="lg" style={{ overflow: 'hidden' }}>
      <Box sx={classes.headerBox}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={classes.title} variant="h4">
            <Divider>{title}</Divider>
          </Typography>
        </Box>
        <TextField
          sx={classes.searchBar}
          variant="outlined"
          fullWidth
          placeholder={searchPlaceholder}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <InfiniteScroll
        dataLength={items.length}
        next={() => setPage(prevPage => prevPage + 1)}
        hasMore={hasMore}
        loader={hasMore && <Box display="flex" justifyContent="center" mt={2}><CircularProgress /></Box>}
        scrollableTarget="contentContainerRef"
        style={{ overflow: 'hidden' }}
      >
        <Grid container spacing={3} justifyContent="center">
          {items.map(item => (
            <Grid key={item._id} item>
              <CardComponent item={item} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
};

ContentPage.propTypes = {
  title: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  fetchTrendingAction: PropTypes.func.isRequired,
  searchAction: PropTypes.func.isRequired,
  clearSearchResultsAction: PropTypes.func.isRequired,
  fetchBookmarksAction: PropTypes.func,
  CardComponent: PropTypes.elementType.isRequired,
  trendingItemsSelector: PropTypes.func.isRequired,
  searchResultsSelector: PropTypes.func.isRequired,
};

export default ContentPage;