import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import { AppBar, styled, Toolbar, Button, Box, IconButton, Container } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LoginIcon from '@mui/icons-material/Login';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import gainsLogo from '../../images/gains_coach.png';
import gWhite from '../../images/g_white.png';
import * as actionType from '../../constants/actionTypes';
import CreateMenu from './CreateMenu';
import AccountMenu from './AccountMenu';


const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    history('/auth');
    window.location.reload();
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    margin: '0px',
    display: 'flex',
  }));

  const StyledLink = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    position: 'relative',
    margin: '0px 10px',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '0',
      height: '2px',
      bottom: '-3px',
      left: '50%',
      transform: 'translate(-50%,0%)',
      backgroundColor: theme.palette.text.primary,
      visibility: 'hidden',
      transition: 'all 0.4s ease-in-out',
    },
    '&:hover:before': {
      visibility: 'visible',
      width: '100%',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }));

  const largeScreenStyle = {
    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
  };

  const mediumScreenStyle = {
    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'none', xl: 'none' },
  };

  const smallScreenStyle = {
    display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' },
    margin: '0px 5px',
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'navbar.main' }}>
      <AppBar position='static' sx={{bgcolor: 'transparent'}} >
        <Container disableGutters>
          <StyledToolbar>
        
            <Box edge='start' sx={{ flexGrow: 1 }}>
              <Link to="/" >
                <Box component="img" src={gainsLogo} alt="logo" height="40px" sx={largeScreenStyle}/>
                <Box component="img" src={gainsLogo} alt="logo" height="40px" sx={mediumScreenStyle}/>
                <Box component="img" src={gWhite} alt="icon" height="35px" sx={smallScreenStyle}/>
              </Link>
            </Box>

            {user?.result && (
              <>
                <StyledLink sx={largeScreenStyle} component={Link} to="/bookmarks" startIcon={<BookmarksIcon/>}>Bookmarks</StyledLink>
                <StyledLink sx={largeScreenStyle} component={Link} to="/memberships" startIcon={<CollectionsBookmarkIcon/>}>Programs</StyledLink>
                <StyledLink sx={largeScreenStyle} component={Link} to="/logs" startIcon={<EventNoteOutlinedIcon/>}>Logs</StyledLink>
                <StyledLink sx={largeScreenStyle} component={Link} to="/social" startIcon={<GroupOutlinedIcon/>}>Social</StyledLink>

                <StyledLink sx={mediumScreenStyle} component={Link} to="/bookmarks">Bookmarks</StyledLink>
                <StyledLink sx={mediumScreenStyle} component={Link} to="/memberships">Programs</StyledLink>
                <StyledLink sx={mediumScreenStyle} component={Link} to="/logs">Logs</StyledLink>
                <StyledLink sx={mediumScreenStyle} component={Link} to="/social">Social</StyledLink>

                <IconButton sx={smallScreenStyle} component={Link} to="/bookmarks">
                  <BookmarksIcon/>
                </IconButton>
                <IconButton sx={smallScreenStyle} component={Link} to="/memberships">
                  <CollectionsBookmarkIcon/>
                </IconButton>
                <IconButton sx={smallScreenStyle} component={Link} to="/logs">
                  <EventNoteOutlinedIcon/>
                </IconButton>
                <IconButton sx={smallScreenStyle} component={Link} to="/social">
                  <GroupOutlinedIcon/>
                </IconButton>
                  
                <CreateMenu/>
              </>
            )}

            <Toolbar disableGutters>
              {user?.result ? (
                <AccountMenu username={user?.result.username || user?.result.email} picture={user?.result.profilePictureUrl} logout={logout} />
              ) : (
                <Button sx={{ ml: 3 }} component={Link} to="/auth" variant="contained" startIcon={<LoginIcon/>}>Login</Button>
              )}
            </Toolbar>
          </StyledToolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Navbar;
