import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { likeVideo, deleteVideo, updateVideo } from '../../actions/video';
import GenericCard from '../Content/Card';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import ThumbnailSelector from '../Video/ThumbnailSelector';

const dataURLToFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const VideoCard = ({ item, isMobile }) => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem('profile'))?.result?._id;
  
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [title, setTitle] = useState(item.title);
  const [description, setDescription] = useState(item.description);
  const [visibility, setVisibility] = useState(item.visibility);
  const [thumbnail, setThumbnail] = useState(item.thumbnail);
  const [isThumbnailUpdated, setIsThumbnailUpdated] = useState(false);

  const handleThumbnailUpdate = (newThumbnail) => {
    setThumbnail(newThumbnail);
    setIsThumbnailUpdated(true);
  };

  const handleLike = (id) => {
    dispatch(likeVideo(id));
  };

  const handleDelete = (id) => {
    dispatch(deleteVideo(id));
  };

  const handleEdit = (id) => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('visibility', visibility);
  
    // Only append the thumbnail if it was updated
    if (isThumbnailUpdated) {
      const thumbnailFile = dataURLToFile(thumbnail, 'video_thumbnail.jpg');
      formData.append('thumbnail', thumbnailFile);
    }
  
    dispatch(updateVideo(item._id, formData));
    setOpenEditDialog(false);
  };

  return (
    <>
      <GenericCard
        item={{ ...item, type: 'video' }}
        onLike={handleLike}
        onBookmark={null}
        onDelete={handleDelete}
        onEdit={handleEdit}
        isBookmarked={null}
        isLiked={item?.likes?.includes(userId)}
        isMobile={isMobile}
        programData={null}
      />

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
        <DialogTitle>Edit Video</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            minRows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Visibility</InputLabel>
            <Select
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              label="Visibility"
            >
              <MenuItem value="everyone">Everyone</MenuItem>
              <MenuItem value="followers">Followers</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
          <ThumbnailSelector videoUrl={item.videoUrl} onUpdate={handleThumbnailUpdate} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoCard;
