import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container, Box, Collapse, Alert, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { changePassword, checkToken } from '../../actions/user';
import Input from './Input';

const RecoverEmail = () => {
  const [searchParams] = useSearchParams();
  const recoverToken = searchParams.get("recoverToken");
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [strength, setStrength] = useState(0);
  const [formError, setFormError] = useState('');
  const tokenValid = useSelector((state) => state.user.tokenValid);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      setFormError("Passwords do not match.");
    } else if (strength <= 2) {
      setFormError("Password is too weak, must be considered at least Good");
    } else {
      dispatch(changePassword({ recoverToken, password }));
      navigate(`/auth`);
    }
  };

  const evalStrength = (strength) => {
    switch (strength) {
      case 0:
        return "Very Weak";
      case 1:
        return "Weak";
      case 2:
        return "Moderate";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (recoverToken) {
      dispatch(checkToken({ recoverToken }));
    }
  }, [dispatch, recoverToken]);

  useEffect(() => {
    if (!tokenValid) {
      setFormError("Invalid Password Change Session");
    }
  }, [tokenValid]);

  const handleChange = (e) => {
    const str = e.target.value;
    let sum = 0;
    if (str.length > 7) sum++;
    if (/[A-Z]/.test(str)) sum++;
    if (/[a-z]/.test(str)) sum++;
    if (/[0-9]/.test(str)) sum++;
    setStrength(sum);
    setPassword(str);
  };

  const handleRepeat = (e) => setRepeatPassword(e.target.value);

  const classes = {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper style={classes.paper} elevation={6}>
        <Avatar style={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Recover Account</Typography>
        <form style={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Typography component="h5" variant="h6">Password Strength: {evalStrength(strength)}</Typography>
            <Input disabled={!tokenValid} name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            <Input disabled={!tokenValid} name="confirmPassword" label="Repeat Password" handleChange={handleRepeat} type="password" />
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" style={classes.submit}>
            Change Password
          </Button>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography component="h5" variant="h6">Do at least 3 of the 4:</Typography>
            <Typography component="h5" variant="body1">7 or more characters</Typography>
            <Typography component="h5" variant="body1">At least one lowercase character</Typography>
            <Typography component="h5" variant="body1">At least one uppercase character</Typography>
            <Typography component="h5" variant="body1">At least one digit</Typography>
          </Box>
        </form>
      </Paper>

      <Box sx={{ width: '100%', mt: '10px' }}>
        <Collapse in={formError !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setFormError("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {formError}
          </Alert>
        </Collapse>
      </Box>
    </Container>
  );
};

export default RecoverEmail;