import * as api from '../api';
import {
  FETCH_COMMENTS_BY_EXERCISE,
  FETCH_COMMENTS_BY_WORKOUT,
  FETCH_COMMENTS_BY_VIDEO,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  FETCH_COMMENT_BY_ID,
} from '../constants/actionTypes';

// Fetch comments by exercise with pagination
export const getCommentsByExercise = (exerciseId, page = 1, limit = 10) => async (dispatch) => {
  try {
    const { data } = await api.getCommentsByExercise(exerciseId, page, limit);
    dispatch({ type: FETCH_COMMENTS_BY_EXERCISE, payload: { comments: data.comments, totalComments: data.totalComments } });
  } catch (error) {
    console.error('Error fetching comments by exercise:', error);
  }
};

// Fetch comments by workout with pagination
export const getCommentsByWorkout = (workoutId, page = 1, limit = 10) => async (dispatch) => {
  try {
    const { data } = await api.getCommentsByWorkout(workoutId, page, limit);
    dispatch({ type: FETCH_COMMENTS_BY_WORKOUT, payload: { comments: data.comments, totalComments: data.totalComments } });
  } catch (error) {
    console.error('Error fetching comments by workout:', error);
  }
};

// Fetch comments by video with pagination
export const getCommentsByVideo = (videoId, page = 1, limit = 10) => async (dispatch) => {
  try {
    const { data } = await api.getCommentsByVideo(videoId, page, limit);
    dispatch({ type: FETCH_COMMENTS_BY_VIDEO, payload: { comments: data.comments, totalComments: data.totalComments } });
  } catch (error) {
    console.error('Error fetching comments by video:', error);
  }
};

// Create a new comment
export const createComment = (commentData) => async (dispatch) => {
  try {
    const { data } = await api.createComment(commentData);
    dispatch({ type: CREATE_COMMENT, payload: data });
  } catch (error) {
    console.error('Error creating comment:', error);
  }
};

// Update a comment
export const updateComment = (id, commentData) => async (dispatch) => {
  try {
    const { data } = await api.updateComment(id, commentData);
    dispatch({ type: UPDATE_COMMENT, payload: data });
  } catch (error) {
    console.error('Error updating comment:', error);
  }
};

// Delete a comment
export const deleteComment = (id) => async (dispatch) => {
  try {
    await api.deleteComment(id);
    dispatch({ type: DELETE_COMMENT, payload: id });
  } catch (error) {
    console.error('Error deleting comment:', error);
  }
};

// Fetch comment by ID
export const getCommentById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getCommentById(id);
    dispatch({ type: FETCH_COMMENT_BY_ID, payload: data });
  } catch (error) {
    console.error('Error fetching comment by ID:', error);
  }
};
