import {
  GET_HOMEPAGE_WORKOUTS,
  GET_TRENDING_WORKOUTS,
  GET_WORKOUTS_BY_CREATOR,
  SEARCH_WORKOUTS,
  GET_WORKOUTS_FROM_ARRAY,
  GET_WORKOUT_BY_ID,
  GET_SUGGESTED_WORKOUTS,
  CREATE_WORKOUT,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  LIKE_WORKOUT,
  INCREMENT_WORKOUT_RUNS,
  WORKOUT_ERROR,
  CLEAR_WORKOUT,
  CLEAR_WORKOUT_SEARCH_RESULTS,
} from '../constants/actionTypes';
import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

const initialState = {
  homepageWorkouts: [],
  trendingWorkouts: { data: [], currentPage: 1, totalPages: 1 },
  workoutsByCreator: { data: [], currentPage: 1, totalPages: 1 },
  searchResults: { data: [], currentPage: 1, totalPages: 1 },
  workoutsFromArray: { data: [], currentPage: 1, totalPages: 1 },
  workout: null,
  suggestedWorkouts: { data: [], currentPage: 1, totalPages: 1 },
  error: null,
};

const updateLikesField = (workouts, payload) => {
  return workouts.map(workout =>
    workout._id === payload._id ? { ...workout, likes: payload.likes } : workout
  );
};

const updateRunsField = (workouts, payload) => {
  return workouts.map(workout =>
    workout._id === payload._id ? { ...workout, runs: payload.runs } : workout
  );
};

const workoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_WORKOUTS:
      return { ...state, homepageWorkouts: action.payload.data, error: null };
    case GET_TRENDING_WORKOUTS:
      return { 
        ...state, 
        trendingWorkouts: { 
          data: uniqBy([...state.trendingWorkouts.data, ...action.payload.data], '_id'), 
          currentPage: action.payload.currentPage, 
          totalPages: action.payload.totalPages 
        }, 
        error: null 
      };
    case GET_WORKOUTS_BY_CREATOR:
      return { 
        ...state, 
        workoutsByCreator: { 
          data: uniqBy(action.payload.data, '_id'), 
          currentPage: action.payload.currentPage, 
          totalPages: action.payload.totalPages 
        }, 
        error: null 
      };
    case SEARCH_WORKOUTS:
      return { 
        ...state, 
        searchResults: { 
          data: uniqBy([...state.searchResults.data, ...action.payload.data], '_id'), 
          currentPage: action.payload.currentPage, 
          totalPages: action.payload.totalPages 
        }, 
        error: null 
      };
    case CLEAR_WORKOUT_SEARCH_RESULTS:
      return { 
        ...state, 
        searchResults: { data: [], currentPage: 1, totalPages: 1 },
        error: null 
      };
    case GET_WORKOUTS_FROM_ARRAY:
      return { 
        ...state, 
        workoutsFromArray: { 
          data: uniqBy([...state.workoutsFromArray.data, ...action.payload.data], '_id'), 
          currentPage: action.payload.currentPage, 
          totalPages: action.payload.totalPages 
        }, 
        error: null 
      };
    case GET_WORKOUT_BY_ID:
      return { 
        ...state, 
        workout: action.payload, 
        error: null 
      };
    case CLEAR_WORKOUT:
      return { ...state, workout: null };
    case GET_SUGGESTED_WORKOUTS:
      return { 
        ...state, 
        suggestedWorkouts: { 
          data: uniqBy([...state.suggestedWorkouts.data, ...action.payload.data], '_id'), 
          currentPage: action.payload.currentPage, 
          totalPages: action.payload.totalPages 
        }, 
        error: null 
      };
    case CREATE_WORKOUT:
      return {
        ...state,
        trendingWorkouts: { 
          ...state.trendingWorkouts, 
          data: uniqBy([action.payload, ...state.trendingWorkouts.data], '_id') 
        },
        workoutsByCreator: { 
          ...state.workoutsByCreator, 
          data: uniqBy([action.payload, ...state.workoutsByCreator.data], '_id') 
        },
        searchResults: { 
          ...state.searchResults, 
          data: uniqBy([action.payload, ...state.searchResults.data], '_id') 
        },
        suggestedWorkouts: { 
          ...state.suggestedWorkouts, 
          data: uniqBy([action.payload, ...state.suggestedWorkouts.data], '_id') 
        },
        error: null
      };
    case UPDATE_WORKOUT:
      return {
        ...state,
        trendingWorkouts: { 
          ...state.trendingWorkouts, 
          data: state.trendingWorkouts.data.map(workout =>
            workout._id === action.payload._id ? action.payload : workout
          )
        },
        workoutsByCreator: { 
          ...state.workoutsByCreator, 
          data: state.workoutsByCreator.data.map(workout =>
            workout._id === action.payload._id ? action.payload : workout
          )
        },
        searchResults: { 
          ...state.searchResults, 
          data: state.searchResults.data.map(workout =>
            workout._id === action.payload._id ? action.payload : workout
          )
        },
        suggestedWorkouts: { 
          ...state.suggestedWorkouts, 
          data: state.suggestedWorkouts.data.map(workout =>
            workout._id === action.payload._id ? action.payload : workout
          )
        },
        error: null
      };
    case DELETE_WORKOUT:
      return {
        ...state,
        homepageWorkouts: state.homepageWorkouts.filter(workout => workout._id !== action.payload),
        trendingWorkouts: { 
          ...state.trendingWorkouts, 
          data: state.trendingWorkouts.data.filter(workout => workout._id !== action.payload)
        },
        workoutsByCreator: { 
          ...state.workoutsByCreator, 
          data: state.workoutsByCreator.data.filter(workout => workout._id !== action.payload)
        },
        searchResults: { 
          ...state.searchResults, 
          data: state.searchResults.data.filter(workout => workout._id !== action.payload)
        },
        suggestedWorkouts: { 
          ...state.suggestedWorkouts, 
          data: state.suggestedWorkouts.data.filter(workout => workout._id !== action.payload)
        },
        error: null
      };
    case LIKE_WORKOUT:
      return {
        ...state,
        trendingWorkouts: { 
          ...state.trendingWorkouts, 
          data: updateLikesField(state.trendingWorkouts.data, action.payload)
        },
        workoutsByCreator: { 
          ...state.workoutsByCreator, 
          data: updateLikesField(state.workoutsByCreator.data, action.payload)
        },
        searchResults: { 
          ...state.searchResults, 
          data: updateLikesField(state.searchResults.data, action.payload)
        },
        suggestedWorkouts: { 
          ...state.suggestedWorkouts, 
          data: updateLikesField(state.suggestedWorkouts.data, action.payload)
        },
        error: null
      };
    case INCREMENT_WORKOUT_RUNS:
      return {
        ...state,
        trendingWorkouts: { 
          ...state.trendingWorkouts, 
          data: updateRunsField(state.trendingWorkouts.data, action.payload)
        },
        workoutsByCreator: { 
          ...state.workoutsByCreator, 
          data: updateRunsField(state.workoutsByCreator.data, action.payload)
        },
        searchResults: { 
          ...state.searchResults, 
          data: updateRunsField(state.searchResults.data, action.payload)
        },
        suggestedWorkouts: { 
          ...state.suggestedWorkouts, 
          data: updateRunsField(state.suggestedWorkouts.data, action.payload)
        },
        error: null
      };
    case WORKOUT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default workoutReducer;

// Selectors
export const selectTrendingWorkouts = createSelector(
  state => state.workout.trendingWorkouts,
  trendingWorkouts => ({ ...trendingWorkouts })
);

export const selectSearchResults = createSelector(
  state => state.workout.searchResults,
  searchResults => ({ ...searchResults })
);
