import {
  IS_MEMBER,
  FETCH_PROGRAMS_BY_INVITED_USER,
  FETCH_PROGRAMS_BY_REQUESTED_USER,
  FETCH_PROGRAMS_BY_MEMBER,
  FETCH_SUGGESTED_PROGRAMS,
  GET_HOMEPAGE_PROGRAMS,
  CREATE_PROGRAM,
  DELETE_PROGRAM,
  GET_MEMBERS,
  GET_INVITED_USERS,
  GET_REQUESTED_USERS,
  ADD_JOIN_REQUEST,
  REMOVE_JOIN_REQUEST,
  ADD_MEMBER,
  REMOVE_MEMBER,
  LEAVE_PROGRAM,
  DENY_JOIN_REQUEST,
  SCHEDULE_WORKOUT,
  REMOVE_WORKOUT,
  UNSCHEDULE_WORKOUT,
  ADD_COMPETITION,
  REMOVE_COMPETITION,
  UPDATE_VISIBILITY,
  ADD_MEMBER_BY_JOIN_CODE,
  ADD_INVITED_USER,
  REMOVE_INVITED_USER,
  ACCEPT_PROGRAM_INVITATION,
  FETCH_PROGRAMS_BY_CREATOR,
  FETCH_PROGRAM_BY_ID,
  SEARCH_PROGRAMS,
  GET_EXERCISES_BY_PROGRAM,
  GET_WORKOUTS_BY_PROGRAM,
  JOIN_PROGRAM,
  CLEAR_PROGRAM_SEARCH_RESULTS,
  GET_TRENDING_PROGRAMS,
  PROGRAM_ERROR,
  CLEAR_JOIN_CODE_ERROR,
  JOIN_CODE_ERROR,
} from '../constants/actionTypes';
import { createSelector } from 'reselect';

const initialState = {
  isMember: false,
  hasLeft: false,
  public: false,
  program: null, // contains limited subset of program fields
  homepagePrograms: [],
  programs: { data: [], currentPage: 1, totalPages: 1 },
  invitedPrograms: { data: [], currentPage: 1, totalPages: 1 },
  requestedPrograms: { data: [], currentPage: 1, totalPages: 1 },
  suggestedPrograms: { data: [], currentPage: 1, totalPages: 1 },
  programsByCreator: { data: [], currentPage: 1, totalPages: 1 },
  searchResults: { data: [], currentPage: 1, totalPages: 1 },
  trendingPrograms: { data: [], currentPage: 1, totalPages: 1 },
  exercises: [],
  workouts: [],
  schedule: [[],[],[],[],[],[],[]],
  invited: [],
  members: [],
  requests: [],
  missingWorkouts: [[],[],[],[],[],[],[]],
  joinCodeSuccess: false,
  joinCodeError: null,
  error: null,
};

const programReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_MEMBER: 
      return {...state, isMember: action.payload};
    case GET_HOMEPAGE_PROGRAMS:
      return { ...state, homepagePrograms: action.payload.data, error: null };
    case GET_TRENDING_PROGRAMS:
      return { ...state, trendingPrograms: action.payload, error: null };
    case FETCH_PROGRAMS_BY_INVITED_USER:
      return { ...state, invitedPrograms: action.payload, error: null };
    case FETCH_PROGRAMS_BY_REQUESTED_USER:
      return {...state, requestedPrograms: action.payload, error: null }; 
    case FETCH_PROGRAMS_BY_MEMBER:
      return {...state, programs: action.payload, error: null }; 
    case FETCH_SUGGESTED_PROGRAMS: 
      return { ...state, suggestedPrograms: action.payload, error: null };
    case CREATE_PROGRAM:
      return { ...state,
        program: action.payload,
        programsByCreator: {...state.programsByCreator, data: [...state.programsByCreator.data, action.payload] },
        error: null,
      }
    case JOIN_PROGRAM:
      const { joinedProgram, joinStatus } = action.payload;

      if (joinStatus === 'joined') {
        return { ...state,
          program: joinedProgram,
          invitedPrograms: { data: state.invitedPrograms.data.filter(program => program._id !== joinedProgram._id), currentPage: state.invitedPrograms.currentPage, totalPages: state.invitedPrograms.totalPages },
          requestedPrograms: { data: state.requestedPrograms.data.filter(program => program._id !== joinedProgram._id), currentPage: state.requestedPrograms.currentPage, totalPages: state.requestedPrograms.totalPages },
          suggestedPrograms: { data: state.suggestedPrograms.data.filter(program => program._id !== joinedProgram._id), currentPage: state.suggestedPrograms.currentPage, totalPages: state.suggestedPrograms.totalPages },
          programs: { data: [...state.programs.data, joinedProgram], currentPage: state.programs.currentPage, totalPages: state.programs.totalPages },
          homepagePrograms: state.homepagePrograms.filter(program => program._id !== joinedProgram._id),
          trendingPrograms: { data: state.trendingPrograms.data.filter(program => program._id !== joinedProgram._id), currentPage: state.trendingPrograms.currentPage, totalPages: state.trendingPrograms.totalPages },
          searchResults: { data: state.searchResults.data.filter(program => program._id !== joinedProgram._id), currentPage: state.searchResults.currentPage, totalPages: state.searchResults.totalPages },
          error: null,
        };
      } else if (joinStatus === 'requested') {
        return { ...state,
          program: joinedProgram,
          homepagePrograms: state.homepagePrograms.map(program => program._id === joinedProgram._id ? joinedProgram : program),
          suggestedPrograms: { data: state.suggestedPrograms.data.map(program => program._id === joinedProgram._id ? joinedProgram : program), currentPage: state.suggestedPrograms.currentPage, totalPages: state.suggestedPrograms.totalPages },
          requestedPrograms: { data: [...state.requestedPrograms.data, joinedProgram], currentPage: state.requestedPrograms.currentPage, totalPages: state.requestedPrograms.totalPages },
          trendingPrograms: { data: state.trendingPrograms.data.map(program => program._id === joinedProgram._id ? joinedProgram : program), currentPage: state.trendingPrograms.currentPage, totalPages: state.trendingPrograms.totalPages },
          searchResults: { data: state.searchResults.data.map(program => program._id === joinedProgram._id ? joinedProgram : program), currentPage: state.searchResults.currentPage, totalPages: state.searchResults.totalPages },
          error: null,
        };
      } else {
        return state;
      }
    case DELETE_PROGRAM:
      return { ...state, 
        programsByCreator: {...state.programsByCreator, data: state.programsByCreator.data.filter(program => program._id !== action.payload)},
        programs: { data: state.programs.data.filter(program => program._id !== action.payload), currentPage: state.programs.currentPage, totalPages: state.programs.totalPages },
        requestedPrograms: { data: state.requestedPrograms.data.filter(program => program._id !== action.payload), currentPage: state.requestedPrograms.currentPage, totalPages: state.requestedPrograms.totalPages },
        invitedPrograms: { data: state.invitedPrograms.data.filter(program => program._id !== action.payload), currentPage: state.invitedPrograms.currentPage, totalPages: state.invitedPrograms.totalPages },
        suggestedPrograms: { data: state.suggestedPrograms.data.filter(program => program._id !== action.payload), currentPage: state.suggestedPrograms.currentPage, totalPages: state.suggestedPrograms.totalPages },
        homepagePrograms: state.homepagePrograms.filter(program => program._id !== action.payload),
        error: null,
      };
    case GET_MEMBERS: 
      return { ...state, members: action.payload, error: null };
    case GET_INVITED_USERS:
      return { ...state, invited: action.payload, error: null };
    case GET_REQUESTED_USERS:
      return { ...state, requests: action.payload, error: null };
    case ADD_JOIN_REQUEST:
      return { ...state, requestedPrograms: state.requestedPrograms.filter(program => program._id !== action.payload), error: null }; //?
    case REMOVE_JOIN_REQUEST: // withdraw join request
      return { ...state,
        requestedPrograms: { data: state.requestedPrograms.data.filter(program => program._id !== action.payload._id), currentPage: state.requestedPrograms.currentPage, totalPages: state.requestedPrograms.totalPages },
        homepagePrograms: state.homepagePrograms.map(program => program._id === action.payload._id ? action.payload : program),
        trendingPrograms: { data: state.trendingPrograms.data.map(program => program._id === action.payload._id ? action.payload : program), currentPage: state.trendingPrograms.currentPage, totalPages: state.trendingPrograms.totalPages },
        searchResults: { data: state.searchResults.data.map(program => program._id === action.payload._id ? action.payload : program), currentPage: state.searchResults.currentPage, totalPages: state.searchResults.totalPages },
        suggestedPrograms: { data: state.suggestedPrograms.data.map(program => program._id === action.payload._id ? action.payload : program), currentPage: state.suggestedPrograms.currentPage, totalPages: state.suggestedPrograms.totalPages },
        error: null
      };
    case DENY_JOIN_REQUEST:
      return { ...state, requests: state.requests.filter(user => user._id !== action.payload), error: null }
    case ADD_MEMBER:
      return { ...state, 
        requests: state.requests.filter(user => user._id !== action.payload._id), 
        members: [...state.members, action.payload],
        error: null
      };
    case REMOVE_MEMBER:
      return { ...state, members: state.members.filter(user => user._id !== action.payload), error: null };
    case LEAVE_PROGRAM: 
      return {...state, programs: {...state.programs, data: state.programs.data.filter(program => program._id !== action.payload)}, hasLeft: true, error: null};
    case SCHEDULE_WORKOUT:
      if (action.payload.workoutWithUrl === null) {
        const existingWorkout = state.workouts.find(workout => workout._id == action.payload.workoutId);
        return { ...state, 
          schedule: state.schedule.map((dayArray, index) => {
            if (index == action.payload.day) {
              return [...dayArray, existingWorkout];
            } else {
              return dayArray;
            }
          }),
          error: null
        };
      } else {
        return { ...state, 
          schedule: state.schedule.map((dayArray, index) => {
            if (index == action.payload.day) {
              return [...dayArray, action.payload.workoutWithUrl];
            } else {
              return dayArray;
            }
          }), 
          workouts: [...state.workouts, action.payload.workoutWithUrl],
          error: null
        };
      }
    case REMOVE_WORKOUT:
      return { ...state, workouts: state.workouts.filter(workout => workout._id !== action.payload) }; // possibly unused?
    case UNSCHEDULE_WORKOUT:
      let modifiedMissingWorkouts = state.missingWorkouts;
    
      const modifiedSchedule = state.schedule.map((dayArray, index) => {
        if (index === action.payload.day) {
          // Filter the array to remove the workout with the given ID, while keeping undefined values
          const filteredArray = dayArray.filter(workout => 
            workout === undefined || workout._id !== action.payload.workoutId
          );
    
          // Check if nothing was filtered out (i.e., the array lengths are the same)
          if (filteredArray.length === dayArray.length) {
            // Remove the first undefined value if it exists and remove the workout from the missingWorkouts array
            const undefinedIndex = filteredArray.indexOf(undefined);
            const removedMissingWorkout = modifiedMissingWorkouts[index].indexOf(action.payload.workoutId);

            if (undefinedIndex !== -1) {
              filteredArray.splice(undefinedIndex, 1);
            }

            if (removedMissingWorkout !== -1) {
              modifiedMissingWorkouts[index].splice(removedMissingWorkout, 1);
            }
          }

          return filteredArray;
        } else {
          return dayArray;
        }
      });
    
      if (action.payload.removedWorkout) {
        return {
          ...state,
          schedule: modifiedSchedule,
          missingWorkouts: modifiedMissingWorkouts,
          workouts: state.workouts.filter(workout => workout._id !== action.payload.workoutId),
          error: null
        };
      } else {
        // The workout is still scheduled elsewhere in the program
        return {
          ...state,
          missingWorkouts: modifiedMissingWorkouts,
          schedule: modifiedSchedule,
          error: null
        };
      }
    case ADD_COMPETITION:
      return { ...state, exercises: [...state.exercises, action.payload], error: null };
    case REMOVE_COMPETITION:
      return { ...state, exercises: state.exercises.filter(exercise => exercise._id !== action.payload), error: null };
    case UPDATE_VISIBILITY:
      return { ...state, program: { ...state.program, public: action.payload }, error: null };
    case ADD_MEMBER_BY_JOIN_CODE:
      const { joined, joinMessage, joinProgram } = action.payload;

      if (joined) { // Joined the program directly
        return { ...state,
          joinCodeSuccess: joined,
          invitedPrograms: { data: state.invitedPrograms.data.filter(program => program._id !== joinProgram._id), currentPage: state.invitedPrograms.currentPage, totalPages: state.invitedPrograms.totalPages },
          suggestedPrograms: { data: state.suggestedPrograms.data.filter(program => program._id !== joinProgram._id), currentPage: state.suggestedPrograms.currentPage, totalPages: state.suggestedPrograms.totalPages },
          programs: { data: [...state.programs.data, joinProgram], currentPage: state.programs.currentPage, totalPages: state.programs.totalPages },
        };
      } else { // Requested to join
        return { ...state, 
          joinCodeSuccess: joined,
          joinCodeError: joinMessage,
          requestedPrograms: { data: [...state.requestedPrograms.data, joinProgram], currentPage: state.requestedPrograms.currentPage, totalPages: state.requestedPrograms.totalPages },
        };
      }
    case CLEAR_JOIN_CODE_ERROR:
      return { ...state, joinCodeSuccess: false, joinCodeError: null };
    case JOIN_CODE_ERROR:
      return { ...state, joinCodeError: action.payload };
    case ADD_INVITED_USER:
      return { ...state, invited: [...state.invited, action.payload], error: null };
    case REMOVE_INVITED_USER:
      return { ...state, invited: state.invited.filter(user => user._id !== action.payload), error: null }
    case ACCEPT_PROGRAM_INVITATION:
      return { ...state, 
        invitedPrograms: { data: state.invitedPrograms.data.filter(program => program._id !== action.payload._id), currentPage: state.invitedPrograms.currentPage, totalPages: state.invitedPrograms.totalPages },
        suggestedPrograms: { data: state.suggestedPrograms.data.map(program => program._id === action.payload._id ? action.payload : program), currentPage: state.suggestedPrograms.currentPage, totalPages: state.suggestedPrograms.totalPages },
        programs: { data: [...state.programs.data, action.payload], currentPage: state.programs.currentPage, totalPages: state.programs.totalPages },
        homepagePrograms: state.homepagePrograms.map(program => program._id === action.payload._id ? action.payload : program),
        programsByCreator: { data: state.programsByCreator.data.map(program => program._id === action.payload._id ? action.payload : program), currentPage: state.programsByCreator.currentPage, totalPages: state.programsByCreator.totalPages },
        error: null 
      };
    case FETCH_PROGRAMS_BY_CREATOR:
      return { ...state, 
        programsByCreator: action.payload,
        error: null,
      };
    case FETCH_PROGRAM_BY_ID: // could probably use this to consolidate all other initial program fetches
      return { ...state, program: action.payload, error: null };
    case SEARCH_PROGRAMS: 
      return {
        ...state,
        searchResults: {
          data: action.payload.data,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case CLEAR_PROGRAM_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: { data: [], currentPage: 1, totalPages: 1 },
        error: null,
      };
    case GET_EXERCISES_BY_PROGRAM: 
      return { ...state, exercises: action.payload, error: null };
    case GET_WORKOUTS_BY_PROGRAM:
      return {
        ...state, 
        workouts: action.payload.workoutsWithUrls,
        schedule: action.payload.schedule.map(day=>day.map(id => action.payload.workoutsWithUrls.find(workout => workout._id == id))),
        missingWorkouts: action.payload.missingWorkouts,
        error: null
      };
    case PROGRAM_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default programReducer;

// Selectors
export const selectTrendingPrograms = createSelector(
  state => state.program.trendingPrograms,
  trendingPrograms => ({ ...trendingPrograms })
);

export const selectSearchResults = createSelector(
  state => state.program.searchResults,
  searchResults => ({ ...searchResults })
);