import * as api from '../api';
import {
  FETCH_BOOKMARKED_EXERCISES,
  FETCH_BOOKMARKED_WORKOUTS,
  FETCH_EXERCISE_BOOKMARKS_BY_CREATOR,
  FETCH_WORKOUT_BOOKMARKS_BY_CREATOR,
  FETCH_EXERCISE_BOOKMARKS_WITH_TITLES,
  FETCH_WORKOUT_BOOKMARKS_WITH_TITLES,
  TOGGLE_BOOKMARK,
  DELETE_BOOKMARKS_BY_EXERCISE_ID,
  DELETE_BOOKMARKS_BY_WORKOUT_ID,
  SIGN_IN,
  SIGN_UP,
  VERIFY_EMAIL,
  RECOVER_EMAIL,
  CHANGE_PASSWORD,
  CHECK_TOKEN,
  GET_USER_BY_ID,
  GET_USERS_BY_IDS,
  UPDATE_USER_PROFILE,
  GET_FOLLOWERS_BY_ID,
  GET_FOLLOWING_BY_ID,
  GET_INCOMING_BY_ID,
  GET_OUTGOING_BY_ID,
  FOLLOW_USER,
  WITHDRAW_REQUEST,
  UNFOLLOW_USER,
  ACCEPT_FOLLOW_REQUEST,
  DECLINE_FOLLOW_REQUEST,
  REMOVE_FOLLOWER,
  SEARCH_USERS,
  SUGGEST_USERS_TO_FOLLOW,
  USER_ERROR
} from '../constants/actionTypes';
import { appendFormData } from './util';

// User actions
export const signIn = (formData) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);
    dispatch({ type: SIGN_IN, payload: data });
    return { success: true, data };
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
    return { success: false, error: error.response.data.message };
  }
};

export const signUp = (formData) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);
    dispatch({ type: SIGN_UP, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.response.data.message });
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  try {
    const { data } = await api.verifyEmail(token);
    dispatch({ type: VERIFY_EMAIL, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const recoverEmail = (email) => async (dispatch) => {
  try {
    const { data } = await api.recoverEmail(email);
    dispatch({ type: RECOVER_EMAIL, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const changePassword = (formData) => async (dispatch) => {
  try {
    const { data } = await api.changePassword(formData);
    dispatch({ type: CHANGE_PASSWORD, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const checkToken = (formData) => async (dispatch) => {
  try {
    const { data } = await api.checkToken(formData);
    dispatch({ type: CHECK_TOKEN, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getUserById(id);
    dispatch({ type: GET_USER_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const getUsersByIds = (id, page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getUsersByIds(id, page, limit);
    dispatch({ type: GET_USERS_BY_IDS, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const updateUserProfile = (updatedUser) => async (dispatch) => {
  try {
    const formData = new FormData();
    appendFormData(formData, updatedUser);
    const { data } = await api.updateUserProfile(formData);
    dispatch({ type: UPDATE_USER_PROFILE, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const getFollowersByUserId = (id) => async (dispatch) => {
  try {
    const { data } = await api.getFollowersByUserId(id);
    dispatch({ type: GET_FOLLOWERS_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const getFollowingByUserId = (id) => async (dispatch) => {
  try {
    const { data } = await api.getFollowingByUserId(id);
    dispatch({ type: GET_FOLLOWING_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const getIncomingByUserId = (id) => async (dispatch) => {
  try {
    const { data } = await api.getIncomingByUserId(id);
    dispatch({ type: GET_INCOMING_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const getOutgoingByUserId = (id) => async (dispatch) => {
  try {
    const { data } = await api.getOutgoingByUserId(id);
    dispatch({ type: GET_OUTGOING_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const followUser = (id) => async (dispatch) => {
  try {
    const { data } = await api.followUser(id);
    console.log(data);
    dispatch({ type: FOLLOW_USER, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const withdrawRequest = (id) => async (dispatch) => {
  try {
    const { data } = await api.withdrawRequest(id);
    dispatch({ type: WITHDRAW_REQUEST, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const unfollowUser = (id) => async (dispatch) => {
  try {
    const { data } = await api.unfollowUser(id);
    dispatch({ type: UNFOLLOW_USER, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const acceptFollowRequest = (id) => async (dispatch) => {
  try {
    const { data } = await api.acceptFollowRequest(id);
    dispatch({ type: ACCEPT_FOLLOW_REQUEST, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const declineFollowRequest = (id) => async (dispatch) => {
  try {
    const { data } = await api.declineFollowRequest(id);
    dispatch({ type: DECLINE_FOLLOW_REQUEST, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const removeFollower = (id) => async (dispatch) => {
  try {
    const { data } = await api.removeFollower(id);
    dispatch({ type: REMOVE_FOLLOWER, payload: id });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const searchUsers = (query) => async (dispatch) => {
  try {
    const { data } = await api.searchUsers(query);
    dispatch({ type: SEARCH_USERS, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

export const suggestUsersToFollow = () => async (dispatch) => {
  try {
    const { data } = await api.suggestUsersToFollow();
    dispatch({ type: SUGGEST_USERS_TO_FOLLOW, payload: data });
  } catch (error) {
    dispatch({ type: USER_ERROR, payload: error.message });
  }
};

// Bookmark actions

export const getExerciseBookmarksByCreator = () => async (dispatch) => {
  try {
    const { data } = await api.getExerciseBookmarksByCreator();
    dispatch({ type: FETCH_EXERCISE_BOOKMARKS_BY_CREATOR, payload: data });
  } catch (error) {
    console.error('Error fetching exercise bookmarks by creator:', error);
  }
};

export const getWorkoutBookmarksByCreator = () => async (dispatch) => {
  try {
    const { data } = await api.getWorkoutBookmarksByCreator();
    dispatch({ type: FETCH_WORKOUT_BOOKMARKS_BY_CREATOR, payload: data });
  } catch (error) {
    console.error('Error fetching workout bookmarks by creator:', error);
  }
};

export const getBookmarkedExercisesByCreator = (page = 1, limit = 8) => async (dispatch) => {
  try {
    const { data } = await api.getBookmarkedExercisesByCreator(page, limit);
    dispatch({ type: FETCH_BOOKMARKED_EXERCISES, payload: data });
  } catch (error) {
    console.error('Error fetching exercise bookmarks by creator:', error);
  }
};

export const getExerciseBookmarksWithTitles = () => async (dispatch) => {
  try {
    const { data } = await api.getExerciseBookmarksWithTitles();
    dispatch({ type: FETCH_EXERCISE_BOOKMARKS_WITH_TITLES, payload: data });
  } catch (error) {
    console.error('Error fetching exercise bookmarks with titles:', error);
  }
}

export const getWorkoutBookmarksWithTitles = () => async (dispatch) => {
  try {
    const { data } = await api.getWorkoutBookmarksWithTitles();
    dispatch({ type: FETCH_WORKOUT_BOOKMARKS_WITH_TITLES, payload: data });
  } catch (error) {
    console.error('Error fetching workout bookmarks with titles:', error);
  }
}

export const getBookmarkedWorkoutsByCreator = (page = 1, limit = 8) => async (dispatch) => {
  try {
    const { data } = await api.getBookmarkedWorkoutsByCreator(page, limit);
    dispatch({ type: FETCH_BOOKMARKED_WORKOUTS, payload: data });
  } catch (error) {
    console.error('Error fetching workout bookmarks by creator:', error);
  }
};

export const bookmark = (bookmarkData) => async (dispatch) => {
  try {
    const { data } = await api.toggleBookmark(bookmarkData);
    dispatch({ type: TOGGLE_BOOKMARK, payload: data });
  } catch (error) {
    console.error('Error creating bookmark:', error);
  }
};

export const deleteBookmarksByExerciseId = (exerciseId) => async (dispatch) => {
  try {
    const { data } = await api.deleteBookmarksByExerciseId(exerciseId);
    dispatch({ type: DELETE_BOOKMARKS_BY_EXERCISE_ID, payload: { exerciseId } });
  } catch (error) {
    console.error('Error deleting bookmarks by exercise ID:', error);
  }
};

export const deleteBookmarksByWorkoutId = (workoutId) => async (dispatch) => {
  try {
    const { data } = await api.deleteBookmarksByWorkoutId(workoutId);
    dispatch({ type: DELETE_BOOKMARKS_BY_WORKOUT_ID, payload: { workoutId } });
  } catch (error) {
    console.error('Error deleting bookmarks by workout ID:', error);
  }
};
