import React from 'react';
import {
  getTrendingPrograms,
  searchPrograms,
  clearSearchResults,
} from '../../../actions/program';
import ProgramCard from '../Admin/ProgramCard';
import ContentPage from '../../Content/ContentPage';
import { selectTrendingPrograms, selectSearchResults } from '../../../reducers/program';

const ProgramSearch = () => (
  <ContentPage
    title="Programs"
    searchPlaceholder="Search Programs..."
    fetchTrendingAction={getTrendingPrograms}
    searchAction={searchPrograms}
    clearSearchResultsAction={clearSearchResults}
    fetchBookmarksAction={null}
    CardComponent={ProgramCard}
    trendingItemsSelector={selectTrendingPrograms}
    searchResultsSelector={selectSearchResults}
  />
);

export default ProgramSearch;