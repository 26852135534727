import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Box, Typography, Divider, IconButton, Pagination, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getBookmarkedExercisesByCreator, getBookmarkedWorkoutsByCreator } from '../../actions/user';
import ExerciseCard from '../Exercise/ExerciseCard';
import WorkoutCard from '../Workout/WorkoutCard';
import { useNavigate } from 'react-router-dom';

const Bookmarks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { bookmarkedExercises, bookmarkedWorkouts } = useSelector((state) => state.user);
  const [exercisePage, setExercisePage] = useState(1);
  const [workoutPage, setWorkoutPage] = useState(1);

  useEffect(() => {
    dispatch(getBookmarkedExercisesByCreator(exercisePage));
    dispatch(getBookmarkedWorkoutsByCreator(workoutPage));
  }, [dispatch, exercisePage, workoutPage]);

  const handleBack = () => {
    navigate('/home');
  };

  const handleExercisePageChange = (event, value) => {
    setExercisePage(value);
  };

  const handleWorkoutPageChange = (event, value) => {
    setWorkoutPage(value);
  };

  const classes = {
    title: {
      color: theme.palette.primary.main,
    },
    subtitle: {
      color: theme.palette.primary.main,
      marginBottom: 1,
    },
    pagination: {
      color: theme.palette.text.secondary,
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    headerBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  };

  return (
    <Container maxWidth="lg">
      <Box sx={classes.headerBox}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={classes.title}>
          <Divider>Bookmarks</Divider>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" sx={classes.subtitle}>
          <Divider>Bookmarked Exercises</Divider>
        </Typography>
        {bookmarkedExercises.data.length > 0 ? (
          <>
            <Grid container spacing={3} justifyContent="center">
              {bookmarkedExercises.data.map((exercise) => (
                <Grid key={exercise._id} item>
                  <ExerciseCard item={exercise} />
                </Grid>
              ))}
            </Grid>
            <Pagination
              count={bookmarkedExercises.totalPages}
              page={exercisePage}
              onChange={handleExercisePageChange}
              sx={classes.pagination}
            />
          </>
        ) : (
          <Typography variant="body1" align="center" sx={classes.pagination}>
            No bookmarked exercises found.
          </Typography>
        )}
      </Box>

      <Box mb={4}>
        <Typography variant="h5" sx={classes.subtitle}>
          <Divider>Bookmarked Workouts</Divider>
        </Typography>
        {bookmarkedWorkouts.data.length > 0 ? (
          <>
            <Grid container spacing={3} justifyContent="center">
              {bookmarkedWorkouts.data.map((workout) => (
                <Grid key={workout._id} item>
                  <WorkoutCard item={workout} />
                </Grid>
              ))}
            </Grid>
            <Pagination
              count={bookmarkedWorkouts.totalPages}
              page={workoutPage}
              onChange={handleWorkoutPageChange}
              sx={classes.pagination}
            />
          </>
        ) : (
          <Typography variant="body1" align="center" sx={classes.pagination}>
            No bookmarked workouts found.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Bookmarks;
