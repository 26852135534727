import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';
import WorkoutCard from '../../Workout/WorkoutCard';
import { getLogsByProgram } from '../../../actions/log'
import Log from '../../Log/Log';
import RestDayIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import { getWorkoutsByProgramId, getExercisesByProgramId } from '../../../actions/program'
import { 
    Container,
    Button, 
    Paper, 
    Typography, 
    InputAdornment, 
    IconButton, 
    TextField, 
    MenuItem, 
    Box, 
    Divider, 
    Stack, 
    Switch,
    Collapse,
    Alert, 
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Grid
} from '@mui/material';
import { useMediaQuery } from '@mui/material';

let initialValue = dayjs();
initialValue.format("YYYY-MM-DD");

function Day(props) {
    const theme = useTheme();
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? <DoneIcon sx={{ color: theme.palette.primary.light, fontSize: 18 }}/> : undefined}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}


const ReportDisplay = () => {
    const { id, memberId } = useParams();
    const [month, setMonth] = useState(0);
    const [date, setDate] = useState(new Date());
    const [logOptions, setLogOptions] = useState([])
    const { schedule } = useSelector(
        (state) => state.program
      );
    
    const altSchedule = schedule.map(day=>day.map(workout => workout?._id))
    const logs = useSelector(
        (state) => state.log.ownerLogs
    );
    const currentDay = new Date();
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const dispatch = useDispatch();
    
    const datesWithAllIds = [];
    
    // Group logs by date
    const logsByDate = logs.reduce((acc, log) => {
        const date = log.createdAt;
        if (!acc[date]) {
        acc[date] = [];
        }
        acc[date].push(log.workoutId);
        return acc;
    }, {});
    for (const date in logsByDate) {
        const logIds = logsByDate[date];

        // Determine the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = new Date(date).getDay();

        const requiredIds = altSchedule[dayOfWeek];
        // Check if all required IDs are present in the logs for that date
        const allIdsPresent = requiredIds.every(id => logIds.includes(id));
        if (allIdsPresent) {
        datesWithAllIds.push((new Date(date)).getDate());
        }
    }

    const theme = useTheme();
    const user = JSON.parse(localStorage.getItem("profile"));
    const styles = {
        restDayCard: {
          border: 1,
          borderColor: 'gray',
          borderRadius: 3,
          backgroundColor: theme.palette.background.paper,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '300px',
          width: '250px',
        },
      };

    useEffect(() => {
        dispatch(getWorkoutsByProgramId(id));
        dispatch(getExercisesByProgramId(id));
    }, []);

    useEffect(() => {
        dispatch(getLogsByProgram(month, id, memberId));
    }, [month]);

    useEffect(() => {
        const filteredLogs = logs.filter(log => {
            const logDate = new Date(log.createdAt);
            return (
              logDate.getFullYear() === date.getFullYear() &&
              logDate.getMonth() === date.getMonth() &&
              logDate.getDate() === date.getDate()
            );
        });
        console.log(filteredLogs);
        setLogOptions(filteredLogs);

    }, [date, logs]);


    const paperStyle = {
        padding: theme.spacing(2),
        borderRadius: 15,
        backgroundColor: theme.palette.background.paper,
      };
    return (
    <Container sx={{ mt: theme.spacing(1) }}>
        <Paper elevation={3} style={paperStyle} alignItems="center"> 
        <Box sx={{display: isSmallScreen ? 'block' : 'flex',}}>
            <Box display="flex" justifyContent="center" sx={{width: isSmallScreen ? '100%' : '40%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                    sx={{
                        minWidth: '300px',
                        maxWidth: '300px',
                        height: '325px',
                    }}
                    onMonthChange = {(e) => {
                        setMonth(currentDay.getMonth() - e.$d.getMonth() + (currentDay.getYear() - e.$d.getYear())*12);
                    }}
                    onYearChange = {(e) => {
                        setMonth(currentDay.getMonth() - e.$d.getMonth() + (currentDay.getYear() - e.$d.getYear())*12);
                    }}
                    onChange = {(e)=>{setDate(e.$d)}}
                    defaultValue={initialValue}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                        day: Day,
                    }}
                    slotProps={{
                        day: {
                            highlightedDays: datesWithAllIds
                        },
                    }}
                />
            </LocalizationProvider>
            </Box>
            <Box sx={{width: isSmallScreen ? "100%" : "60%"}}>
                <Stack spacing={2}  sx={{alignItems: isSmallScreen ? 'center' : 'none'}}>
                    {schedule[date.getDay()].map((workout) => (
                        <>
                            {
                                workout && 
                                <Box key={workout._id} sx={{display: isSmallScreen ? 'grid' : 'flex', placeItems: isSmallScreen ? 'center' : 'none'}}>

                                    <WorkoutCard item={workout} programData={ null }/>
                                    <Box sx={{width: isSmallScreen ? '100%' : '50%', marginLeft: isSmallScreen ? "0px" : "20px"}}>
                                        {((logOptions.filter(log => log.workoutId == workout._id).length == 1) && (logOptions[0].programs)) ?
                                            <Log creator={user.result._id} data={logOptions.find(log => log.programs.includes(id))} programId={id}/>
                                            :
                                            <Paper style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '10px', borderRadius: '15px', margin: '10px 0px' }}>
                                                <Typography>
                                                    User has yet to upload their log
                                                </Typography>
                                            </Paper>
                                        }

                                    </Box>
                                </Box>
                            }
                            {
                                !workout &&
                                <Box sx={styles.restDayCard}>
                                <Typography paddingTop='2.5%'>Deleted Workout</Typography>
                                <WarningIcon />
                                </Box>
                            }
                        </>
                    ))}
                    {schedule[date.getDay()].length === 0 && 
                        <Box sx={styles.restDayCard}>
                        <Typography paddingTop='2.5%'>Rest day</Typography>
                        <RestDayIcon />
                    </Box>
                    }
                </Stack>
            </Box>
            
        
        </Box>
        </Paper>
        </Container>
  );
};

export default ReportDisplay;






