/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import * as math from "./math";
const color = "aqua";
const boundingBoxColor = "red";
const lineWidth = 2;


export function drawPoint(ctx, y, x, r, color) {
  ctx.beginPath();
  ctx.arc(x, y, r, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.fill();
};

/**
* Draws a line on a canvas, i.e. a joint
*/
export function drawSegment([ay, ax], [by, bx], color, scale, ctx, width=lineWidth) {
  ctx.beginPath();
  ctx.moveTo(ax * scale, ay * scale);
  ctx.lineTo(bx * scale, by * scale);
  ctx.lineWidth = width;
  ctx.strokeStyle = color;
  ctx.stroke();
}

// draws edges on the canvas
export function drawEdges(ctx, tensor, r) {
  for (let i = 0; i < tensor.length; i++) {
    for (let j = 0; j < tensor[i].length; j++) {
      if (tensor[i][j] > 0.7) {
        drawPoint(ctx, i, j, r, color);
      }
    }
  }  
}



export function draw3DSkeleton(keypoints, minConfidence, ctx, scale=1) {
  const pairs = [[11,12],[11,13],[13,15],[12,14],[14,16],[11,23],[12,24],[23,25],[24,26],[25,27],[26,28],[23,24]];
  
  for (let i = 0; i < pairs.length; i++) {
    const kp1 = keypoints[pairs[i][0]];
    const kp2 = keypoints[pairs[i][1]];
    if (kp1.score > minConfidence || kp2.score > minConfidence) {
      drawSegment(
        [kp1.y, kp1.x],
        [kp2.y, kp2.x],
        color,
        scale,
        ctx
      );
    }
  }
}

export function draw2DSkeleton(keypoints, minConfidence, ctx, scale=1) {
  const pairs = [[5,6],[5,7],[7,9],[6,8],[8,10],[5,11],[6,12],[11,13],[12,14],[13,15],[14,16],[11,12]];
  
  for (let i = 0; i < pairs.length; i++) {
    const kp1 = keypoints[pairs[i][0]];
    const kp2 = keypoints[pairs[i][1]];
    if (kp1.score > minConfidence || kp2.score > minConfidence) {
      drawSegment(
        [kp1.y, kp1.x],
        [kp2.y, kp2.x],
        color,
        scale,
        ctx
      );
    }
  }
}

export function drawText(ctx, x, y, text, size) {
  ctx.font = size + 'px serif';
  ctx.fillStyle=color;
  ctx.fillText(text, x, y);
}

/**
* Draw pose keypoints onto a canvas
*/
export function drawKeypoints(keypoints, minConfidence, ctx, scale=1, width=3) {
  for (let i = 0; i < keypoints.length; i++) {
    if (keypoints[i].score < minConfidence) {
      continue;
    }

    drawPoint(ctx, keypoints[i].y * scale, keypoints[i].x * scale, width, color);
  }
}

// draws feedback lines over the incorrect body parts
export function drawFeedback(keypoints, feedback, flat, minConfidence, ctx, scale=1, width=3) {
  let midShoulder = null;
  let midHip = null;
  let midAnkle = null;
  if (flat) {
    midShoulder = (math.midpoint([keypoints[5].x, keypoints[5].y], [keypoints[6].x, keypoints[6].y]));
    midHip = (math.midpoint([keypoints[11].x, keypoints[11].y], [keypoints[12].x, keypoints[12].y]));
    midAnkle = (math.midpoint([keypoints[15].x, keypoints[15].y], [keypoints[16].x, keypoints[16].y]));
  }
  else {
    midShoulder = (math.midpoint([keypoints[11].x, keypoints[11].y, keypoints[11].z], [keypoints[12].x, keypoints[12].y, keypoints[12].z]));
    midHip = (math.midpoint([keypoints[23].x, keypoints[23].y, keypoints[23].z], [keypoints[24].x, keypoints[24].y, keypoints[24].z]));
    midAnkle = (math.midpoint([keypoints[27].x, keypoints[27].y, keypoints[27].z], [keypoints[28].x, keypoints[28].y, keypoints[28].z]));
  }
  
  
  
  keypoints.push({
    x: midShoulder[0],
    y: midShoulder[1]
  });
  keypoints.push({
    x: midHip[0],
    y: midHip[1]
  });
  keypoints.push({
    x: midAnkle[0],
    y: midAnkle[1]
  });
  for (let i = 0; i < feedback.length; i++) {
    // if (keypoints[feedback[i][1]].score < minConfidence) {
    //   continue;
    // }
    drawSegment(
      [keypoints[feedback[i][0]].y, keypoints[feedback[i][0]].x],
      [keypoints[feedback[i][1]].y, keypoints[feedback[i][1]].x],
      boundingBoxColor,
      scale,
      ctx
    );
    drawSegment(
      [keypoints[feedback[i][1]].y, keypoints[feedback[i][1]].x],
      [keypoints[feedback[i][2]].y, keypoints[feedback[i][2]].x],
      boundingBoxColor,
      scale,
      ctx
    );
    drawPoint(ctx, keypoints[feedback[i][1]].y * scale, keypoints[feedback[i][1]].x * scale, width, boundingBoxColor);
  }
}

/**
* Converts an arary of pixel data into an ImageData object
*/
export async function renderToCanvas(a, ctx) {
  const [height, width] = a.shape;
  const imageData = new ImageData(width, height);

  const data = await a.data();

  for (let i = 0; i < height * width; ++i) {
    const j = i * 4;
    const k = i * 3;

    imageData.data[j + 0] = data[k + 0];
    imageData.data[j + 1] = data[k + 1];
    imageData.data[j + 2] = data[k + 2];
    imageData.data[j + 3] = 255;
  }

  ctx.putImageData(imageData, 0, 0);

}

/**
* Draw an image on a canvas
*/
export function renderImageToCanvas(image, size, canvas) {
  canvas.width = size[0];
  canvas.height = size[1];
  const ctx = canvas.getContext("2d");

  ctx.drawImage(image, 0, 0);
}


export function rotateBase64Image(srcBase64, degrees) {
  const canvas = document.createElement('canvas');
  let ctx = canvas.getContext("2d");
  let image = new Image();
  image.src = srcBase64
  return new Promise(resolve => {
  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(degrees * Math.PI / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    resolve(canvas.toDataURL());
    };
  });
}