import * as React from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';

let initialValue = dayjs();
initialValue.format("YYYY-MM-DD");

function Day(props) {
    const theme = useTheme();
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? <DoneIcon sx={{ color: theme.palette.primary.light, fontSize: 18 }}/> : undefined}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}

export default function Calendar({setDate, setMonthVar, data}) {
    const [isLoading, setIsLoading] = React.useState(false);
    const highlightedDays = data;
    const currentDay = new Date();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                sx={{
                    minWidth: '300px',
                    maxWidth: '300px',
                    height: '325px',
                }}
                onMonthChange = {(e) => {
                    setMonthVar(currentDay.getMonth() - e.$d.getMonth() + (currentDay.getYear() - e.$d.getYear())*12);
                }}
                onYearChange = {(e) => {
                    setMonthVar(currentDay.getMonth() - e.$d.getMonth() + (currentDay.getYear() - e.$d.getYear())*12);
                }}
                onChange = {(e)=>{setDate(e.$d)}}
                defaultValue={initialValue}
                loading={isLoading}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                    day: Day,
                }}
                slotProps={{
                    day: {
                        highlightedDays,
                    },
                }}
            />
        </LocalizationProvider>
    );
}