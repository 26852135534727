import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, TextField, Box, Container, Typography, Stack, Switch, InputAdornment, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { createProgram } from '../../../actions/program';
import { createStripeConnectedAccount } from '../../../actions/payment';
import { useTheme } from '@mui/material/styles';
import FileInput from '../../Form/FileInput';

const ProgramForm = () => {
  const countryList = [
    "Albania",
    "Antigua & Barbuda",
    "Argentina",
    "Armenia",
    "Bahamas",
    "Australia",
    "Austria",
    "Bahrain",
    "Belgium",
    "Benin",
    "Bolivia",
    "Bosnia",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Cambodia",
    "Canada",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Estonia",
    "Ethiopia",
    "Finland",
    "France",
    "Gambia",
    "Germany",
    "Ghana",
    "Greece",
    "Guatemala",
    "Guyana",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kenya",
    "Kuwait",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao SAR China",
    "Madagascar",
    "Malaysia",
    "Malta",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Morocco",
    "Namibia",
    "Netherlands",
    "New Zealand",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Panama",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Rwanda",
    "Saudi Arabia",
    "Senegal",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "St. Lucia",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Tanzania",
    "Thailand",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vietnam"
  ];
  const user = JSON.parse(localStorage.getItem('profile'));
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [programData, setProgramData] = useState({
    title: "",
    description: "",
    visibility: true,
    thumbnail: null,
    price: "0.00",
  });
  const [rawThumbnail, setRawThumbnail] = useState(null);
  const [priceError, setPriceError] = useState(false);

  // states for Stripe account details
  const [country, setCountry] = useState('United States');
  const [email, setEmail] = useState(user.result.email);
  const [emailError, setEmailError] = useState('');

  const toggle = (event) => {
    setProgramData({ ...programData, visibility: !programData.visibility });
  };

  const handlePriceChange = (e) => {
    let value = e.target.value;

    // Validate input: Allow numbers and at most two decimal points
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      // Convert to float for validation purposes
      const floatValue = parseFloat(value);

      // Check if the price is either 0.00 or between 2.00 and 50.00
      if ((floatValue === 0.00 || (floatValue >= 2.00 && floatValue <= 50.00)) || value === "") {
        setPriceError(false);
        setProgramData({ ...programData, price: value });
      } else {
        setPriceError(true); // Set error if price is out of bounds
      }
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    // Basic email regex pattern for validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailValue === "") {
      setEmailError("Email is required");
    } else if (!emailPattern.test(emailValue)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(""); // Clear error if email is valid
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (false && !user.stripeAccountId && emailError === "") {
      await dispatch(createStripeConnectedAccount(email, country));
    }

    if (!priceError) {
      const result = await dispatch(createProgram({ ...programData,
        title: programData.title.length > 0 ? programData.title : "Untitled Program",
        thumbnail: rawThumbnail, 
        price: parseFloat(programData.price) || 0 
      }));
      setProgramData({ title: "", description: "", visibility: true, thumbnail: null, price: "0.00" });
      navigate('/programs/' + result._id);
    }
  };

  const buttonStyle = {
    margin: '8px',
    width: '200px',
  };

  const paperStyle = {
    padding: theme.spacing(2),
    borderRadius: 15,
    backgroundColor: theme.palette.background.paper,
  };

  const formStyle = {
    margin: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  };

  return (
    <>
      {user !== null && (
        <Container sx={{ mt: theme.spacing(1) }}>
          <Paper style={paperStyle} elevation={6}>
            <form autoComplete="off" noValidate style={formStyle} onSubmit={handleSubmit}>
              <Box sx={boxStyle}>
                <Typography color='primary' variant="h6">Program Builder</Typography>
                <TextField sx={{ m: 1 }} name="title" variant="outlined" label="Program Title" fullWidth multiline rows={1} value={programData.title} onChange={(e) => setProgramData({ ...programData, title: e.target.value })} />
                <TextField sx={{ m: 1 }} name="description" variant="outlined" label="Description" fullWidth multiline rows={4} value={programData.description} onChange={(e) => setProgramData({ ...programData, description: e.target.value })} />
                
                {false && (<>
                <TextField
                  sx={{ m: 1, width: '200px' }}
                  size="small"
                  name="price"
                  variant="outlined"
                  label="Monthly subscription price"
                  value={programData.price}
                  error={priceError}
                  helperText={priceError ? "Price must be $0.00 or between $2.00 and $50.00" : "Gains Coach, LLC takes a 30% cut. Leave blank or $0 for free programs."}
                  onChange={handlePriceChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />

                <Typography variant="body1" sx={{ mt: 1 }}>Stripe Account Details for Payouts</Typography>
                <Typography variant="body2" sx={{ mb: 2, color: theme.palette.text.secondary }}>A new account will be created if you do not have one yet.</Typography>

                <Box sx={{ mb: 2, width: '200px' }}>
                  <TextField 
                    size="small"
                    name="email" 
                    variant="outlined" 
                    label="Email" 
                    fullWidth 
                    onChange={handleEmailChange} 
                    value={email} 
                    error={emailError !== ""}
                    helperText={emailError}
                  />
                </Box>

                <Box sx={{ mb: 1, width: '200px' }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      size="small"
                      labelId="country-label"
                      id="country"
                      value={country}
                      onChange={handleCountryChange}
                      label="Country"
                    >
                      {countryList.map((countryName) => (
                        <MenuItem key={countryName} value={countryName}>
                          {countryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                </>)}

                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', mt: '20px' }}>
                  <Typography variant="body1">Private</Typography>
                  <Switch
                    checked={programData.visibility}
                    onChange={toggle}
                  />
                  <Typography variant="body1">Public</Typography>
                </Stack>

                <FileInput val="" base64={false} onDone={(file) => setRawThumbnail(file)}>
                  Upload Thumbnail
                </FileInput>

                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={handleSubmit}
                  disabled={priceError}
                >
                  Create Program
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default ProgramForm;