import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Box, Typography, Button, useTheme, Divider, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';

const GenericHomeContent = ({
  title,
  fetchDataAction,
  fetchGuestDataAction,
  bookmarksAction,
  CardComponent,
  navigatePath,
  reduxStateSelector,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [items, setItems] = useState([]);

  const data = useSelector(reduxStateSelector);

  useEffect(() => {
    if (user) {
      dispatch(fetchDataAction());
      if (bookmarksAction) dispatch(bookmarksAction());
    } else {
      dispatch(fetchGuestDataAction());
    }
  }, [dispatch, fetchDataAction, fetchGuestDataAction, bookmarksAction]);

  useEffect(() => {
    let combinedItems = data || [];
    combinedItems = uniqBy(combinedItems, '_id');
    setItems(combinedItems);
  }, [data]);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getDisplayCount = () => {
    if (isLargeScreen) return 7;
    if (isMediumScreen) return 5;
    if (isSmallScreen) return 5;
    return 3;
  };

  const displayItems = items.slice(0, getDisplayCount());

  const handleNavigate = () => {
    navigate(navigatePath);
  };

  const classes = {
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '7px',
      border: '1px solid gray',
      height: isExtraSmallScreen ? '350px' : '300px',
      width: isExtraSmallScreen ? '300px' : '250px',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      textAlign: 'center',
    },
    title: {
      color: theme.palette.primary.main,
    },
    body: {
      color: theme.palette.text.secondary,
    },
    button: {
      marginTop: theme.spacing(2),
    },
  };

  return (
    <Container maxWidth="lg">
      <Typography sx={classes.title} variant="h4" align="center" gutterBottom>
        <Divider>{title}</Divider>
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {displayItems.map((item) => (
          <Grid key={item._id} item>
            <CardComponent item={item} isMobile={isExtraSmallScreen} />
          </Grid>
        ))}
        <Grid item>
          <Box sx={classes.card}>
            <Typography variant="h6" gutterBottom sx={classes.title}>
              Discover More {title}
            </Typography>
            <Typography variant="body2" gutterBottom sx={classes.body}>
              Explore a wide range of {title.toLowerCase()} tailored to your needs. Click the button below to see more.
            </Typography>
            <Button variant="contained" color="primary" sx={classes.button} onClick={handleNavigate}>
              Explore More
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

GenericHomeContent.propTypes = {
  title: PropTypes.string.isRequired,
  fetchDataAction: PropTypes.func.isRequired,
  fetchGuestDataAction: PropTypes.func.isRequired,
  bookmarksAction: PropTypes.func,
  CardComponent: PropTypes.elementType.isRequired,
  navigatePath: PropTypes.string.isRequired,
  reduxStateSelector: PropTypes.func.isRequired,
};

export default GenericHomeContent;
