import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkoutBookmarksByCreator, bookmark, deleteBookmarksByWorkoutId } from '../../actions/user';
import { likeWorkout, deleteWorkout } from '../../actions/workout';
import { useNavigate } from 'react-router-dom';
import GenericCard from '../Content/Card';
import { unscheduleWorkout } from '../../actions/program';

const WorkoutCard = ({ item, isMobile, programData=null }) => {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem('profile'))?.result?._id;
  const navigate = useNavigate();
  const bookmarks = useSelector((state) => state.user.workoutBookmarks);
  const isBookmarked = bookmarks?.includes(item._id);

  const handleLike = (id) => {
    dispatch(likeWorkout(id));
  };

  const handleRemove = () => {
    if (programData.workoutId != null && programData.day != null) {
      dispatch(unscheduleWorkout(programData.programId, {workoutId: programData.workoutId, day: programData.day}));
    }
  };

  const handleBookmark = async (id) => {
    if (isBookmarked) {
      await dispatch(deleteBookmarksByWorkoutId(id));
    } else {
      await dispatch(bookmark({ workoutId: id }));
    }
    dispatch(getWorkoutBookmarksByCreator()); // Ensure state is up-to-date
  };

  const handleDelete = (id) => {
    dispatch(deleteBookmarksByWorkoutId(id));
    dispatch(deleteWorkout(id));
  };

  const handleEdit = (id) => {
    navigate(`/workout/${id}`);
  };
 
  return (
    <GenericCard
      item={{ ...item, type: 'workout' }}
      onLike={handleLike}
      onRemove={handleRemove}
      onBookmark={handleBookmark}
      onDelete={handleDelete}
      onEdit={handleEdit}
      isBookmarked={isBookmarked}
      isLiked={item?.likes?.includes(userId)}
      isMobile={isMobile}
      programData={programData}
    />
  );
};

export default WorkoutCard;
