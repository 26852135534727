import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Stack, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { denyJoinRequest, addMember } from '../../../actions/program';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    } from '@mui/material';

const MAX_NAME_LENGTH = 15;
const MAX_BIO_LENGTH = 15;

const GroupJoin = ({result, id}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const deleteRequest = () => {
        dispatch(denyJoinRequest(id, result._id));
    };

    const acceptRequest = () => {
        dispatch(addMember(id, result._id));
    }
    

    return (
        
    <ListItem alignItems="flex-start" key={result._id}>
        <ListItemAvatar>
            <Avatar alt={result.username} src={result.profilePictureUrl}>{result.username?.charAt(0)}</Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={result.username.length > MAX_NAME_LENGTH ? `${result.username.substring(0, MAX_NAME_LENGTH)}...` : result.username}
            secondary={result.bio.length > MAX_BIO_LENGTH ? `${result.bio.substring(0, MAX_BIO_LENGTH)}...` : result.bio}
        />
        <Box>
            <IconButton onClick={acceptRequest}>
                <CheckIcon />
            </IconButton>
            <IconButton onClick={deleteRequest}>
                <ClearIcon />
            </IconButton>
        </Box>
    </ListItem>  
    );
}

export default GroupJoin;