import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Typography, Grid, Divider, Container, Avatar, Pagination, PaginationItem, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import Exercise from '../Exercise/ExerciseCard';
import Workout from '../Workout/WorkoutCard';
import Video from '../Video/VideoCard';
import Program from '../Program/Admin/ProgramCard';
import { getExercisesByCreator } from '../../actions/exercise';
import { getWorkoutsByCreator } from '../../actions/workout';
import { getVideosByCreator } from '../../actions/video';
import { getProgramsByCreator } from '../../actions/program';
import { getUserById, followUser, unfollowUser } from '../../actions/user';
import EditProfileForm from './EditProfileForm';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProfilePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const userProfile = useSelector((state) => state.user.user);
  const { exercisesByCreator } = useSelector((state) => state.exercise);
  const { workoutsByCreator } = useSelector((state) => state.workout);
  const { videosByCreator } = useSelector((state) => state.video);
  const { programsByCreator } = useSelector((state) => state.program);

  const query = useQuery();
  const [exercisePage, setExercisePage] = useState(parseInt(query.get('exercisePage') || '1', 10));
  const [workoutPage, setWorkoutPage] = useState(parseInt(query.get('workoutPage') || '1', 10));
  const [videoPage, setVideoPage] = useState(parseInt(query.get('videoPage') || '1', 10));
  const [programPage, setProgramPage] = useState(parseInt(query.get('programPage') || '1', 10));

  const currentUser = JSON.parse(localStorage.getItem('profile'))?.result;

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getExercisesByCreator(id, exercisePage));
  }, [dispatch, id, exercisePage]);

  useEffect(() => {
    dispatch(getWorkoutsByCreator(id, workoutPage));
  }, [dispatch, id, workoutPage]);

  useEffect(() => {
    dispatch(getVideosByCreator(id, videoPage));
  }, [dispatch, id, videoPage]);

  useEffect(() => {
    dispatch(getProgramsByCreator(id, programPage));
  }, [dispatch, id, programPage]);

  useEffect(() => {
    const newExercisePage = parseInt(query.get('exercisePage') || '1', 10);
    const newWorkoutPage = parseInt(query.get('workoutPage') || '1', 10);
    const newVideoPage = parseInt(query.get('videoPage') || '1', 10);
    const newProgramPage = parseInt(query.get('programPage') || '1', 10);

    if (exercisePage !== newExercisePage) setExercisePage(newExercisePage);
    if (workoutPage !== newWorkoutPage) setWorkoutPage(newWorkoutPage);
    if (videoPage !== newVideoPage) setVideoPage(newVideoPage);
    if (programPage !== newProgramPage) setProgramPage(newProgramPage);
  }, [query]);

  const totalExercisePages = exercisesByCreator?.totalPages || 1;
  const totalWorkoutPages = workoutsByCreator?.totalPages || 1;
  const totalVideoPages = videosByCreator?.totalPages || 1;
  const totalProgramPages = programsByCreator?.totalPages || 1;

  const handleExercisePageChange = (event, value) => {
    if (value <= totalExercisePages) {
      setExercisePage(value);
    }
  };

  const handleWorkoutPageChange = (event, value) => {
    if (value <= totalWorkoutPages) {
      setWorkoutPage(value);
    }
  };

  const handleVideoPageChange = (event, value) => {
    if (value <= totalVideoPages) {
      setVideoPage(value);
    }
  };

  const handleProgramPageChange = (event, value) => {
    if (value <= totalProgramPages) {
      setProgramPage(value);
    }
  };

  const isFollowing = userProfile?.followers?.includes(currentUser?._id);

  const handleFollow = () => {
    if (isFollowing) {
      dispatch(unfollowUser(id));
    } else {
      dispatch(followUser(id));
    }
  };

  return (
    <Container sx={{ mt: theme.spacing(1) }}>
      {(!userProfile && !exercisesByCreator?.data?.length && !workoutsByCreator?.data?.length && !videosByCreator?.data?.length) ? (
        <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
          No profile data found
        </Typography>
      ) : (
        <>
          {userProfile && (
            <>
              <Grid container direction="column" alignItems="center">
                <Avatar src={userProfile.profilePictureUrl || userProfile.profilePicture} alt={userProfile.username} sx={{ width: 100, height: 100 }} />
                <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
                  {userProfile.username}
                </Typography>
                <Typography variant="h5" sx={{ color: theme.palette.text.secondary }}>
                  {userProfile.bio}
                </Typography>
                {currentUser?._id === id ? (
                  <EditProfileForm user={userProfile} />
                ) : (
                  <Button variant="contained" color="primary" onClick={handleFollow}>
                    {isFollowing ? 'Unfollow' : 'Follow'}
                  </Button>
                )}
              </Grid>
              <Divider style={{ margin: '20px 0 30px 0' }} />
            </>
          )}

          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Exercises
          </Typography>
          <Divider style={{ margin: '20px 0 30px 0' }} />
          {!exercisesByCreator?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No exercises found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {exercisesByCreator.data.map((exercise) => (
                  <Grid key={exercise._id} item>
                    <Exercise item={exercise} />
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalExercisePages}
                  page={exercisePage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleExercisePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      component={Link}
                      to={`/profile/${id}?exercisePage=${item.page}&workoutPage=${workoutPage}&videoPage=${videoPage}&programPage=${programPage}`}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Workouts
          </Typography>
          <Divider style={{ margin: '20px 0 30px 0' }} />
          {!workoutsByCreator?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No workouts found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {workoutsByCreator.data.map((workout) => (
                  <Grid key={workout._id} item>
                    <Workout item={workout} />
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalWorkoutPages}
                  page={workoutPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleWorkoutPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      component={Link}
                      to={`/profile/${id}?exercisePage=${exercisePage}&workoutPage=${item.page}&videoPage=${videoPage}&programPage=${programPage}`}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Videos
          </Typography>
          <Divider style={{ margin: '20px 0 30px 0' }} />
          {!videosByCreator?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No videos found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {videosByCreator.data.map((video) => (
                  <Grid key={video._id} item>
                    <Video item={video} />
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalVideoPages}
                  page={videoPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleVideoPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      component={Link}
                      to={`/profile/${id}?exercisePage=${exercisePage}&workoutPage=${workoutPage}&videoPage=${item.page}&programPage=${programPage}`}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Typography variant="h4" sx={{ color: theme.palette.primary.main, mt: 4, mb: 2 }}>
            Programs
          </Typography>
          <Divider style={{ margin: '20px 0 30px 0' }} />
          {!programsByCreator?.data?.length ? (
            <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
              No programs found
            </Typography>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {programsByCreator.data.map((program) => (
                  <Grid key={program._id} item>
                    <Program item={program} />
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                  count={totalProgramPages}
                  page={programPage}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleProgramPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      component={Link}
                      to={`/profile/${id}?exercisePage=${exercisePage}&workoutPage=${workoutPage}&videoPage=${videoPage}&programPage=${item.page}`}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ProfilePage;
