import { useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Container, Paper } from '@mui/material';
import { verifyEmail } from '../../actions/user';

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const emailToken = searchParams.get("emailToken");
  const dispatch = useDispatch();

  useEffect(() => {
    const verify = async () => {
      if (emailToken) {
        setIsLoading(true);
        try {
          await dispatch(verifyEmail({ emailToken }));
          setIsLoading(false);
        } catch (err) {
          setError(true);
          setIsLoading(false);
        }
      }
    };
    verify();
  }, [dispatch, emailToken]);

  const getVerificationStatus = () => {
    if (isLoading) {
      return "Loading...";
    } else if (error) {
      return "Error verifying email. Please try again later.";
    } else if (emailToken) {
      return "Email verified";
    } else {
      return "No email token provided";
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper style={{ padding: '30px', borderRadius: '10px', marginTop: '10px', marginBottom: '10px',}} elevation={6}>
        <Typography variant="h3" color="primary" component="h2">{getVerificationStatus()}</Typography>
      </Paper>
    </Container>
  );
};

export default VerifyEmail;