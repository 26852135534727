import * as poseDetection from '@tensorflow-models/pose-detection';
import '@tensorflow/tfjs-backend-webgl';


export default class Detector {
    // Stores the model
    constructor() {
        this.net = this.setup();
    }

    // Loads the model
    async setup() {
        const model = poseDetection.SupportedModels.BlazePose;
        const detectorConfig = {
            runtime: 'tfjs',
            modelType: 'full'
        };
        const detector = await poseDetection.createDetector(model, detectorConfig);
        return detector;
    }

    // Takes in an image and returns an array of keypoints
    async detect(image) {
        const pose = await (await this.net).estimatePoses(image);
        return pose[0];
    }
}