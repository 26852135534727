import React from 'react';
import {
  getTrendingVideos,
  searchVideos,
  clearSearchResults,
} from '../../actions/video';
import VideoCard from './VideoCard';
import ContentPage from '../Content/ContentPage';
import { selectTrendingVideos, selectSearchResults } from '../../reducers/video';

const VideoSearch = () => (
  <ContentPage
    title="Videos"
    searchPlaceholder="Search Videos..."
    fetchTrendingAction={getTrendingVideos}
    searchAction={searchVideos}
    clearSearchResultsAction={clearSearchResults}
    fetchBookmarksAction={null}
    CardComponent={VideoCard}
    trendingItemsSelector={selectTrendingVideos}
    searchResultsSelector={selectSearchResults}
  />
);

export default VideoSearch;
