import { FETCH_STATS_BY_CREATOR, CREATE_STATS, INCREMENT_STATS } from '../constants/actionTypes';

const initialState = {
  stats: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATS_BY_CREATOR:
      return { ...state, stats: action.payload };
    case CREATE_STATS:
      return { ...state, stats: action.payload };
    case INCREMENT_STATS:
      return { ...state, stats: action.payload };
    default:
      return state;
  }
};