import React from 'react';
import { getExerciseById, incrementExerciseRuns } from '../../actions/exercise';
import Details from '../Content/Details';

const ExerciseDetails = () => {
  return (
    <Details 
      type="exercise" 
      fetchItemById={getExerciseById} 
      incrementRuns={incrementExerciseRuns} 
    />
  );
};

export default ExerciseDetails;