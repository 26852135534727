import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { removeMember } from '../../../actions/program';
import {
        ListItem,
        ListItemAvatar,
        Avatar,
        ListItemText,
        } from '@mui/material';
const MAX_NAME_LENGTH = 15;
const MAX_BIO_LENGTH = 15;

const GroupMember = ({id, result}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const deleteMember = () => {
        dispatch(removeMember(id, result._id));
    };

    const generateReport = () => {
        navigate(`/programs/${id}/${result._id}`);
    };

    const nameStyle = {
        color: theme.palette.text.secondary,
    };

    const stackStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
        
    const buttonStyle = {
        color: theme.palette.primary.main,
    };
    return (
        <ListItem alignItems="flex-start" key={result._id}>
            <ListItemAvatar>
                <Avatar alt={result.username} src={result.profilePictureUrl}>{result.username?.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={result.username.length > MAX_NAME_LENGTH ? `${result.username.substring(0, MAX_NAME_LENGTH)}...` : result.username}
                secondary={result.bio.length > MAX_BIO_LENGTH ? `${result.bio.substring(0, MAX_BIO_LENGTH)}...` : result.bio}
            />
            <Stack direction='row' spacing={1}>
                <Button sx={buttonStyle} onClick={generateReport}>View Report</Button>
                <Button sx={buttonStyle} onClick={deleteMember}>Remove</Button>
            </Stack>
        </ListItem>     
    );
};

export default GroupMember;