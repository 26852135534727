import React from 'react';
import { getHomepagePrograms, getHomepageProgramsForGuest } from '../../../actions/program';
import ProgramCard from '../Admin/ProgramCard';
import GenericHomeContent from '../../Content/HomeContent';

const HomePrograms = () => {
  const user = JSON.parse(localStorage.getItem('profile'));

  const fetchDataAction = user ? getHomepagePrograms : getHomepageProgramsForGuest;

  const reduxStateSelector = (state) => state.program.homepagePrograms;

  return (
    <GenericHomeContent
      title="Programs"
      fetchDataAction={fetchDataAction}
      fetchGuestDataAction={getHomepageProgramsForGuest}
      bookmarksAction={null}
      CardComponent={ProgramCard}
      navigatePath="/programs"
      reduxStateSelector={reduxStateSelector}
      containerTitle="Program"
    />
  );
};

export default HomePrograms;
