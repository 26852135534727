import { Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FollowRequest from './followRequest';


const RequestsList = ({incoming, outgoing}) => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const theme = useTheme();
    return (
        <>
            <Divider>
                <Typography variant='body1' color={theme.palette.text.secondary}>Incoming</Typography>
            </Divider>
            {incoming.length === 0 ? <FollowRequest command="blank"/> :
            incoming.map((req, index)=>(<FollowRequest key={index} id={req._id} name={req.username} bio = {req.bio} command="receiver" user={user.result._id} profilePicture={req.profilePictureUrl}/>))}
            
            <Divider>
                <Typography variant='body1' color={theme.palette.text.secondary}>Outgoing</Typography>
            </Divider>
            {outgoing.length === 0 ? <FollowRequest command="blank"/> :
            outgoing.map((req, index)=>(<FollowRequest key={index} id={req._id} name={req.username} bio = {req.bio} command="creator" user={user.result._id} profilePicture={req.profilePictureUrl}/>))}  
        </>
    );
};

export default RequestsList;