import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    Container,
    Button, 
    Paper, 
    Typography, 
    InputAdornment, 
    IconButton, 
    TextField, 
    MenuItem, 
    Box, 
    Divider, 
    Stack, 
    Switch,
    Collapse,
    Alert, 
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import CalendarGrid from './WorkoutDisplay';
import LogDisplay from '../Member/LogDisplay'
import ProgramInvitation from './ProgramInvitation';
import ProgramMember from './ProgramMember';
import ProgramJoinRequest from './ProgramJoinRequest';
import ExerciseCard from '../../Exercise/ExerciseCard';
import { getWorkoutBookmarksWithTitles, getExerciseBookmarksWithTitles, searchUsers } from '../../../actions/user';
import { 
  isMember,
  getProgramById,
  deleteProgram, 
  scheduleWorkout,  
  addCompetition, 
  removeCompetition,
  updateVisibility,
  getWorkoutsByProgramId, 
  getExercisesByProgramId, 
  addInvitedUser, 
  removeInvitedUser,
  getMembers,
  getInvitedUsers,
  getRequestedUsers,
  leaveProgram,
} from '../../../actions/program';
import {getCompetitionResults} from '../../../actions/log'
import Circle from '@mui/icons-material/Circle';

const MAX_NAME_LENGTH = 15;
const MAX_BIO_LENGTH = 15;

const SearchResult = ({ programId, result, inviteState }) => {
  const dispatch = useDispatch();

  const handleInvite = () => {
    dispatch(addInvitedUser(programId, result._id));
  };

  const handleUninvite = () => {
    dispatch(removeInvitedUser(programId, result._id));
  };

  return (
    <ListItem alignItems="flex-start" key={result._id}>
      <ListItemAvatar>
        <Avatar alt={result.username} src={result.profilePictureUrl}>{result.username?.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={result.username.length > MAX_NAME_LENGTH ? `${result.username.substring(0, MAX_NAME_LENGTH)}...` : result.username}
        secondary={result.bio.length > MAX_BIO_LENGTH ? `${result.bio.substring(0, MAX_BIO_LENGTH)}...` : result.bio}
      />
      {inviteState === "requested" &&
        <Button onClick={handleUninvite}>Uninvite</Button>
      }
      {inviteState === "none" &&
        <Button onClick={handleInvite}>Invite</Button>
      }
    </ListItem>            
  );
};


function CircleIconBackground({text}) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Circle
        sx={{
          position: 'absolute',
          fontSize: 50,  // Adjust size as needed
          color: 'rgba(0, 102, 255, .85)',  // Fire-like color with some transparency
        }}
      />
      <Typography variant="h6" sx={{ position: 'relative', color: '#fff' }}>
        {text}
      </Typography>
    </Box>
  );
}

const ProgramDetails = () => {
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [email, setEmail] = useState("");
  const [workout, setWorkout] = useState("");
  const [day, setDay] = useState("");
  const [publicBool, setPublicBool] = useState(false);
  const [exercise, setExercise] = useState("");
  const [formError, setFormError] = useState("");
  const [sortedResults, setSortedResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const searchResults = useSelector((state) => state.user.searchResults);
  const member = useSelector((state) => state.program.isMember);
  const hasLeft = useSelector((state) => state.program.hasLeft);
  const { program, members, invited, requests, schedule, missingWorkouts } = useSelector(
    (state) => state.program
  );
  const workoutBookmarks = useSelector(
    (state) => state.user.workoutBookmarksWithTitles
  );
  const exerciseBookmarks = useSelector(
    (state) => state.user.exerciseBookmarksWithTitles
  );
  const programWorkouts = useSelector(
    (state) => state.workout.workoutsFromArray
  );
  const programExercises = useSelector(
    (state) => state.program.exercises
  );
  const results = useSelector((state) => state.log.results);
  const users = useSelector((state) => state.log.users);

  const userMap = new Map();
  for (let i = 0; i < users.length; i++) {
    userMap.set(users[i]._id, users[i])
  }

  const dates = [
    [0, "Sunday"],
    [1, "Monday"],
    [2, "Tuesday"],
    [3, "Wednesday"],
    [4, "Thursday"],
    [5, "Friday"],
    [6, "Saturday"],
  ];

  let workoutAssign = [];
  if (programWorkouts?.length === program?.days?.length) {
    for (let i = 0; i < programWorkouts.length; i++) {
      workoutAssign.push({ workout: programWorkouts[i], days: program.days[i] });
    }
  }
  useEffect(() => {
    if (user != null) {
      dispatch(getProgramById(id));
      dispatch(getWorkoutBookmarksWithTitles());
      dispatch(getExerciseBookmarksWithTitles());
      dispatch(getMembers(id));
      dispatch(getInvitedUsers(id));
      dispatch(getRequestedUsers(id));
      dispatch(isMember(id));
      dispatch(getCompetitionResults(id));
    }
  }, []);

  useEffect(() => {
    if (user != null) {
      dispatch(searchUsers(email));
    }
  }, []);

  useEffect(() => {
    if (program) {
      dispatch(getWorkoutsByProgramId(id));
      dispatch(getExercisesByProgramId(id));
      setPublicBool(program?.public);
    }
  }, [program]);

  useEffect(() => {
    if (hasLeft) {
      navigate('/memberships')
    }
  }, [hasLeft]);

  useEffect(() => {
    if (results) {
      const tempResults = results.map(exercise => {
        return {top5Creators: (exercise.top5Creators.sort((a, b) => b.sum - a.sum))};
      });
      setSortedResults(tempResults);
    }
  }, [results]);
  
  const handleSubmit = (e) => {
    dispatch(searchUsers(email));
  };

  const handleWorkout = () => {
    if (workout !== "" && day !== "") {
      dispatch(scheduleWorkout(id, { workoutId: workout, day: day }));
    }
  };

  const handleExercise = () => {
    dispatch(addCompetition(id, exercise));
  };

  const toggle = () => {
    dispatch(updateVisibility(id));
  };

  const handleDelete = () => {
    dispatch(deleteProgram(id));
    navigate('/profile/' + user.result._id);
  };

  const handleLeave = () => {
    dispatch(leaveProgram(id));
  }

  const calculateInviteState = (id) => {
    if (id === user.result._id) {
      return "self";
    } else if (members.map((obj) => obj._id).includes(id)) {
      return "member";
    } else if (invited.map((obj) => obj._id).includes(id)) {
      return "requested";
    } else {
      return "none";
    }
  };

  const titleStyle = {
    margin: "0px 0px 10px",
    color: theme.palette.primary.main,
  };

  const sectionHeaderStyle = {
    color: theme.palette.primary.main,
  };

  const dividerStyle = {
    '&::before, &::after': {
      borderTopWidth: '4px',
    },
    marginTop: 2,
  };

  const descriptionStyle = {
    margin: "0px 0px 10px",
    color: theme.palette.text.secondary,
  };

  const headingStyle = {
    margin: "7px 0px 7px",
  };

  const inviteStyle = {
    borderRadius: "10px",
    width: "100%",
  };

  const workoutStyle = {
    width: "60%",
    margin: "5px 5px 0px 0px",
    color: "primary",
  };

  const assignStyle = {
    width: "30%",
    margin: "5px 5px 0px 0px",
    color: "primary",
  };

  const compStyle = {
    width: "90%",
    margin: "10px 10px 10px 0px",
    color: "primary",
  };

  const selectorsStyle = {
    justifyContent: "center",
    width: "85%",
    justifyContent: "space-between",
  };

  const paperStyle = {
    padding: theme.spacing(2),
    borderRadius: 15,
    backgroundColor: theme.palette.background.paper,
  };

  const flexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  };

  const styles = {
    deletedCompetitionCard: {
      border: 1,
      borderColor: 'gray',
      borderRadius: 3,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '300px',
      width: '250px',
    },
  };

  return (
    <>
      {program !== null && 
        <Container sx={{ mt: theme.spacing(1) }}>
          <Paper elevation={3} style={paperStyle} alignItems="center"> 
            <Box sx={flexStyle}>
              <Typography variant='h4' sx={titleStyle}> {program.title} </Typography>
              <Typography variant='h5' sx={descriptionStyle}> {program.description} </Typography>
              <Typography sx={{color: theme.palette.primary.main, mt: '10px'}}> Join Code: {program?.joinCode} </Typography>
              <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                <Typography sx={sectionHeaderStyle} variant="h5" align="center">
                  <Divider sx={dividerStyle}>Workout Schedule</Divider>
                </Typography>
                {user.result._id === program.creator._id &&
                  <Box display="flex" alignItems="center" flexDirection="column" width="100%" >
                    <Typography variant='h6' sx={headingStyle}> Add Workout </Typography>
                    <Stack direction='row' spacing={1} sx={selectorsStyle}>
                      <TextField size="small" value={workout} onChange={(e) => setWorkout(e.target.value)} select label='Workout' width="100px" sx={workoutStyle}>
                        {workoutBookmarks.map((workout, index) => (
                          <MenuItem key={index} value={workout._id}>
                            {workout.title}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField size="small" value={day} onChange={(e) => setDay(e.target.value)} select label='Assign day' sx={assignStyle}>
                        {dates.map((date, index) => (
                          <MenuItem key={index} value={date[0]}>
                            {date[1]}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Button variant="contained" onClick={handleWorkout}>Add</Button>
                    </Stack>
                  </Box>
                }

                <Box>
                  <CalendarGrid id={id} schedule={schedule} missingWorkouts={missingWorkouts} isAdmin={user.result._id === program.creator._id}/>
                </Box>

                <Typography sx={sectionHeaderStyle} variant="h5" align="center">
                  <Divider sx={dividerStyle}>Competitions</Divider>
                </Typography>
                
                {user.result._id === program.creator._id &&
                  <Box display="flex" alignItems="center" flexDirection="column" width="100%">
                    <Typography variant='h6' sx={headingStyle}>Add Competition</Typography>
                    <Stack direction='row' spacing={1} sx={selectorsStyle}>
                      <TextField size="small" value={exercise} onChange={(e) => setExercise(e.target.value)} select label='Choose exercise' sx={compStyle}>
                        {exerciseBookmarks?.map((exercise, index) => (
                          <MenuItem key={index} value={exercise._id}>
                            {exercise.title}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button size="small" variant="contained" onClick={handleExercise}>Add</Button>
                    </Stack>
                  </Box>
                } 

                <Box>
                  <Stack container spacing={2} sx={{ justifyContent: 'center'}}>
                    {programExercises.map((exercise, index) => 
                        <Box sx = {{display: isSmallScreen ? "grid" : "flex", placeItems: isSmallScreen ? 'center' : 'none', paddingLeft: isSmallScreen ? "0%" : "30%"}}>
                          {!exercise.deleted ? (
                            <>
                              <ExerciseCard item={exercise} programData={user.result._id === program.creator._id ? {exerciseId: exercise._id, programId: id} : null}/>
                              {
                                (sortedResults.length > index) &&
                                <Box sx={{marginLeft: isSmallScreen ? "0px" : "20px"}}>
                                  <Typography> Results </Typography>
                                  {sortedResults[index].top5Creators.map(obj => 
                                  <Box display="flex" alignItems="center">
                                  
                                    <ListItem alignItems="flex-start" key={userMap.get(obj.creator)._id}>
                                      <ListItemAvatar>
                                        <Avatar alt={userMap.get(obj.creator).username} src={userMap.get(obj.creator).profilePictureUrl}>{userMap.get(obj.creator).username?.charAt(0)}</Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={userMap.get(obj.creator).username.length > MAX_NAME_LENGTH ? `${userMap.get(obj.creator).username.substring(0, MAX_NAME_LENGTH)}...` : userMap.get(obj.creator).username}
                                        secondary={userMap.get(obj.creator).bio.length > MAX_BIO_LENGTH ? `${userMap.get(obj.creator).bio.substring(0, MAX_BIO_LENGTH)}...` : userMap.get(obj.creator).bio}
                                      />
                                    </ListItem> 
                                  
                                    <CircleIconBackground text={obj.sum}/>
                                  </Box>)}
                                </Box>
                              }
                            </>
                          ) : (
                            <Box key={index} sx={styles.deletedCompetitionCard}>
                              <Typography paddingTop='2.5%'>Deleted Exercise</Typography>
                              <WarningIcon />
                              {user.result._id === program.creator._id && (
                                <Button onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(removeCompetition(id, exercise._id));
                                }}>
                                  Discard
                                </Button>
                              )}
                            </Box>
                          )}
                        </Box>
                    )}
                  </Stack>
                </Box>

                {member && 
                <>
                  <Typography sx={sectionHeaderStyle} variant="h5" align="center">
                      <Divider sx={dividerStyle}>Upload Logs</Divider>
                  </Typography>
                  
                  <LogDisplay schedule={schedule} id = {id} isAdmin={user.result._id === program.creator._id}/>
                </>
                }

                {
                  member && 
                  <>
                  <Button onClick={handleLeave}>
                    Leave
                  </Button>
                  </>
                }

                {user.result._id === program.creator._id && 
                  <>
                    <Typography sx={sectionHeaderStyle} variant="h5" align="center">
                      <Divider sx={dividerStyle}>Roster</Divider>
                    </Typography>

                    <Stack direction="column" alignItems="center" spacing={1} width="100%">
                      <Box>
                        {members.map((obj, index) => <ProgramMember key={index} id={id} result={obj} />)}
                      </Box>

                      <Box display="flex" alignItems="center" flexDirection="column">
                        <Typography variant='h6' sx={headingStyle}>Invite Members</Typography>

                        <Stack direction='row' spacing={1} >
                          <TextField fullWidth variant="outlined" label="Search user..." size="small" onKeyDown={handleSubmit} value={email} onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="search" color="primary" onClick={handleSubmit}>
                                    <SendIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              sx: inviteStyle, 
                            }}
                          />
                        </Stack>

                        <List>
                          {searchResults.map((obj, index) => <SearchResult key={index} inviteState={calculateInviteState(obj._id)} result={obj} programId={id}/>)}
                        </List>
                      </Box>

                      {invited?.length > 0 &&
                        <Box display="flex" alignItems="center" flexDirection="column">
                          <Typography variant='h6' sx={headingStyle}>Invitations</Typography>
                          <List>
                            {invited.map((obj, index) => <ProgramInvitation key={index} id={id} result={obj} />)}
                          </List>
                        </Box> 
                      }    

                      {requests?.length > 0 &&
                        <Box display="flex" alignItems="center" flexDirection="column">
                          <Typography variant='h6' sx={headingStyle}>Join Requests</Typography>
                          <Box>
                            {requests.map((obj, index) => <ProgramJoinRequest key={index} id={id} result={obj}/>)}
                          </Box>
                        </Box>
                      }
                    </Stack>

                    <Typography sx={sectionHeaderStyle} variant="h5" align="center">
                      <Divider sx={dividerStyle}>Settings</Divider>
                    </Typography>
                
                    <Box sx={flexStyle}>
                      <Box sx={{ maxWidth: 'sm', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                        <Stack direction="row" sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: '20px' }}>
                            <Typography variant="body1">Public</Typography>
                            <Switch checked={!publicBool} onChange={toggle} />
                            <Typography variant="body1">Private</Typography>
                          </Stack>

                          <Box>
                            <Button variant="outlined" onClick={handleDelete} sx={{ mt: '20px' }}>
                              Delete Program
                            </Button>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </>
                }
              </Stack>
            </Box>

            <Box sx={{ width: '100%', mt: '10px' }}>
              <Collapse in={formError !== ""}>
                <Alert severity="error"
                  action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setFormError("");}}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {formError}
                </Alert>
              </Collapse>    
            </Box>
          </Paper>
        </Container>
      }
    </>
  ); 
}

export default ProgramDetails;