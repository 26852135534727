import * as api from '../api';
import {
  GET_HOMEPAGE_EXERCISES,
  GET_TRENDING_EXERCISES,
  GET_EXERCISES_BY_CREATOR,
  SEARCH_EXERCISES,
  GET_EXERCISES_FROM_ARRAY,
  GET_EXERCISE_BY_ID,
  GET_SUGGESTED_EXERCISES,
  CREATE_EXERCISE,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  LIKE_EXERCISE,
  INCREMENT_EXERCISE_RUNS,
  EXERCISE_ERROR,
  CLEAR_EXERCISE,
  CLEAR_EXERCISE_SEARCH_RESULTS,
} from '../constants/actionTypes';
import { appendFormData } from './util';

export const getHomepageExercises = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageExercises();
    dispatch({ type: GET_HOMEPAGE_EXERCISES, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const getHomepageExercisesForGuest = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageExercisesForGuest();
    dispatch({ type: GET_HOMEPAGE_EXERCISES, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const getTrendingExercises = (page = 1, limit = 7) => async (dispatch) => {
  try {
    const { data } = await api.getTrendingExercises(page, limit);
    dispatch({ type: GET_TRENDING_EXERCISES, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const getExercisesByCreator = (creatorId, page = 1, limit = 6) => async (dispatch) => {
  try {
    const { data } = await api.getExercisesByCreator(creatorId, page, limit);
    dispatch({ type: GET_EXERCISES_BY_CREATOR, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const searchExercises = (query, page = 1, limit = 10) => async (dispatch) => {
  try {
    const { data } = await api.searchExercises(query, page, limit);
    dispatch({ type: SEARCH_EXERCISES, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const clearSearchResults = () => ({
  type: CLEAR_EXERCISE_SEARCH_RESULTS,
});

export const getExercisesFromArray = (array) => async (dispatch) => {
  try {
    const { data } = await api.getExercisesFromArray(array);
    dispatch({ type: GET_EXERCISES_FROM_ARRAY, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const getExerciseById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getExerciseById(id);
    dispatch({ type: GET_EXERCISE_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const clearExercise = () => ({
  type: CLEAR_EXERCISE,
});

export const getSuggestedExercises = () => async (dispatch) => {
  try {
    const { data } = await api.getSuggestedExercises();
    dispatch({ type: GET_SUGGESTED_EXERCISES, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const createExercise = (exerciseData) => async (dispatch) => {
  try {
    const formData = new FormData();
    appendFormData(formData, exerciseData);
    const { data: responseData } = await api.createExercise(formData);
    dispatch({ type: CREATE_EXERCISE, payload: responseData });
    return responseData; // Return the data to handle the redirect in the component
  } catch (error) {
    console.error('Error in createExercise action:', error);
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const updateExercise = (id, data) => async (dispatch) => {
  try {
    const formData = new FormData();
    appendFormData(formData, data);
    const { data: responseData } = await api.updateExercise(id, formData);
    dispatch({ type: UPDATE_EXERCISE, payload: responseData });
    return responseData; // Return the data to handle the redirect
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const deleteExercise = (id) => async (dispatch) => {
  try {
    await api.deleteExercise(id);
    dispatch({ type: DELETE_EXERCISE, payload: id });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const likeExercise = (id) => async (dispatch) => {
  try {
    const { data } = await api.likeExercise(id);
    dispatch({ type: LIKE_EXERCISE, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};

export const incrementExerciseRuns = (id) => async (dispatch) => {
  try {
    const { data } = await api.incrementExerciseRuns(id);
    dispatch({ type: INCREMENT_EXERCISE_RUNS, payload: data });
  } catch (error) {
    dispatch({ type: EXERCISE_ERROR, payload: error.message });
  }
};