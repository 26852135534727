import React from 'react';
import { Container } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './components/Auth/Auth.js';
import VerifyEmail from './components/Auth/VerifyEmail.jsx'
import Forgot from './components/Auth/Forgot.js'
import RecoverEmail from './components/Auth/RecoverEmail.jsx';
import Navbar from './components/Navbar/Navbar.js';
import Home from './components/Home/Home.js';
import Logs from './components/Log/Logs.jsx';
import Bookmarks from './components/Bookmarks/Bookmarks.js';
import ProfilePage from './components/Profile/ProfilePage.jsx';
import Exercises from './components/Exercise/Exercises.js';
import ExerciseDetails from './components/Exercise/ExerciseDetails.jsx';
import Runner from './components/Exercise/ExerciseRunner.js';
import FormHelper from './components/Exercise/FormHelper.js';
import Workouts from './components/Workout/Workouts.js'
import WorkoutDetails from './components/Workout/WorkoutDetails.js';
import WorkoutHelper from './components/Workout/FormHelper.js';
import WorkoutRunner from './components/Workout/WorkoutRunner.js';
import FollowPage from './components/Social/followPage.js';
import ProgramBuilder from './components/Program/Admin/ProgramBuilder.js';
import ProgramDetails from './components/Program/Admin/ProgramDetails.js';
import ProgramSearch from './components/Program/Member/Programs.js';
import MemberPage from './components/Program/Member/MemberPage.js';
import VideoDetails from './components/Video/VideoDetails.js';
import Videos from './components/Video/Videos.js';
import ReportDetails from './components/Program/Admin/LogDisplay.js';
import PrivacyPolicy from './components/Legal/PrivacyPolicy.js';
import TermsOfService from './components/Legal/TermsOfService.js';

const App = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  // If user is not logged in, remove profile from localStorage to prevent auth header errors
  if (!user) localStorage.removeItem('profile'); 


  return (
    <BrowserRouter>
      <Container disableGutters maxWidth={false}>
        <Navbar />
        <div>
          <Routes>
            <Route path="/" exact element={<Navigate to="/home" />} />
            <Route path="/home" exact element={<Home/>} />

            <Route path="/auth" exact element={!user ? <Auth /> : <Navigate to="/home" />} />
            <Route path="/verify-email" exact element={<VerifyEmail />} />
            <Route path="/forgot" exact element={<Forgot />} />
            <Route path="/recover-email" exact element={<RecoverEmail /> } />
            <Route path='/profile/:id' exact element={<ProfilePage />} />
            
            <Route path="/logs" exact element={<Logs/>} />
            <Route path="/bookmarks" exact element={<Bookmarks/>} />
            <Route path="/social" exact element={<FollowPage/>} />

            <Route path="/exercise" exact element={<FormHelper/>} />
            <Route path="/exercise/:id" exact element={<FormHelper/>} />
            <Route path="/exercises" exact element={<Exercises/>} />
            <Route path="/exercises/:id" exact element={<ExerciseDetails />} />
            <Route path="/exercises/:id/run" exact element={<Runner/>} />

            <Route path="/workout" exact element={<WorkoutHelper/>} />
            <Route path="/workout/:id" exact element={<WorkoutHelper/>} />
            <Route path="/workouts" exact element={<Workouts/>} />
            <Route path="/workouts/:id" exact element={<WorkoutDetails />} />
            <Route path="/workouts/:id/run" exact element={<WorkoutRunner/>} />

            <Route path="/videos" exact element={<Videos/>} />
            <Route path="/videos/:id" exact element={<VideoDetails/>} />
            
            <Route path="/program" exact element={<ProgramBuilder/>} /> 
            <Route path="/memberships" exact element={<MemberPage/>} />
            <Route path="/programs/:id" exact element={<ProgramDetails/>}/>
            <Route path="/programs/:id/:memberId" exact element={<ReportDetails/>} />
            <Route path="/programs" exact element={<ProgramSearch/>} />

            <Route path="/privacy" exact element={<PrivacyPolicy/>} />
            <Route path="/terms" exact element={<TermsOfService/>} />
          </Routes>
        </div>
      </Container>
    </BrowserRouter>
  );
};

export default App;