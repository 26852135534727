import React from 'react';
import {
  getTrendingExercises,
  searchExercises,
  clearSearchResults,
} from '../../actions/exercise';
import { getExerciseBookmarksByCreator } from '../../actions/user';
import ExerciseCard from './ExerciseCard';
import ContentPage from '../Content/ContentPage';
import { selectTrendingExercises, selectSearchResults } from '../../reducers/exercise';

const ExerciseSearch = () => (
  <ContentPage
    title="Exercises"
    searchPlaceholder="Search Exercises..."
    fetchTrendingAction={getTrendingExercises}
    searchAction={searchExercises}
    clearSearchResultsAction={clearSearchResults}
    fetchBookmarksAction={getExerciseBookmarksByCreator}
    CardComponent={ExerciseCard}
    trendingItemsSelector={selectTrendingExercises}
    searchResultsSelector={selectSearchResults}
  />
);

export default ExerciseSearch;
