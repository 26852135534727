import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Form from './ExerciseBuilder';


const FormHelper = () => {
    const { id } = useParams();
    let correctedId = useRef(0);
    if (id != undefined) {
        correctedId.current = id;
    }

    const [currentId, setCurrentId] = useState(correctedId.current);
    return <Form currentId={currentId} setCurrentId={setCurrentId} />;
}

export default FormHelper;