import * as api from '../api';
import {
  IS_MEMBER,
  FETCH_PROGRAMS_BY_INVITED_USER,
  FETCH_PROGRAMS_BY_MEMBER,
  FETCH_PROGRAMS_BY_REQUESTED_USER,
  FETCH_SUGGESTED_PROGRAMS,
  GET_HOMEPAGE_PROGRAMS,
  GET_TRENDING_PROGRAMS,
  CREATE_PROGRAM,
  DELETE_PROGRAM,
  GET_MEMBERS,
  GET_INVITED_USERS,
  GET_REQUESTED_USERS,
  ADD_JOIN_REQUEST,
  REMOVE_JOIN_REQUEST,
  ADD_MEMBER,
  REMOVE_MEMBER,
  LEAVE_PROGRAM,
  DENY_JOIN_REQUEST,
  SCHEDULE_WORKOUT,
  REMOVE_WORKOUT,
  UNSCHEDULE_WORKOUT,
  ADD_COMPETITION,
  REMOVE_COMPETITION,
  UPDATE_VISIBILITY,
  ADD_MEMBER_BY_JOIN_CODE,
  ADD_INVITED_USER,
  REMOVE_INVITED_USER,
  ACCEPT_PROGRAM_INVITATION,
  FETCH_PROGRAMS_BY_CREATOR,
  FETCH_PROGRAM_BY_ID,
  SEARCH_PROGRAMS,
  PROGRAM_ERROR,
  GET_EXERCISES_BY_PROGRAM,
  CLEAR_PROGRAM_SEARCH_RESULTS,
  GET_WORKOUTS_BY_PROGRAM,
  JOIN_PROGRAM,
  CLEAR_JOIN_CODE_ERROR,
  JOIN_CODE_ERROR,
} from '../constants/actionTypes';
import { appendFormData } from './util';

export const isMember = (id) => async (dispatch) => {
  try {
    const { data } = await api.isMember(id);
    dispatch({ type: IS_MEMBER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getHomepagePrograms = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepagePrograms();
    dispatch({ type: GET_HOMEPAGE_PROGRAMS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getHomepageProgramsForGuest = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageProgramsForGuest();
    dispatch({ type: GET_HOMEPAGE_PROGRAMS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getProgramsByInvitedUser = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getProgramsByInvitedUser(page, limit);
    dispatch({ type: FETCH_PROGRAMS_BY_INVITED_USER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getProgramsByMember = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getProgramsByMember(page, limit);
    dispatch({ type: FETCH_PROGRAMS_BY_MEMBER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getProgramsByRequestedUser = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getProgramsByRequestedUser(page, limit);
    dispatch({ type: FETCH_PROGRAMS_BY_REQUESTED_USER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getTrendingPrograms = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getTrendingPrograms(page, limit);
    dispatch({ type: GET_TRENDING_PROGRAMS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getSuggestedPrograms = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getSuggestedPrograms(page, limit);
    dispatch({ type: FETCH_SUGGESTED_PROGRAMS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const createProgram = (programData) => async (dispatch) => {
  try {
    const formData = new FormData();
    appendFormData(formData, programData);
    const { data: responseData } = await api.createProgram(formData);
    await dispatch({ type: CREATE_PROGRAM, payload: responseData });
    return responseData;
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const deleteProgram = (id) => async (dispatch) => {
  try {
    await api.deleteProgram(id);
    dispatch({ type: DELETE_PROGRAM, payload: id });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const joinProgram = (id) => async (dispatch) => {
  try {
    const { data } = await api.joinProgram(id);
    dispatch({ type: JOIN_PROGRAM, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getMembers = (id) => async (dispatch) => {
  try {
    const { data } = await api.getMembers(id);
    dispatch({ type: GET_MEMBERS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getInvitedUsers = (id) => async (dispatch) => {
  try {
    const { data } = await api.getInvitedUsers(id);
    dispatch({ type: GET_INVITED_USERS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getRequestedUsers = (id) => async (dispatch) => {
  try {
    const { data } = await api.getRequestedUsers(id);
    dispatch({ type: GET_REQUESTED_USERS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const addJoinRequest = (id) => async (dispatch) => {
  try {
    const { data } = await api.addJoinRequest(id);
    dispatch({ type: ADD_JOIN_REQUEST, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const removeJoinRequest = (id) => async (dispatch) => {
  try {
    const { data } = await api.removeJoinRequest(id);
    dispatch({ type: REMOVE_JOIN_REQUEST, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const addMember = (id, userData) => async (dispatch) => {
  try {
    const { data } = await api.addMember(id, userData);
    dispatch({ type: ADD_MEMBER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const removeMember = (id, userData) => async (dispatch) => {
  try {
    const { data } = await api.removeMember(id, userData);
    dispatch({ type: REMOVE_MEMBER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const leaveProgram = (id) => async (dispatch) => {
  try {
    const { data } = await api.leaveProgram(id);
    dispatch({ type: LEAVE_PROGRAM, payload: data });
  } catch (error) {
    dispatch({ type: LEAVE_PROGRAM, payload: error.message });
  }
};

export const denyJoinRequest = (id, userData) => async (dispatch) => {
  try {
    const { data } = await api.denyJoinRequest(id, userData);
    dispatch({ type: DENY_JOIN_REQUEST, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const scheduleWorkout = (id, workoutData) => async (dispatch) => {
  try {
    const { data } = await api.scheduleWorkout(id, workoutData);
    dispatch({ type: SCHEDULE_WORKOUT, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const removeWorkout = (id, workoutData) => async (dispatch) => {
  try {
    const { data } = await api.removeWorkout(id, workoutData);
    dispatch({ type: REMOVE_WORKOUT, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const unscheduleWorkout = (id, workoutData) => async (dispatch) => {
  try {
    const { data } = await api.unscheduleWorkout(id, workoutData);
    dispatch({ type: UNSCHEDULE_WORKOUT, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const addCompetition = (id, competitionData) => async (dispatch) => {
  try {
    const { data } = await api.addCompetition(id, competitionData);
    dispatch({ type: ADD_COMPETITION, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const removeCompetition = (id, competitionData) => async (dispatch) => {
  try {
    const { data } = await api.removeCompetition(id, competitionData);
    dispatch({ type: REMOVE_COMPETITION, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const updateVisibility = (id) => async (dispatch) => {
  try {
    const { data } = await api.updateVisibility(id);
    dispatch({ type: UPDATE_VISIBILITY, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const addMemberByJoinCode = (joinCode) => async (dispatch) => {
  try {
    console.log('[action] joining with code: ', joinCode);
    const { data } = await api.addMemberByJoinCode(joinCode);
    dispatch({ type: ADD_MEMBER_BY_JOIN_CODE, payload: data });
  } catch (error) {
    dispatch({ type: JOIN_CODE_ERROR, payload: error.message });
  }
};

export const clearJoinCodeError = () => async (dispatch) => {
  dispatch({ type: CLEAR_JOIN_CODE_ERROR, payload: null });
};

export const addInvitedUser = (id, userData) => async (dispatch) => {
  try {
    const { data } = await api.addInvitedUser(id, userData);
    dispatch({ type: ADD_INVITED_USER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const removeInvitedUser = (id, userData) => async (dispatch) => {
  try {
    const { data } = await api.removeInvitedUser(id, userData);
    dispatch({ type: REMOVE_INVITED_USER, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const acceptProgramInvitation = (id) => async (dispatch) => {
  try {
    const { data } = await api.acceptProgramInvitation(id);
    dispatch({ type: ACCEPT_PROGRAM_INVITATION, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getProgramsByCreator = (creatorId, page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getProgramsByCreator(creatorId, page, limit);
    dispatch({ type: FETCH_PROGRAMS_BY_CREATOR, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getProgramById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getProgramById(id);
    dispatch({ type: FETCH_PROGRAM_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const searchPrograms = (query, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.searchPrograms(query, page, limit);
    dispatch({ type: SEARCH_PROGRAMS, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const clearSearchResults = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROGRAM_SEARCH_RESULTS });
};

export const getExercisesByProgramId = (id) => async (dispatch) => {
  try {
    const { data } = await api.getExercisesByProgramId(id);
    dispatch({ type: GET_EXERCISES_BY_PROGRAM, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};

export const getWorkoutsByProgramId = (id) => async (dispatch) => {
  try {
    const { data } = await api.getWorkoutsByProgramId(id);
    dispatch({ type: GET_WORKOUTS_BY_PROGRAM, payload: data });
  } catch (error) {
    dispatch({ type: PROGRAM_ERROR, payload: error.message });
  }
};