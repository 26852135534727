import { createSelector } from 'reselect';
import {
  GET_HOMEPAGE_EXERCISES,
  GET_TRENDING_EXERCISES,
  GET_EXERCISES_BY_CREATOR,
  SEARCH_EXERCISES,
  GET_EXERCISES_FROM_ARRAY,
  GET_EXERCISE_BY_ID,
  GET_SUGGESTED_EXERCISES,
  CREATE_EXERCISE,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  LIKE_EXERCISE,
  INCREMENT_EXERCISE_RUNS,
  EXERCISE_ERROR,
  CLEAR_EXERCISE,
  CLEAR_EXERCISE_SEARCH_RESULTS,
} from '../constants/actionTypes';
import { uniqBy } from 'lodash';

const initialState = {
  homepageExercises: [],
  trendingExercises: { data: [], currentPage: 1, totalPages: 1 },
  exercisesByCreator: { data: [], currentPage: 1, totalPages: 1 },
  searchResults: { data: [], currentPage: 1, totalPages: 1 },
  exercisesFromArray: [],
  exercise: null,
  suggestedExercises: { data: [], currentPage: 1, totalPages: 1 },
  error: null,
};

const updateLikesField = (exercises, payload) => {
  return exercises.map(exercise =>
    exercise._id === payload._id ? { ...exercise, likes: payload.likes } : exercise
  );
};

const updateRunsField = (exercises, payload) => {
  return exercises.map(exercise =>
    exercise._id === payload._id ? { ...exercise, runs: payload.runs } : exercise
  );
};

const exerciseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_EXERCISES:
      return { ...state, homepageExercises: action.payload.data, error: null };
    case GET_TRENDING_EXERCISES:
      return {
        ...state,
        trendingExercises: {
          data: uniqBy([...state.trendingExercises.data, ...action.payload.data], '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case GET_EXERCISES_BY_CREATOR:
      return {
        ...state,
        exercisesByCreator: {
          data: uniqBy(action.payload.data, '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case SEARCH_EXERCISES:
      return {
        ...state,
        searchResults: {
          data: uniqBy([...state.searchResults.data, ...action.payload.data], '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case CLEAR_EXERCISE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: { data: [], currentPage: 1, totalPages: 1 },
        error: null,
      };
    case GET_EXERCISES_FROM_ARRAY:
      return {
        ...state,
        exercisesFromArray: action.payload,
        error: null,
      };
    case GET_EXERCISE_BY_ID:
      return {
        ...state,
        exercise: action.payload,
        error: null,
      };
    case CLEAR_EXERCISE:
      return { ...state, exercise: null };
    case GET_SUGGESTED_EXERCISES:
      return {
        ...state,
        suggestedExercises: {
          data: uniqBy([...state.suggestedExercises.data, ...action.payload.data], '_id'),
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        error: null,
      };
    case CREATE_EXERCISE:
      return {
        ...state,
        trendingExercises: {
          ...state.trendingExercises,
          data: uniqBy([action.payload, ...state.trendingExercises.data], '_id'),
        },
        exercisesByCreator: {
          ...state.exercisesByCreator,
          data: uniqBy([action.payload, ...state.exercisesByCreator.data], '_id'),
        },
        searchResults: {
          ...state.searchResults,
          data: uniqBy([action.payload, ...state.searchResults.data], '_id'),
        },
        suggestedExercises: {
          ...state.suggestedExercises,
          data: uniqBy([action.payload, ...state.suggestedExercises.data], '_id'),
        },
        error: null,
      };
    case UPDATE_EXERCISE:
      return {
        ...state,
        trendingExercises: {
          ...state.trendingExercises,
          data: state.trendingExercises.data.map(exercise =>
            exercise._id === action.payload._id ? action.payload : exercise
          ),
        },
        exercisesByCreator: {
          ...state.exercisesByCreator,
          data: state.exercisesByCreator.data.map(exercise =>
            exercise._id === action.payload._id ? action.payload : exercise
          ),
        },
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map(exercise =>
            exercise._id === action.payload._id ? action.payload : exercise
          ),
        },
        suggestedExercises: {
          ...state.suggestedExercises,
          data: state.suggestedExercises.data.map(exercise =>
            exercise._id === action.payload._id ? action.payload : exercise
          ),
        },
        error: null,
      };
    case DELETE_EXERCISE:
      return {
        ...state,
        homepageExercises: state.homepageExercises.filter(exercise => exercise._id !== action.payload),
        trendingExercises: {
          ...state.trendingExercises,
          data: state.trendingExercises.data.filter(exercise => exercise._id !== action.payload),
        },
        exercisesByCreator: {
          ...state.exercisesByCreator,
          data: state.exercisesByCreator.data.filter(exercise => exercise._id !== action.payload),
        },
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.filter(exercise => exercise._id !== action.payload),
        },
        suggestedExercises: {
          ...state.suggestedExercises,
          data: state.suggestedExercises.data.filter(exercise => exercise._id !== action.payload),
        },
        error: null,
      };
    case LIKE_EXERCISE:
      return {
        ...state,
        trendingExercises: {
          ...state.trendingExercises,
          data: updateLikesField(state.trendingExercises.data, action.payload),
        },
        exercisesByCreator: {
          ...state.exercisesByCreator,
          data: updateLikesField(state.exercisesByCreator.data, action.payload),
        },
        searchResults: {
          ...state.searchResults,
          data: updateLikesField(state.searchResults.data, action.payload),
        },
        suggestedExercises: {
          ...state.suggestedExercises,
          data: updateLikesField(state.suggestedExercises.data, action.payload),
        },
        error: null,
      };
    case INCREMENT_EXERCISE_RUNS:
      return {
        ...state,
        trendingExercises: {
          ...state.trendingExercises,
          data: updateRunsField(state.trendingExercises.data, action.payload),
        },
        exercisesByCreator: {
          ...state.exercisesByCreator,
          data: updateRunsField(state.exercisesByCreator.data, action.payload),
        },
        searchResults: {
          ...state.searchResults,
          data: updateRunsField(state.searchResults.data, action.payload),
        },
        suggestedExercises: {
          ...state.suggestedExercises,
          data: updateRunsField(state.suggestedExercises.data, action.payload),
        },
        error: null,
      };
    case EXERCISE_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default exerciseReducer;

// Selectors
export const selectTrendingExercises = createSelector(
  state => state.exercise.trendingExercises,
  trendingExercises => ({ ...trendingExercises })
);

export const selectSearchResults = createSelector(
  state => state.exercise.searchResults,
  searchResults => ({ ...searchResults })
);
