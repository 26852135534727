import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Paper, Typography, Switch, Stack, Divider, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteLog, updateLogVisibility } from '../../actions/log';
import CloseIcon from '@mui/icons-material/Close';
import { removeLogFromProgram } from '../../actions/log'

const Log = ({ data, creator, programId = null}) => {
  const [publicBool, setPublicBool] = useState(data.visibility === 'public');
  const indexes = [...Array(data.reps.length).keys()];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deletionOfLog = () => {
    dispatch(deleteLog(data._id));
  };

  const toggle = () => {
    dispatch(updateLogVisibility(data._id, publicBool ? 'private' : 'public'));
    setPublicBool(!publicBool);
  };

  const handleRemove = () => {
    dispatch(removeLogFromProgram(data._id, programId))
  }
  return (
    <Paper style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '10px', borderRadius: '15px', margin: '10px 0px' }}>
      <Stack direction="column" spacing={1} sx={{ display: 'flex', justifyContent: 'center', width: '80%' }}>
        <Divider>{data.creator.username}</Divider>

        {indexes.map((index) => (
          <Stack direction="row" spacing={1} key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography key={index}>{data.reps[index]} {data.exerciseNames[index]} </Typography>
            {data.videos && data.videos[index] && (
              <Button size="small" startIcon={<SlideshowIcon />} color="primary" onClick={() => navigate(`/videos/${data.videos[index]}`)}>
                Video
              </Button>
            )}
          </Stack>
        ))}

        <Divider>{moment(data.createdAt).fromNow()}</Divider>

        {data.creator._id === creator && (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1">Public</Typography>
            <Switch checked={publicBool} onChange={toggle} />
            <Typography variant="body1">Private</Typography>
            <IconButton size="small" color="secondary" onClick={deletionOfLog}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
      {programId &&
        <Button 
        startIcon={<CloseIcon/>}
        onClick={handleRemove}>
      </Button>
      }
    </Paper>
  );
};

export default Log;
