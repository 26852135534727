import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography, Divider, Card, CardMedia, Grid, IconButton, Container } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import CommentSection from '../Comment/CommentSection';

const Details = ({ type, fetchItemById, incrementRuns }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const item = useSelector((state) => state[type][type]);

  useEffect(() => {
    dispatch(fetchItemById(id));
  }, [id, dispatch, fetchItemById]);

  if (!item) return null;

  const runItem = (_id) => {
    dispatch(incrementRuns(id));
    navigate(`/${type}s/${id}/run`);
  };

  const RunButton = () => {
    return (
      <IconButton
        onClick={runItem}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        variant="contained"
      >
        <PlayCircleOutlineIcon color='primary' sx={{
          minWidth: 50,
          minHeight: 50,
        }}/>
      </IconButton>
    );
  };

  return (
    <Container maxWidth="lg">
      <Paper style={{ padding: '30px', borderRadius: '10px', marginTop: '10px', marginBottom: '10px' }} elevation={6}>
        <div>
          <Typography variant="h3" color="primary" component="h2" sx={{ wordBreak: 'break-word' }}>{item.title}</Typography>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Created by
            <Link to={`/profile/${item.creator._id}`} style={{ textDecoration: 'none', color: `${theme.palette.primary.main}` }}>
              {` ${item.creator.username}`}
            </Link>
          </Typography>
          <Typography variant="body1" color={theme.palette.text.secondary}>{moment(item.createdAt).fromNow()}</Typography>

          <Divider style={{ margin: '20px 0px' }} />

          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} md={6} sm={8}>
              <Card elevation={0} style={{ position: "relative", maxWidth: 500, maxHeight: 400 }}>
                <CardMedia
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    height: 300, // Set a fixed height
                    backgroundColor: item.thumbnailUrl ? 'transparent' : theme.palette.grey[400], // Fallback background color
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  component="div" // Use div to allow for background color if no image is present
                >
                  {item.thumbnailUrl ? (
                    <img
                      src={item.thumbnailUrl}
                      alt="run"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  ) : (
                    <Typography variant="h6" color="textSecondary">
                      No Image Available
                    </Typography>
                  )}
                  <RunButton />
                </CardMedia>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sm={8}>
              <Typography gutterBottom variant="body1" component="p" sx={{ wordBreak: 'break-word' }}>{item.description}</Typography>
            </Grid>
          </Grid>

          <Divider style={{ margin: '20px 0' }} />
          <CommentSection type={type} id={item._id} />
        </div>
      </Paper>
    </Container>
  );
};

export default Details;
