import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { 
  DARK_GRAY, 
  GRAY,
  LIGHT_BLUE,
  DARK_BLUE,
  CYAN,
  LIGHT_GRAY
} from './constants/colors';


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
      mode: 'dark',
      primary: {
        main: LIGHT_BLUE,
      },
      secondary: {
        main: DARK_BLUE,
      },
      background: {
        default: '#000000',
        paper: DARK_GRAY,
      },
      text: {
        main: '#ffffff',
        primary: '#ffffff',
        secondary: GRAY,
      },
      subtext: {
        default: LIGHT_GRAY
      },
      navbar: {
        main: DARK_GRAY + '99',
      },
  },
  typography: {
      fontFamily: 'Lato',
  },
});


export default responsiveFontSizes(theme);