import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { unfollowUser } from '../../actions/user'


const MAX_NAME_LENGTH = 15;
const MAX_BIO_LENGTH = 15;

const FollowingList = ({ setFollowingCount, following }) => {
    const dispatch = useDispatch();

    const handleDelete = (id) => {
        dispatch(unfollowUser(id));
    }



    useEffect(() => {
        setFollowingCount(following.length);
    }, [following.length]);
    return (
        <List>
            {following.map((index) => (
                <ListItem alignItems="flex-start" key={index._id}>
                    <ListItemAvatar>
                        <Avatar alt={index.username} src={index.profilePictureUrl}>{index.username?.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={index.username.length > MAX_NAME_LENGTH ? `${index.username.substring(0, MAX_NAME_LENGTH)}...` : index.username}
                        secondary={index.bio.length > MAX_BIO_LENGTH ? `${index.bio.substring(0, MAX_BIO_LENGTH)}...` : index.bio}
                    />
                    <IconButton sx={{ mt: '6px' }} aria-label="delete" onClick={() => handleDelete(index._id)}>
                        <ClearIcon />
                    </IconButton>
                </ListItem>
            ))}
        </List>
    );
}

export default FollowingList;