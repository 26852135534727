import * as api from '../api';
import { FETCH_STATS_BY_CREATOR, CREATE_STATS, INCREMENT_STATS } from '../constants/actionTypes';

// Fetch stats by creator
export const getStatsByCreator = (creatorId) => async (dispatch) => {
  try {
    const { data } = await api.getStatsByCreator(creatorId);
    dispatch({ type: FETCH_STATS_BY_CREATOR, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

// Create stats
export const createStats = () => async (dispatch) => {
  try {
    const { data } = await api.createStats();
    dispatch({ type: CREATE_STATS, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

// Increment stats
export const incrementStats = (arr) => async (dispatch) => {
  try {
    const { data } = await api.incrementStats({ arr });
    dispatch({ type: INCREMENT_STATS, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};