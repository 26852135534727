import {
  FETCH_COMMENTS_BY_EXERCISE,
  FETCH_COMMENTS_BY_WORKOUT,
  FETCH_COMMENTS_BY_VIDEO,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  FETCH_COMMENT_BY_ID,
} from '../constants/actionTypes';

const initialState = {
  exerciseComments: { comments: [], totalComments: 0 },
  workoutComments: { comments: [], totalComments: 0 },
  videoComments: { comments: [], totalComments: 0 },
  comment: null,
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS_BY_EXERCISE:
      return { ...state, exerciseComments: action.payload };
    case FETCH_COMMENTS_BY_WORKOUT:
      return { ...state, workoutComments: action.payload };
    case FETCH_COMMENTS_BY_VIDEO:
      return { ...state, videoComments: action.payload };
    case CREATE_COMMENT:
      return {
        ...state,
        exerciseComments: {
          ...state.exerciseComments,
          comments: [...state.exerciseComments.comments, action.payload],
          totalComments: state.exerciseComments.totalComments + 1,
        },
        workoutComments: {
          ...state.workoutComments,
          comments: [...state.workoutComments.comments, action.payload],
          totalComments: state.workoutComments.totalComments + 1,
        },
        videoComments: {
          ...state.videoComments,
          comments: [...state.videoComments.comments, action.payload],
          totalComments: state.videoComments.totalComments + 1,
        },
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        exerciseComments: {
          ...state.exerciseComments,
          comments: state.exerciseComments.comments.map((comment) =>
            comment._id === action.payload._id ? action.payload : comment
          ),
        },
        workoutComments: {
          ...state.workoutComments,
          comments: state.workoutComments.comments.map((comment) =>
            comment._id === action.payload._id ? action.payload : comment
          ),
        },
        videoComments: {
          ...state.videoComments,
          comments: state.videoComments.comments.map((comment) =>
            comment._id === action.payload._id ? action.payload : comment
          ),
        },
      };
    case DELETE_COMMENT:
      return {
        ...state,
        exerciseComments: {
          ...state.exerciseComments,
          comments: state.exerciseComments.comments.filter((comment) => comment._id !== action.payload),
          totalComments: state.exerciseComments.totalComments - 1,
        },
        workoutComments: {
          ...state.workoutComments,
          comments: state.workoutComments.comments.filter((comment) => comment._id !== action.payload),
          totalComments: state.workoutComments.totalComments - 1,
        },
        videoComments: {
          ...state.videoComments,
          comments: state.videoComments.comments.filter((comment) => comment._id !== action.payload),
          totalComments: state.videoComments.totalComments - 1,
        },
      };
    case FETCH_COMMENT_BY_ID:
      return { ...state, comment: action.payload };
    default:
      return state;
  }
};

export default commentsReducer;
