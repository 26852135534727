import * as api from '../api';
import {
  GET_HOMEPAGE_WORKOUTS,
  GET_TRENDING_WORKOUTS,
  GET_WORKOUTS_BY_CREATOR,
  SEARCH_WORKOUTS,
  GET_WORKOUTS_FROM_ARRAY,
  GET_WORKOUT_BY_ID,
  GET_SUGGESTED_WORKOUTS,
  CREATE_WORKOUT,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  LIKE_WORKOUT,
  INCREMENT_WORKOUT_RUNS,
  WORKOUT_ERROR,
  CLEAR_WORKOUT,
  CLEAR_WORKOUT_SEARCH_RESULTS,
} from '../constants/actionTypes';
import { appendFormData } from './util';

export const getHomepageWorkouts = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageWorkouts();
    dispatch({ type: GET_HOMEPAGE_WORKOUTS, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const getHomepageWorkoutsForGuest = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageWorkoutsForGuest();
    dispatch({ type: GET_HOMEPAGE_WORKOUTS, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const getTrendingWorkouts = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getTrendingWorkouts(page, limit);
    dispatch({ type: GET_TRENDING_WORKOUTS, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const getWorkoutsByCreator = (creatorId, page = 1, limit = 6) => async (dispatch) => {
  try {
    const { data } = await api.getWorkoutsByCreator(creatorId, page, limit);
    dispatch({ type: GET_WORKOUTS_BY_CREATOR, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const searchWorkouts = (query, page = 1, limit = 10) => async (dispatch) => {
  try {
    const { data } = await api.searchWorkouts(query, page, limit);
    dispatch({ type: SEARCH_WORKOUTS, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const clearSearchResults = () => ({
  type: CLEAR_WORKOUT_SEARCH_RESULTS,
});

export const getWorkoutsFromArray = (array) => async (dispatch) => {
  try {
    const { data } = await api.getWorkoutsFromArray(array);
    dispatch({ type: GET_WORKOUTS_FROM_ARRAY, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const getWorkoutById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getWorkoutById(id);
    dispatch({ type: GET_WORKOUT_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const clearWorkout = () => ({
  type: CLEAR_WORKOUT,
});

export const getSuggestedWorkouts = (page = 1, limit = 4) => async (dispatch) => {
  try {
    const { data } = await api.getSuggestedWorkouts(page, limit);
    dispatch({ type: GET_SUGGESTED_WORKOUTS, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const createWorkout = (workoutData) => async (dispatch) => {
  try {
    const formData = new FormData();
    appendFormData(formData, workoutData);
    const { data: responseData } = await api.createWorkout(formData);
    dispatch({ type: CREATE_WORKOUT, payload: responseData });
    return responseData; // Return the data to handle the redirect in the component
  } catch (error) {
    console.error('Error in createExercise action:', error);
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const updateWorkout = (id, data) => async (dispatch) => {
  try {
    const formData = new FormData();
    appendFormData(formData, data);
    const { data: responseData } = await api.updateWorkout(id, formData);
    dispatch({ type: UPDATE_WORKOUT, payload: responseData });
    return responseData; // Return the data to handle the redirect
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const deleteWorkout = (id) => async (dispatch) => {
  try {
    await api.deleteWorkout(id);
    dispatch({ type: DELETE_WORKOUT, payload: id });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const likeWorkout = (id) => async (dispatch) => {
  try {
    const { data } = await api.likeWorkout(id);
    dispatch({ type: LIKE_WORKOUT, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};

export const incrementWorkoutRuns = (id) => async (dispatch) => {
  try {
    const { data } = await api.incrementWorkoutRuns(id);
    dispatch({ type: INCREMENT_WORKOUT_RUNS, payload: data });
  } catch (error) {
    dispatch({ type: WORKOUT_ERROR, payload: error.message });
  }
};