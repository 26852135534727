import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  ButtonBase,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Tooltip,
  Icon
} from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpAltOutlined from '@mui/icons-material/ThumbUpAltOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import moment from 'moment';
import Transition from '../Transition/Transition';
import defaultThumbnail from '../../images/default_thumbnail.jpg';


const GenericCard = ({
  item,
  onLike,
  onBookmark,
  onDelete,
  onEdit,
  onRemove,
  onManage,
  onJoin,
  onAccept,
  onWithdraw,
  isBookmarked,
  isLiked,
  isMobile,
  programData,
}) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const theme = useTheme();
  const navigate = useNavigate();

  const [likes, setLikes] = useState(item?.likes || []);
  const userId = user?.result?._id;
  const hasLiked = useMemo(() => likes.includes(userId), [likes, userId]);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handleConfirm = () => {
    onDelete(item._id);
    handleCloseConfirmation();
  };

  const handleLike = () => {
    onLike(item._id);
    if (hasLiked) {
      setLikes(likes.filter((id) => id !== userId));
    } else {
      setLikes([...likes, userId]);
    }
  };

  const handleRemove = () => {
    onRemove();
  }
  const handleBookmark = () => {
    onBookmark(item._id);
  };

  const RenderBookmark = () => {
    return isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />;
  };

  const Likes = () => {
    if (likes.length > 0) {
      return likes.includes(userId) ? (
        <>
          <ThumbUpAltIcon fontSize="small" /> &nbsp;{likes.length}
        </>
      ) : (
        <>
          <ThumbUpAltOutlined fontSize="small" /> &nbsp;{likes.length}
        </>
      );
    }
    return <ThumbUpAltOutlined fontSize="small" />;
  };

  const openItem = () => {
    navigate(`/${item.type}s/${item._id}`);
  };

  const classes = {
    media: {
      height: 0,
      paddingTop: '56.25%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backgroundBlendMode: 'darken',
    },
    border: {
      border: 'solid',
    },
    fullHeightCard: {
      height: '100%',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '7px',
      border: '1px solid gray',
      height: isMobile ? '350px' : '300px', // Fixed height, bigger for small screens
      width: isMobile ? '300px' : '250px', // Fixed width
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        borderColor: 'white',
        '.overlay': {
          color: theme.palette.text.primary,
        },
      },
    },
    overlay: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      color: theme.palette.text.secondary,
    },
    grid: {
      display: 'flex',
    },
    details: {
      justifyContent: 'space-between',
      margin: '0px 5px 0px 5px',
      color: theme.palette.text.secondary,
      wordBreak: 'break-word',
      maxWidth: '100%',
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2, // Number of lines to show before truncating
    },
    title: {
      padding: '10px 14px 0px',
      color: theme.palette.primary.main,
      maxWidth: '100%', // Set the maximum width to the parent container's width or any specific width
      overflow: 'hidden', // Hide any overflow text
      textOverflow: 'ellipsis', // Add ellipsis at the end of the text if it overflows
      whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
    },
    cardActions: {
      padding: '0 16px 8px 16px',
      display: 'flex',
      justifyContent: 'right',
      color: theme.palette.primary.main,
    },
    cardAction: {
      display: 'block',
      textAlign: 'initial',
    },
  };

  return (
    <Card sx={classes.card} raised elevation={3}>
      <ButtonBase component="span" sx={classes.cardAction} onClick={openItem}>
        <CardMedia
          sx={classes.media}
          image={item?.thumbnailUrl || defaultThumbnail}
          title={item.title}
        />
        <div style={classes.overlay}>
          <Typography variant="h6">{item.creator?.username}</Typography>
          <Typography variant="body2">{moment(item.createdAt).fromNow()}</Typography>
        </div>
        <Typography sx={classes.title} variant="h5" component="h2">
          {item.title}
        </Typography>
        <CardContent>
          <Typography
            sx={classes.details}
            variant="body2"
            component="p"
          >
            {item.description} {'\u200B'}
          </Typography>
        </CardContent>
      </ButtonBase>

      <CardActions sx={classes.cardActions}>
        {programData !== null && (
          <Button onClick={(e) => {
            handleRemove();
            e.stopPropagation();
          }} variant="outlined">
            Remove
          </Button>      
        )}

        {user?.result?._id === item?.creator?._id && onManage && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onManage(item._id);
            }}
            variant="outlined"
          >
            Manage
          </Button>
        )}

        { onJoin && 
          user?.result?._id !== item?.creator?._id &&
          !(item?.members?.includes(user?.result._id)) && 
          !(item?.invitedUsers?.includes(user?.result._id)) &&
          !(item?.joinRequests?.includes(user?.result._id)) &&
          (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onJoin(item._id);
            }}
            variant="outlined"
            disabled={!user}
          >
            Join
          </Button>
        )} 

        { onAccept && 
          user?.result?._id !== item?.creator?._id &&
          !(item?.members?.includes(user?.result._id)) && 
          (item?.invitedUsers?.includes(user?.result._id)) &&
          !(item?.joinRequests?.includes(user?.result._id)) &&
          (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onAccept(item._id);
            }}
            variant="outlined"
            disabled={!user}
          >
            Accept
          </Button>
          
        )} 

        { onWithdraw && 
          user?.result?._id !== item?.creator?._id &&
          !(item?.members?.includes(user?.result._id)) && 
          !(item?.invitedUsers?.includes(user?.result._id)) &&
          (item?.joinRequests?.includes(user?.result._id)) &&
          (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onWithdraw(item._id);
            }}
            variant="outlined"
            disabled={!user}
          >
            Withdraw Request
          </Button>
          
        )}  

        {item.missingExercises && (
          <Tooltip title="An exercise in this workout has been deleted. Please contact the owner to update it.">
            <div>
              <IconButton size="small" color="secondary" disabled>
                <ReportProblemIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
        
        {user?.result?._id === item?.creator?._id && (
          <>
            {onEdit !== null && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(item._id);
                }}
                color="primary"
                size="small"
              >
                <EditIcon />
              </IconButton>
            )}

            {programData == null && (
              <>
                <IconButton size="small" color="primary" onClick={handleOpenConfirmation}>
                  <DeleteIcon />
                </IconButton>
                <Dialog
                  open={openConfirmation}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseConfirmation}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>{`Confirm ${item.type} Deletion`}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Are you sure you want to delete this {item.type} permanently?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button sx={{ color: 'text.primary' }} onClick={handleCloseConfirmation}>
                      Cancel
                    </Button>
                    <Button sx={{ color: 'text.primary' }} onClick={handleConfirm}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        )}
        
        {programData == null && (item.type === 'exercise' || item.type === 'workout') &&
          <IconButton size="small" color="primary" disabled={!user?.result} onClick={handleBookmark}>
            <RenderBookmark />
          </IconButton>
        }

        {(item.type !== 'program') && (
          <IconButton size="small" color="primary" disabled={!user?.result} onClick={handleLike}>
            <Likes />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default GenericCard;
