import React from 'react';
import { getWorkoutById, incrementWorkoutRuns } from '../../actions/workout';
import Details from '../Content/Details';

const WorkoutDetails = () => {
  return (
    <Details 
      type="workout" 
      fetchItemById={getWorkoutById} 
      incrementRuns={incrementWorkoutRuns} 
    />
  );
};

export default WorkoutDetails;