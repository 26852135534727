import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import WorkoutCard from '../../Workout/WorkoutCard';
import RestDayIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import WarningIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { unscheduleWorkout } from '../../../actions/program';

const CalendarGrid = ({ id, schedule, missingWorkouts, isAdmin }) => {
  let dates = [
    [0, 'Sunday'],
    [1, 'Monday'],
    [2, 'Tuesday'],
    [3, 'Wednesday'],
    [4, 'Thursday'],
    [5, 'Friday'],
    [6, 'Saturday'],
  ];

  if (!isAdmin) {
      const today = new Date().getDay(); // Get the current day of the week as a number (0-6)
      const startIndex = dates.findIndex(date => date[0] === today);
      dates = [...dates.slice(startIndex), ...dates.slice(0, startIndex)];
  }

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleRemoveWorkout = async (programData) => {
    if (programData.workoutId != null && programData.day != null) {
      await dispatch(unscheduleWorkout(programData.programId, {workoutId: programData.workoutId, day: programData.day}));
    }
  };

  const countNullsBeforeIndex = (array, index) => {
    let count = 0;
    for (let i = 0; i < index; i++) {
      if (array[i] == null) {
        count++;
      }
    }
    return count;
  };

  const styles = {
    restDayCard: {
      border: 1,
      borderColor: 'gray',
      borderRadius: 3,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '300px',
      width: '250px',
    },
    calendarDayCard: {
      padding: '16px',
      textAlign: 'center',
      height: '350px',
      width: '260px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  };

  return (
    <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
      {!isAdmin && (
      <Grid item key={0} sx={{ minWidth: '300px' }}>
        <Paper elevation={8} sx={styles.calendarDayCard}>
              <Typography variant="h6">Today</Typography>
              <Stack marginTop="5%" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {schedule[dates[0][0]].length > 0 ? (
                  <Stack spacing={2}>
                    {schedule[dates[0][0]].map((workout) => (workout &&
                      <Box key={workout?._id} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <WorkoutCard item={workout} programData={isAdmin ? { programId: id, workoutId: workout?._id, day: 0 } : null} />
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Box sx={styles.restDayCard}>
                    <Typography paddingTop='2.5%'>Rest day</Typography>
                    <RestDayIcon />
                  </Box>
                )}
              </Stack>
        </Paper>
      </Grid>)}
      {dates.slice(isAdmin ? 0 : 1, 7).map((date, index) => (
        <Grid item key={index} sx={{ minWidth: '300px' }}>
          <Paper elevation={8} sx={styles.calendarDayCard}>
            <Typography variant="h6">{date[1]}</Typography>
            <Stack marginTop="5%" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {schedule[date[0]]?.length > 0 ? (
                <Stack spacing={2}>
                  {schedule[date[0]].map((workout, index) => (workout ? (
                    <Box key={workout?._id} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <WorkoutCard item={workout} programData={isAdmin ? { programId: id, workoutId: workout?._id, day: date[0] } : null} />
                    </Box>
                  ) : (
                    <Box key={index} sx={styles.restDayCard}>
                      <Typography paddingTop='2.5%'>Deleted Workout</Typography>
                      <WarningIcon />
                      {isAdmin && (
                        <Button onClick={(e) => {
                          e.preventDefault();
                          // the missingWorkouts array only includes the workout IDs that are missing from the schedule
                          const missingWorkoutIndex = countNullsBeforeIndex(schedule[date[0]], index);
                          handleRemoveWorkout({ programId: id, workoutId: missingWorkouts[date[0]][missingWorkoutIndex], day: date[0] });
                        }}>
                          Discard
                        </Button>
                      )}
                    </Box>
                  )))}
                </Stack>
              ) : (
                <Box sx={styles.restDayCard}>
                  <Typography paddingTop='2.5%'>Rest day</Typography>
                  <RestDayIcon />
                </Box>
              )}
            </Stack>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default CalendarGrid;
