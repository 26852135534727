import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import WorkoutBuilder from './WorkoutBuilder';


const WorkoutHelper = () => {
    const { id } = useParams();
    let correctedId = useRef(0);
    
    if (id != undefined) {
        correctedId.current = id;
    }
    
    const [currentId, setCurrentId] = useState(correctedId.current);
    return <WorkoutBuilder currentId={currentId} setCurrentId={setCurrentId} />;
};

export default WorkoutHelper;