import React from 'react';
import { getHomepageVideos, getHomepageVideosForGuest } from '../../actions/video';
import VideoCard from './VideoCard';
import GenericHomeContent from '../Content/HomeContent';

const HomeVideos = () => {
  const user = JSON.parse(localStorage.getItem('profile'));

  const fetchDataAction = user ? getHomepageVideos : getHomepageVideosForGuest;

  const reduxStateSelector = (state) => state.video.homepageVideos;

  return (
    <GenericHomeContent
      title="Videos"
      fetchDataAction={fetchDataAction}
      fetchGuestDataAction={getHomepageVideosForGuest}
      CardComponent={VideoCard}
      navigatePath="/videos"
      reduxStateSelector={reduxStateSelector}
    />
  );
};

export default HomeVideos;
