import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Paper, Typography, Divider, Container, Box, Button, Grid } from '@mui/material';
import {getFollowersByUserId, getFollowingByUserId, getIncomingByUserId, getOutgoingByUserId} from '../../actions/user'
import { useTheme } from '@mui/material/styles';
import FollowForm from './followForm';
import RequestsList from './requestsList';
import FollowerList from './followerList';
import FollowingList from './followingList';
// import VideoFeed from '../Video/VideoFeed'


const FollowPage = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const theme = useTheme();
  const dispatch = useDispatch();
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [next, setNext] = useState(0);
  const [prev, setPrev] = useState(0);
  
  const followers = useSelector((state) => state.user.followers)
  const following = useSelector((state) => state.user.following)
  const incoming = useSelector((state) => state.user.incomingRequests)
  const outgoing = useSelector((state) => state.user.outgoingRequests)
  useEffect(() => {
    if (user != null) {
        dispatch(getFollowersByUserId(user.result._id));
        dispatch(getFollowingByUserId(user.result._id));
        dispatch(getIncomingByUserId(user.result._id));
        dispatch(getOutgoingByUserId(user.result._id));
    }
  }, []);

  const paperStyle = { 
    padding: '20px', 
    margin: "10px auto",
    minHeight: '350px',
    maxHeight: '350px', 
    overflow: 'auto',
    border: '2px solid',
    borderColor: theme.palette.text.secondary,
  };

  const feedStyle = { 
    padding: '20px', 
    minHeight: '550px',
    maxHeight: '550px', 
    alignItems: 'center',
    border: '2px solid',
    borderColor: theme.palette.text.secondary,
  };

  const videoStyle = { 
    minHeight: '520px',
    maxHeight: '520px', 
    overflow: 'auto',
    borderColor: theme.palette.text.secondary,
  };

  const headingStyle = {
    margin: '0px 0px 10px',
    color: theme.palette.primary.main,
    textAlign: 'center'
  };

  return (
    <>
      { user == null &&
        <Paper elevation={6}>
          <Typography variant="h6" align="center">
            Please Sign In
          </Typography>
        </Paper>
      }
      { user != null &&
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
          <Grid item style={{margin: 'auto'}}  xs={12} sm={12} md={4} lg={4}>
            <Paper elevation={3} sx={paperStyle}>
              <Typography variant="h5" sx={headingStyle}>
                Followers ({followerCount})
              </Typography>
              <FollowerList setFollowerCount={setFollowerCount} followers = {followers}/>
            </Paper>
          </Grid>
          <Grid item style={{margin: 'auto'}}  xs={12} sm={12} md={4} lg={4}>
            <Paper elevation={3} sx={paperStyle}>
              <Typography variant="h5" sx={headingStyle}>
                Following ({followingCount})
              </Typography>
              <FollowingList setFollowingCount={setFollowingCount} following={following}/>
            </Paper>
          </Grid>
          <Grid item style={{margin: 'auto'}}  xs={12} sm={12} md={4} lg={4}>
            <Paper elevation={3} sx={paperStyle}>
              <Typography variant="h5" sx={headingStyle}>
                Requests
              </Typography>
              <FollowForm following={following} outgoing={outgoing} user={user}/>
              <Divider sx={{m: '15px 0px'}}/>
              <RequestsList incoming={incoming} outgoing={outgoing}/>
              
              
            </Paper>
          </Grid>
          {/* <Grid item style={{margin: 'auto'}}  xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={3} sx={feedStyle} alignItems="center">
              <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center" 
                width="100%" 
                padding="20 20px"
              >
                <Button variant="contained" onClick={()=>{setPrev(prev+1)}}>
                  Prev
                </Button>
                <Typography variant="h4" sx={headingStyle}>Your Feed</Typography>
                <Button variant="contained" onClick={()=>{setNext(next+1)}}>
                  Next
                </Button>
              </Box>
              <Box sx={videoStyle}>
                <VideoFeed prev={prev} next={next}/>
              </Box>
            </Paper>
          </Grid> */}
      </Grid>
      </Container>
      }
    </>
  );
};

export default FollowPage;