import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, 
        ListItem,
        ListItemAvatar,
        Avatar,
        ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { removeInvitedUser } from '../../../actions/program';

const MAX_NAME_LENGTH = 15;
const MAX_BIO_LENGTH = 15;

const GroupRequest = ({id, result}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const deleteRequest = () => {
        dispatch(removeInvitedUser(id,result._id));
    };

    const buttonStyle = {
        color: theme.palette.primary.main,
    };

    return (
        <ListItem alignItems="flex-start" key={result._id}>
            <ListItemAvatar>
                <Avatar alt={result.username} src={result.profilePictureUrl}>{result.username?.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={result.username.length > MAX_NAME_LENGTH ? `${result.username.substring(0, MAX_NAME_LENGTH)}...` : result.username}
                secondary={result.bio.length > MAX_BIO_LENGTH ? `${result.bio.substring(0, MAX_BIO_LENGTH)}...` : result.bio}
            />
            <IconButton sx={buttonStyle} onClick={deleteRequest}>
                <CloseIcon />
            </IconButton>
        </ListItem>     
 
    );
}

export default GroupRequest;