import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ color: '#ffffff' }}>
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" gutterBottom>
          Effective Date: August 26, 2024
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          By accessing or using Gains Coach (the “Service”), you agree to be bound by these Terms of Service (the “Terms”), 
          our Privacy Policy, and any other terms and conditions that may apply to specific sections of the Service or 
          products and services available through the Service. If you do not agree to these Terms, you must not use the Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Changes to the Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update these Terms from time to time. If we make significant changes, we will notify you either by email 
          or by posting a prominent notice on our website. Your continued use of the Service after such modifications will 
          constitute your acknowledgment of the modified Terms and agreement to abide and be bound by the updated Terms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. Use of the Service
        </Typography>
        <Typography variant="body1" gutterBottom>
          You are responsible for your use of the Service and for any content you post, upload, or otherwise make available on the platform. 
          You agree not to use the Service for any illegal or unauthorized purposes, including but not limited to:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Uploading content that violates the rights of others, including intellectual property rights." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Harassing, abusing, or harming another person." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Engaging in unlawful conduct." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Circumventing any security measures used to protect the Service." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          4. User Accounts
        </Typography>
        <Typography variant="body1" gutterBottom>
          To access certain features of the Service, you must create an account. You agree to provide accurate and complete 
          information and to keep your account information updated. You are responsible for maintaining the confidentiality of your 
          account login credentials and are fully responsible for all activities that occur under your account. You agree to notify 
          us immediately of any unauthorized use of your account.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Content Ownership and License
        </Typography>
        <Typography variant="body1" gutterBottom>
          You retain ownership of any content you submit, post, or display on or through the Service. By submitting content, you 
          grant Gains Coach a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, 
          transmit, display, and distribute your content in any and all media or distribution methods.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Termination of Service
        </Typography>
        <Typography variant="body1" gutterBottom>
          We reserve the right to suspend or terminate your account or access to the Service at any time, for any reason, including 
          but not limited to your violation of these Terms or the Privacy Policy.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Payments and Subscriptions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Certain features of the Service may require payment. You agree to pay all applicable fees and taxes associated with your 
          use of paid services. Gains Coach reserves the right to change its pricing and subscription structure at any time, and you 
          will be notified of any price changes before they take effect. All payments are non-refundable unless otherwise required by law.
        </Typography>

        <Typography variant="h5" gutterBottom>
          8. Warranty Disclaimer
        </Typography>
        <Typography variant="body1" gutterBottom>
          The Service is provided on an "as-is" and "as available" basis. Gains Coach makes no warranties, whether express or implied, 
          regarding the Service, its availability, or your use of the Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body1" gutterBottom>
          To the fullest extent permitted by law, Gains Coach shall not be liable for any indirect, incidental, special, consequential, 
          or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.
        </Typography>

        <Typography variant="h5" gutterBottom>
          10. Indemnification
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree to defend, indemnify, and hold harmless Gains Coach and its officers, directors, employees, and agents from and against 
          any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your access to or use of the Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          11. Governing Law
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms are governed by and construed in accordance with the laws of Delaware, without regard to its 
          conflict of laws principles.
        </Typography>

        <Typography variant="h5" gutterBottom>
          12. Dispute Resolution
        </Typography>
        <Typography variant="body1" gutterBottom>
          Any disputes arising under or in connection with these Terms shall be resolved through binding arbitration in accordance 
          with the rules of the American Arbitration Association (AAA). The arbitration shall take place in California, 
          and the decision of the arbitrator shall be final and binding.
        </Typography>

        <Typography variant="h5" gutterBottom>
          13. Miscellaneous
        </Typography>
        <Typography variant="body1" gutterBottom>
          If any provision of these Terms is found to be invalid, the remainder of the Terms shall remain in full force and effect. 
          The failure of Gains Coach to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions or concerns about these Terms, please contact us at:
        </Typography>
        <Typography variant="body1" color="primary">
          team@gainscoach.com
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;