import * as api from '../api';
import {
  GET_HOMEPAGE_VIDEOS,
  FETCH_TRENDING_VIDEOS,
  FETCH_VIDEOS_BY_CREATOR,
  SEARCH_VIDEOS,
  GET_VIDEO,
  CREATE_VIDEO,
  UPDATE_VIDEO,
  DELETE_VIDEO,
  LIKE_VIDEO,
  INCREMENT_VIEWS,
  VIDEO_ERROR,
  CLEAR_VIDEO_SEARCH_RESULTS,
} from '../constants/actionTypes';

export const getHomepageVideos = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageVideos();
    dispatch({ type: GET_HOMEPAGE_VIDEOS, payload: data });
  } catch (error) {
    console.error('Error fetching homepage videos:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
}

export const getHomepageVideosForGuest = () => async (dispatch) => {
  try {
    const { data } = await api.getHomepageVideosForGuest();
    dispatch({ type: GET_HOMEPAGE_VIDEOS, payload: data });
  } catch (error) {
    console.error('Error fetching homepage videos for guest:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
}

export const getTrendingVideos = (page = 1, limit = 7) => async (dispatch) => {
  try {
    const { data } = await api.getTrendingVideos(page, limit);
    dispatch({ type: FETCH_TRENDING_VIDEOS, payload: data });
  } catch (error) {
    console.error('Error fetching trending videos:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const getVideosByCreator = (creatorId, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.getVideosByCreator(creatorId, page, limit);
    dispatch({ type: FETCH_VIDEOS_BY_CREATOR, payload: data });
  } catch (error) {
    console.error('Error fetching videos by creator:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const searchVideos = (query, page, limit) => async (dispatch) => {
  try {
    const { data } = await api.searchVideos(query, page, limit);
    dispatch({ type: SEARCH_VIDEOS, payload: data });
  } catch (error) {
    console.error('Error searching videos:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const clearSearchResults = () => ({ type: CLEAR_VIDEO_SEARCH_RESULTS });

export const getVideoById = (id) => async (dispatch) => {
  try {
    const { data } = await api.getVideoById(id);
    dispatch({ type: GET_VIDEO, payload: data });
  } catch (error) {
    console.error('Error fetching video:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const createVideo = (videoData) => async (dispatch) => {
  try {
    const { data } = await api.createVideo(videoData);
    dispatch({ type: CREATE_VIDEO, payload: data });
    return data._id;
  } catch (error) {
    console.error('Error creating video:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const updateVideo = (id, videoData) => async (dispatch) => {
  try {
    const { data } = await api.updateVideo(id, videoData);
    dispatch({ type: UPDATE_VIDEO, payload: data });
  } catch (error) {
    console.error('Error updating video:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const deleteVideo = (id) => async (dispatch) => {
  try {
    await api.deleteVideo(id);
    dispatch({ type: DELETE_VIDEO, payload: id });
  } catch (error) {
    console.error('Error deleting video:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const likeVideo = (id) => async (dispatch) => {
  try {
    const { data } = await api.likeVideo(id);
    dispatch({ type: LIKE_VIDEO, payload: data });
  } catch (error) {
    console.error('Error liking video:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};

export const incrementVideoViews = (id) => async (dispatch) => {
  try {
    const { data } = await api.incrementVideoViews(id);
    dispatch({ type: INCREMENT_VIEWS, payload: data });
  } catch (error) {
    console.error('Error incrementing video views:', error);
    dispatch({ type: VIDEO_ERROR, payload: error.message });
  }
};
