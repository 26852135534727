import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Tooltip as Tooltip2 } from '@mui/material';
import { Grid } from '@mui/material';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getStatsByCreator, createStats } from '../../actions/stats';
import { Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const COLORS = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];

const getLast7Days = () => {
  const result = [];
  const today = new Date();
  for (let i = 6; i >= 0; i--) {
    const day = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
    result.push(day.toLocaleDateString('en-us', { month: 'short', day: 'numeric' }));
  }
  return result;
};

const getLast8Weeks = () => {
  const result = [];
  const today = new Date();
  for (let i = 7; i >= 0; i--) {
    const weekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i * 7);
    weekStart.setDate(weekStart.getDate() - weekStart.getDay());
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6);

    let weekRange;
    if (weekStart.getMonth() !== weekEnd.getMonth()) {
      weekRange = `${weekStart.toLocaleString('en-us', { month: 'short' })} ${weekStart.getDate()}-${weekEnd.toLocaleString('en-us', { month: 'short' })} ${weekEnd.getDate()}`;
    } else {
      weekRange = `${weekStart.toLocaleString('en-us', { month: 'short' })} ${weekStart.getDate()}-${weekEnd.getDate()}`;
    }

    result.push(weekRange);
  }
  return result;
};

const aggregateByLast7Days = (logMap) => {
  const last7Days = getLast7Days();
  const dailyDataSet = last7Days.map(day => ({ name: day }));
  const sumsByDayAndId = {};

  for (let [id, entries] of logMap.entries()) {
    const filteredEntries = entries.filter(entry => last7Days.includes(new Date(entry.date).toLocaleDateString('en-us', { month: 'short', day: 'numeric' })));
    filteredEntries.forEach(entry => {
      const day = new Date(entry.date).toLocaleDateString('en-us', { month: 'short', day: 'numeric' });
      sumsByDayAndId[day] = sumsByDayAndId[day] || {};
      sumsByDayAndId[day][id] = (sumsByDayAndId[day][id] || 0) + entry.reps;
    });
  }

  dailyDataSet.forEach(dataObj => {
    const day = dataObj.name;
    if (sumsByDayAndId[day]) {
      for (let id in sumsByDayAndId[day]) {
        dataObj[id] = sumsByDayAndId[day][id];
      }
    }
  });
  return dailyDataSet;
};

const aggregateByLast8Weeks = (logMap) => {
  const last8Weeks = getLast8Weeks();
  const weeklyDataSet = last8Weeks.map(week => ({ name: week }));
  const sumsByWeekAndId = {};

  for (let [id, entries] of logMap.entries()) {
    const filteredEntries = entries.filter(entry => {
      const entryWeekStart = new Date(entry.date);
      entryWeekStart.setDate(entryWeekStart.getDate() - entryWeekStart.getDay());
      const entryWeekEnd = new Date(entryWeekStart);
      entryWeekEnd.setDate(entryWeekEnd.getDate() + 6);

      let entryWeekRange;
      if (entryWeekStart.getMonth() !== entryWeekEnd.getMonth()) {
        entryWeekRange = `${entryWeekStart.toLocaleString('en-us', { month: 'short' })} ${entryWeekStart.getDate()}-${entryWeekEnd.toLocaleString('en-us', { month: 'short' })} ${entryWeekEnd.getDate()}`;
      } else {
        entryWeekRange = `${entryWeekStart.toLocaleString('en-us', { month: 'short' })} ${entryWeekStart.getDate()}-${entryWeekEnd.getDate()}`;
      }

      return last8Weeks.includes(entryWeekRange);
    });

    filteredEntries.forEach(entry => {
      const weekStart = new Date(entry.date);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      let weekRange;
      if (weekStart.getMonth() !== weekEnd.getMonth()) {
        weekRange = `${weekStart.toLocaleString('en-us', { month: 'short' })} ${weekStart.getDate()}-${weekEnd.toLocaleString('en-us', { month: 'short' })} ${weekEnd.getDate()}`;
      } else {
        weekRange = `${weekStart.toLocaleString('en-us', { month: 'short' })} ${weekStart.getDate()}-${weekEnd.getDate()}`;
      }

      sumsByWeekAndId[weekRange] = sumsByWeekAndId[weekRange] || {};
      sumsByWeekAndId[weekRange][id] = (sumsByWeekAndId[weekRange][id] || 0) + entry.reps;
    });
  }

  weeklyDataSet.forEach(dataObj => {
    const week = dataObj.name;
    if (sumsByWeekAndId[week]) {
      for (let id in sumsByWeekAndId[week]) {
        dataObj[id] = sumsByWeekAndId[week][id];
      }
    }
  });

  return weeklyDataSet;
};

const Stats = (props) => {
  const logMap = props.logMap;
  const logArray = Array.from(logMap.entries());
  const dailyData = aggregateByLast7Days(logMap);
  const weeklyData = aggregateByLast8Weeks(logMap);
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const theme = useTheme();

  // Use media queries to determine screen size and adjust width and height
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));

  // Set width and height based on screen size
  let chartWidth = 400;
  let chartHeight = 300;
  let pieWidth = 400;
  let pieHeight = 475;
  let pieRadius = 150;

  if (isXs) {
    chartWidth = 200;
    chartHeight = 150;
    pieRadius = 70;
    pieHeight = 200;
  } else if (isSm) {
    chartWidth = 350;
    chartHeight = 200;
    pieRadius = 100;
    pieHeight = 250;
  } else if (isMd) {
    chartWidth = 500;
    chartHeight = 300;
    pieHeight = 200;
    pieRadius = 125;
  } else if (isLg) {
    chartWidth = 600;
    chartHeight = 400;
    pieWidth = 400;
    pieHeight = 475;
    pieRadius = 150;
  }
  
  const [barChartData, setBarChartData] = useState(dailyData);
  const [selectedDataKey, setSelectedDataKey] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('Weekly');

  const stats = useSelector((state) => state.stats.stats);

  let outerPieData = [];
  let innerPieData = [];
  let emptyData = false;

  useEffect(() => {
    if (user != null) {
      dispatch(getStatsByCreator(user.result._id));
    }
  }, []);

  useEffect(() => {
    if (user != null && stats == null) {
      dispatch(createStats(user.result._id));
    }
  }, [stats]);

  const [init, setInit] = useState(true);
  useEffect(() => {
    if (init && logArray.length > 0) {
      setInit(false);
      setSelectedDataKey(logArray[0][0]);
      setBarChartData(weeklyData);
    }
  }, [logArray]);

  if (stats != null && Object.keys(stats).length !== 0) {
    outerPieData = [
      { name: 'Upper Body', value: stats.pieCounts.upperBody },
      { name: 'Lower Body', value: stats.pieCounts.lowerBody },
      { name: 'Core', value: stats.pieCounts.core },
    ];

    innerPieData = [
      { name: 'Chest', value: stats.muscleGroups?.chest || 0 },
      { name: 'Back', value: (stats.muscleGroups?.upperBack || 0) + (stats.muscleGroups?.lowerBack || 0) },
      { name: 'Shoulders', value: (stats.muscleGroups?.frontDeltoids || 0) + (stats.muscleGroups?.backDeltoids || 0) + (stats.muscleGroups?.trapezius || 0) },
      { name: 'Arms', value: (stats.muscleGroups?.biceps || 0) + (stats.muscleGroups?.triceps || 0) + (stats.muscleGroups?.forearm || 0) },
      { name: 'Quads', value: (stats.muscleGroups?.quadriceps || 0) + (stats.muscleGroups?.abductors || 0) + (stats.muscleGroups?.adductor || 0) },
      { name: 'Hamstrings', value: stats.muscleGroups?.hamstring || 0 },
      { name: 'Abs', value: stats.muscleGroups?.abs || 0 },
      { name: 'Obliques', value: stats.muscleGroups?.obliques || 0 },
      { name: 'Glutes', value: stats.muscleGroups?.gluteal || 0 },
      { name: 'Calves', value: stats.muscleGroups?.calves || 0 },
    ];

    if (
      stats.pieCounts.upperBody === 0 &&
      stats.pieCounts.lowerBody === 0 &&
      stats.pieCounts.core === 0
    ) {
      emptyData = true;
    }
  }

  const handleDataChange = (event) => {
    setSelectedDataKey(event.target.value);
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    setBarChartData(event.target.value === 'Daily' ? dailyData : weeklyData);
  };

  const isDataAvailable = barChartData.some((entry) => selectedDataKey in entry);

  const headingStyle = {
    margin: '0px 0px 10px',
    color: theme.palette.primary.main,
    textAlign: 'center',
  };

  return (
    <Paper elevation={3} style={{ padding: '30px', margin: 'auto', marginTop: '20px', border: '1px solid' }}>
      <Typography variant="h4" style={headingStyle}>
        Workout Stats
      </Typography>

      <Grid marginBottom="30px" container justifyContent="space-between" alignItems="stretch" spacing={3}>
        <Grid item style={{ margin: 'auto' }} xs={12} sm={12} md={12} lg={4}>
          <Box textAlign="center">
            <Tooltip2 style={{ marginRight: '10px' }} title="Total of all time">
              <Typography variant="h6">Total Sets</Typography>
            </Tooltip2>
            <Typography variant="h4">{stats?.totalSets}</Typography>
          </Box>
        </Grid>

        <Grid item style={{ margin: 'auto' }} xs={12} sm={12} md={12} lg={4}>
          <Box textAlign="center">
            <Tooltip2 style={{ marginRight: '10px' }} title="Calculated over the last 7 days">
              <Typography variant="h6">Sets Per Day</Typography>
            </Tooltip2>
            <Typography variant="h4">{(props.totalWeek / 7).toFixed(1)}</Typography>
          </Box>
        </Grid>

        <Grid item style={{ margin: 'auto' }} xs={12} sm={12} md={12} lg={4}>
          <Box textAlign="center">
            <Tooltip2 style={{ marginRight: '10px' }} title="Calculated over the last 8 weeks">
              <Typography variant="h6">Sets Per Week</Typography>
            </Tooltip2>
            <Typography variant="h4">{(props.total2Months / 8).toFixed(1)}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
        <Grid item style={{ margin: 'auto' }} xs={12} sm={12} md={12} lg={6}>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Typography variant="h5">Training Distribution</Typography>
            {emptyData && (
              <Card style={{ backgroundColor: "black", maxWidth: 345, margin: '20px auto' }}>
                <CardContent>
                  <Typography variant="h6" color="textSecondary" align="center">
                    No data to display
                  </Typography>
                </CardContent>
              </Card>
            )}
            <PieChart width={pieWidth} height={pieHeight}>
              <Pie
                dataKey="value"
                data={innerPieData}
                cx={pieWidth / 2}
                cy={pieHeight / 2}
                innerRadius={pieRadius * 0.33}
                outerRadius={pieRadius * 0.67}
                fill="#8884d8"
                label
              >
                {innerPieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Pie
                dataKey="value"
                data={outerPieData}
                cx={pieWidth / 2}
                cy={pieHeight / 2}
                innerRadius={pieRadius * 0.67}
                outerRadius={pieRadius}
                fill="#82ca9d"
                label
              >
                {outerPieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </Box>
        </Grid>

        <Grid item style={{ margin: 'auto' }} xs={12} sm={12} md={12} lg={6}>
          <Box marginTop="10px" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Typography variant="h5" align="center" marginBottom={2}>Recent Performance</Typography>

            <BarChart width={chartWidth} height={chartHeight} data={barChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey={selectedDataKey} fill={theme.palette.primary.main} />
            </BarChart>
            {!isDataAvailable && (
              <Card style={{ backgroundColor: "black", position: 'absolute' }}>
                <CardContent>
                  <Typography variant="h6" color="textSecondary">
                    No data to display
                  </Typography>
                </CardContent>
              </Card>
            )}

            <Box display="flex" alignItems="center" marginTop={2}>
              <Select value={selectedDataKey} onChange={handleDataChange} sx={{ mr: 1 }}>
                {logArray.map(([key, value]) => (
                  <MenuItem key={key} value={key}>{value[0].name}</MenuItem>
                ))}
              </Select>
              <Select value={selectedPeriod} onChange={handlePeriodChange}>
                <MenuItem value="Weekly">Last 8 Weeks</MenuItem>
                <MenuItem value="Daily">Last 7 Days</MenuItem>
              </Select>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Stats;
