import {
  FETCH_BOOKMARKED_EXERCISES,
  FETCH_BOOKMARKED_WORKOUTS,
  FETCH_EXERCISE_BOOKMARKS_BY_CREATOR,
  FETCH_WORKOUT_BOOKMARKS_BY_CREATOR,
  FETCH_EXERCISE_BOOKMARKS_WITH_TITLES,
  FETCH_WORKOUT_BOOKMARKS_WITH_TITLES,
  TOGGLE_BOOKMARK,
  DELETE_BOOKMARKS_BY_EXERCISE_ID,
  DELETE_BOOKMARKS_BY_WORKOUT_ID,
  SIGN_IN,
  SIGN_UP,
  VERIFY_EMAIL,
  RECOVER_EMAIL,
  CHANGE_PASSWORD,
  CHECK_TOKEN,
  GET_USER_BY_ID,
  UPDATE_USER_PROFILE,
  FOLLOW_USER,
  UNFOLLOW_USER,
  ACCEPT_FOLLOW_REQUEST,
  DECLINE_FOLLOW_REQUEST,
  SEARCH_USERS,
  SUGGEST_USERS_TO_FOLLOW,
  USER_ERROR,
  AUTH,
  LOGOUT,
  GET_USERS_BY_IDS,
  GET_FOLLOWERS_BY_ID,
  GET_FOLLOWING_BY_ID,
  GET_INCOMING_BY_ID,
  GET_OUTGOING_BY_ID,
  WITHDRAW_REQUEST,
  REMOVE_FOLLOWER,
} from '../constants/actionTypes';

const initialState = {
  authData: null,
  user: null,
  users: [],
  tokenValid: true,
  searchResults: [],
  suggestions: [],
  bookmarkedExercises: {
    data: [],
    currentPage: 1,
    totalPages: 1,
  },
  bookmarkedWorkouts: {
    data: [],
    currentPage: 1,
    totalPages: 1,
  },
  exerciseBookmarks: [],
  workoutBookmarks: [],
  exerciseBookmarksWithTitles: [],
  workoutBookmarksWithTitles: [],
  bookmark: null,
  currentPage: 1,
  totalPages: 1,
  error: null,
  submitted: false,
  followers: [],
  following: [],
  incomingRequests: [],
  outgoingRequests: []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH:
      // add user to local storage on login
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
      return { ...state, authData: action.data, user: action.data.result, loading: false, errors: null };
    case LOGOUT:
      // remove user from local storage on logout
      localStorage.clear();
      return { ...state, authData: null, user: null, loading: false, errors: null };
    case CHECK_TOKEN:
      return { ...state, tokenValid: action.payload.result };
    case SIGN_UP:
      return {...state, submitted: true}
      case SIGN_IN:
    case VERIFY_EMAIL:
    case RECOVER_EMAIL:
    case CHANGE_PASSWORD:
    case CHECK_TOKEN:
      localStorage.setItem('profile', JSON.stringify({ ...action?.payload }));
      return { ...state, authData: action.payload, user: action.payload.result, tokenValid: true, error: null };
    case GET_USER_BY_ID:
    case UPDATE_USER_PROFILE:
      return { ...state, user: action.payload, error: null };
    case GET_USERS_BY_IDS:
      return {...state, users: action.payload, error: null};
    case GET_FOLLOWERS_BY_ID: 
      return {...state, followers: action.payload, error: null}
    case GET_FOLLOWING_BY_ID:
      return {...state, following: action.payload, error: null}
    case GET_INCOMING_BY_ID:
      return {...state, incomingRequests: action.payload, error: null}
    case GET_OUTGOING_BY_ID:
      return {...state, outgoingRequests: action.payload, error:null}
    case FOLLOW_USER: // create a request to follow user
      return {...state, 
        outgoingRequests: [...state.outgoingRequests, action.payload], error: null};
    case WITHDRAW_REQUEST:
      return {...state, outgoingRequests: state.outgoingRequests.filter(obj => obj._id !== action.payload), error: null}
    case UNFOLLOW_USER: 
      return {...state, 
        following: state.following.filter(obj => obj._id !== action.payload), error: null};
    case ACCEPT_FOLLOW_REQUEST:
      return { ...state, 
        incomingRequests: state.incomingRequests.filter(obj => obj._id !== action.payload._id), 
        followers: [...state.followers, action.payload], error: null};
    case DECLINE_FOLLOW_REQUEST:
      return { ...state, 
        incomingRequests: state.incomingRequests.filter(obj => obj._id !== action.payload), error: null };
    case REMOVE_FOLLOWER:
      return { ...state, 
        followers: state.followers.filter(obj => obj._id !== action.payload), error: null };
    case SEARCH_USERS:
      return { ...state, searchResults: action.payload, error: null };
    case SUGGEST_USERS_TO_FOLLOW:
      return { ...state, suggestions: action.payload, error: null };
    case USER_ERROR:
      return { ...state, error: action.payload };
    case FETCH_BOOKMARKED_EXERCISES:
      return {
        ...state,
        bookmarkedExercises: {
          data: action.payload.exerciseBookmarks,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        exerciseBookmarks: action.payload.exerciseBookmarks.map((exercise) => exercise._id),
      };
    case FETCH_BOOKMARKED_WORKOUTS:
      return {
        ...state,
        bookmarkedWorkouts: {
          data: action.payload.workoutBookmarks,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
        },
        workoutBookmarks: action.payload.workoutBookmarks.map((workout) => workout._id),
      };
    case FETCH_EXERCISE_BOOKMARKS_BY_CREATOR:
      return {
        ...state,
        exerciseBookmarks: action.payload,
      };
    case FETCH_WORKOUT_BOOKMARKS_BY_CREATOR:
      return {
        ...state,
        workoutBookmarks: action.payload,
      };
    case FETCH_EXERCISE_BOOKMARKS_WITH_TITLES:
      return {
        ...state,
        exerciseBookmarksWithTitles: action.payload,
      };
    case FETCH_WORKOUT_BOOKMARKS_WITH_TITLES:
      return {
        ...state,
        workoutBookmarksWithTitles: action.payload,
      };
    case TOGGLE_BOOKMARK:
      return {
        ...state,
        exerciseBookmarks: action.payload.exercise ? [...state.exerciseBookmarks, action.payload] : state.exerciseBookmarks,
        workoutBookmarks: action.payload.workout ? [...state.workoutBookmarks, action.payload] : state.workoutBookmarks,
      };
    case DELETE_BOOKMARKS_BY_EXERCISE_ID:
      return {
        ...state,
        exerciseBookmarks: state.exerciseBookmarks.filter(
          (bookmark) => bookmark.exercise !== action.payload.exerciseId
        ),
      };
    case DELETE_BOOKMARKS_BY_WORKOUT_ID:
      return {
        ...state,
        workoutBookmarks: state.workoutBookmarks.filter(
          (bookmark) => bookmark.workout !== action.payload.workoutId
        ),
      };
    default:
      return state;
  }
};

export default authReducer;