import React from 'react';
import { useDispatch } from 'react-redux';
import {ListItem, ListItemText, IconButton, Typography, Paper, Stack, Box , ListItemAvatar, Avatar} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {declineFollowRequest, acceptFollowRequest, withdrawRequest} from '../../actions/user'

const MAX_NAME_LENGTH = 20;
const MAX_BIO_LENGTH = 20;

const FollowRequest = ({name, bio, command, id, profilePicture}) => {
    const dispatch = useDispatch();

    const handleDecline = () => {
        dispatch(declineFollowRequest(id))
    };

    const handleWithdraw = () => {
        dispatch(withdrawRequest(id))
    }
    const handleAccept = () => {
        dispatch(acceptFollowRequest(id))
    };

    const nameStyle = {
        margin: '5px 10px',
        color: 'primary',
        textAlign: 'left'
    };

    return (
        <>

            {(command === "blank") ?
            <Paper elevation={0} sx={{ m: '5px', backgroundColor: 'secondary' }}>
                <Stack direction="row" sx={{justifyContent: 'start'}}>
                    <Typography variant='body1' sx={nameStyle}>
                        No requests
                    </Typography>
                </Stack>
            </Paper>
            :
            <ListItem alignItems="flex-start" key={id}>
                <ListItemAvatar>
                    <Avatar alt={name} src={profilePicture}>{name?.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={name.length > MAX_NAME_LENGTH ? `${name.substring(0, MAX_NAME_LENGTH)}...` : name}
                    secondary={bio.length > MAX_BIO_LENGTH ? `${bio.substring(0, MAX_BIO_LENGTH)}...` : bio}
                />
                    {command === "creator" &&
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton onClick={handleWithdraw} size='small'>
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    }

                    {command === "receiver" &&
                        <Box display="flex" justifyContent="flex-end" flexDirection="row">
                            <IconButton onClick={handleAccept} size='small'>
                                <CheckIcon />
                            </IconButton>
                            <IconButton onClick={handleDecline} size='small'>
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    }
            </ListItem>
            }
        </>
    );
};

export default FollowRequest;